const routes = [{
    path: '',
    name: 'login',
    component: () => import('@/views/Cliente/Login'),
    meta: {
      boolRequiredAuthentication: false,
      boolFooterDisble: true,
      boolTopDisble: true,
    },
  },
  {
    path: '/:strUrlDatabase',
    component: () => import('@/layouts/Cliente'),
    children: [{
        path: 'login',
        name: 'loginCliente',
        component: () => import('@/views/Cliente/Login'),
        meta: {
          boolRequiredAuthentication: false,
          boolFooterDisble: true,
          boolTopDisble: true,
        },
      },
      {
        path: '',
        name: 'index',
        component: () => import('@/views/Cliente/Index'),
        meta: {
          boolRequiredAuthentication: false,

        },
      },
      {
        path: 'video/:strIdentificador',
        name: 'video',
        component: () => import('@/views/Cliente/Consulta/Video'),
        meta: {
          boolRequiredAuthentication: false,
          boolFooterDisble: true,
        },
      },
      {
        path: 'alterar-senha/:strToken',
        name: 'alterarSenha',
        component: () => import('@/views/Cliente/AlterarSenha'),
        meta: {
          boolRequiredAuthentication: false,
          boolFooterDisble: true,
          boolTopDisble: true,
        },
      },
      {
        path: 'logout',
        name: 'logout',
        component: () => import('@/views/Cliente/Logout'),
        meta: {
          boolRequiredAuthentication: true,
          boolFooterDisble: true,
          boolTopDisble: true,
        },
      },
      {
        path: 'home',
        name: 'home',
        component: () => import('@/views/Cliente/Home'),
        meta: {
          boolRequiredAuthentication: true,
        },
      },
      {
        path: 'cadastro/empresa',
        name: 'cadastro.empresa',
        component: () => import('@/views/Cliente/Cadastro/Empresa/Index'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa fa-building',
            name: 'Empresa'
          },
          menuNavigation: [{
              text: "Cadastro",
              disabled: true,
              href: "",
            },
            {
              text: "Empresa",
              disabled: true,
              href: "",
            }
          ]
        },
      },
      {
        path: 'cadastro/empresa/inserir',
        name: 'cadastro.empresa.register',
        component: () => import('@/views/Cliente/Cadastro/Empresa/Inserir'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa fa-building',
            name: 'Inserir Empresa'
          },
          menuNavigation: [{
              text: "Cadastro",
              disabled: true,
              href: "",
            },
            {
              text: "Empresa",
              disabled: false,
              to: "cadastro.empresa",
            },
            {
              text: "Inserir Empresa",
              disabled: true,
              href: "",
            }
          ]
        },
      },
      {
        path: 'cadastro/empresa/editar/:intId',
        name: 'cadastro.empresa.edit',
        component: () => import('@/views/Cliente/Cadastro/Empresa/Editar'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa fa-building',
            name: 'Editar Empresa'
          },
          menuNavigation: [{
              text: "Cadastro",
              disabled: true,
              href: "",
            },
            {
              text: "Empresa",
              disabled: false,
              to: "cadastro.empresa",
            },
            {
              text: "Editar Empresa",
              disabled: true,
              href: "",
            }
          ]
        },
      },
      {
        path: 'cadastro/parceiro',
        name: 'cadastro.parceiro',
        component: () => import('@/views/Cliente/Cadastro/Parceiro/Index'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa-solid fa-handshake',
            name: 'Parceiro'
          },
          menuNavigation: [{
              text: "Cadastro",
              disabled: true,
              href: "",
            },
            {
              text: "Parceiro",
              disabled: true,
              href: "",
            }
          ]
        },
      },
      {
        path: 'cadastro/parceiro/inserir',
        name: 'cadastro.parceiro.register',
        component: () => import('@/views/Cliente/Cadastro/Parceiro/Inserir'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa-solid fa-handshake',
            name: 'Parceiro'
          },
          menuNavigation: [{
              text: "Cadastro",
              disabled: true,
              href: "",
            },
            {
              text: "Parceiro",
              disabled: false,
              to: "cadastro.parceiro",
            },
            {
              text: "Inserir Parceiro",
              disabled: true,
              href: "",
            }
          ]
        },
      },
      {
        path: 'cadastro/parceiro/editar/:intId',
        name: 'cadastro.parceiro.edit',
        component: () => import('@/views/Cliente/Cadastro/Parceiro/Editar'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa-solid fa-handshake',
            name: 'Editar Parceiro'
          },
          menuNavigation: [{
              text: "Cadastro",
              disabled: true,
              href: "",
            },
            {
              text: "Parceiro",
              disabled: false,
              to: "cadastro.parceiro",
            },
            {
              text: "Editar Parceiro",
              disabled: true,
              href: "",
            }
          ]
        },
      },
      {
        path: 'cadastro/fornecedor',
        name: 'cadastro.fornecedor',
        component: () => import('@/views/Cliente/Cadastro/Fornecedor/Index'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa fa-cart-arrow-down',
            name: 'Fornecedor'
          },
          menuNavigation: [{
              text: "Cadastro",
              disabled: true,
              href: "",
            },
            {
              text: "Fornecedor",
              disabled: true,
              href: "",
            }
          ]
        },
      },
      //CONVENIO
      {
        path: 'cadastro/convenio',
        name: 'cadastro.convenio',
        component: () => import('@/views/Cliente/Cadastro/Convenio/Index'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa fa-ticket',
            name: 'Convênio'
          },
          menuNavigation: [{
              text: "Cadastro",
              disabled: true,
              href: "",
            },
            {
              text: "Convênio",
              disabled: true,
              href: "",
            }
          ]
        },
      },
      {
        path: 'cadastro/convenio/inserir',
        name: 'cadastro.convenio.register',
        component: () => import('@/views/Cliente/Cadastro/Convenio/Inserir'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa fa-ticket',
            name: 'Inserir Convênio'
          },
          menuNavigation: [{
              text: "Cadastro",
              disabled: true,
              href: "",
            },
            {
              text: "Convênio",
              disabled: false,
              to: "cadastro.convenio",
            },
            {
              text: "Inserir Convênio",
              disabled: true,
              href: "",
            }
          ]
        },
      },
      {
        path: 'cadastro/convenio/editar/:intId',
        name: 'cadastro.convenio.edit',
        component: () => import('@/views/Cliente/Cadastro/Convenio/Editar'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa fa-ticket',
            name: 'Editar Convênio'
          },
          menuNavigation: [{
              text: "Cadastro",
              disabled: true,
              href: "",
            },
            {
              text: "Convênio",
              disabled: false,
              to: "cadastro.convenio",
            },
            {
              text: "Editar Convênio",
              disabled: true,
              href: "",
            }
          ]
        },
      },
      //CID
      {
        path: 'cadastro/cid',
        name: 'cadastro.cid',
        component: () => import('@/views/Cliente/Cadastro/Cid/Index'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa fa-th-list',
            name: 'CID'
          },
          menuNavigation: [{
              text: "Cadastro",
              disabled: true,
              href: "",
            },
            {
              text: "CID",
              disabled: true,
              href: "",
            }
          ]
        },
      },
      {
        path: 'cadastro/cid/inserir',
        name: 'cadastro.cid.register',
        component: () => import('@/views/Cliente/Cadastro/Cid/Inserir'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa fa-th-list',
            name: 'Inserir CID'
          },
          menuNavigation: [{
              text: "Cadastro",
              disabled: true,
              href: "",
            },
            {
              text: "CID",
              disabled: false,
              to: "cadastro.cid",
            },
            {
              text: "Inserir CID",
              disabled: true,
              href: "",
            }
          ]
        },
      },
      {
        path: 'cadastro/cid/editar/:intId',
        name: 'cadastro.cid.edit',
        component: () => import('@/views/Cliente/Cadastro/Cid/Editar'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa fa-th-list',
            name: 'Editar CID'
          },
          menuNavigation: [{
              text: "Cadastro",
              disabled: true,
              href: "",
            },
            {
              text: "CID",
              disabled: false,
              to: "cadastro.cid",
            },
            {
              text: "Editar CID",
              disabled: true,
              href: "",
            }
          ]
        },
      },


      //TABELA PRECO
      {
        path: 'cadastro/tabela-preco',
        name: 'cadastro.preco',
        component: () => import('@/views/Cliente/Cadastro/TabelaPreco/Index'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa fa-tags',
            name: 'Tabela de Preço'
          },
          menuNavigation: [{
              text: "Cadastro",
              disabled: true,
              href: "",
            },
            {
              text: "Tabela de Preço",
              disabled: true,
              href: "",
            }
          ]
        },
      },
      {
        path: 'cadastro/tabela-preco/inserir',
        name: 'cadastro.preco.register',
        component: () => import('@/views/Cliente/Cadastro/TabelaPreco/Inserir'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa fa-tags',
            name: 'Tabela de Preço'
          },
          menuNavigation: [{
              text: "Cadastro",
              disabled: true,
              href: "",
            },
            {
              text: "Tabela de Preço",
              disabled: false,
              to: "cadastro.preco",
            },
            {
              text: "Inserir Tabela de Preço",
              disabled: true,
              href: "",
            }
          ]
        },
      },
      {
        path: 'cadastro/tabela-preco/editar/:intId',
        name: 'cadastro.preco.edit',
        component: () => import('@/views/Cliente/Cadastro/TabelaPreco/Editar'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa fa-tags',
            name: 'Tabela de Preço'
          },
          menuNavigation: [{
              text: "Cadastro",
              disabled: true,
              href: "",
            },
            {
              text: "Tabela de Preço",
              disabled: false,
              to: "cadastro.preco",
            },
            {
              text: "Editar Tabela de Preço",
              disabled: true,
              href: "",
            }
          ]
        },
      },

      {
        path: 'cadastro/formularios-personalizados',
        name: 'cadastro.formulario',
        component: () => import('@/views/Cliente/Cadastro/FormularioPersonalizado/Index'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa fa-list-ol',
            name: 'Formulários Personalizados'
          },
          menuNavigation: [{
              text: "Cadastro",
              disabled: true,
              href: "",
            },
            {
              text: "Formulários Personalizados",
              disabled: true,
              href: "",
            }
          ]
        },
      },
      {
        path: 'cadastro/formularios-personalizados/inserir',
        name: 'cadastro.formulario.register',
        component: () => import('@/views/Cliente/Cadastro/FormularioPersonalizado/Inserir'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa fa-list-ol',
            name: 'Formulários Personalizados'
          },
          menuNavigation: [{
              text: "Cadastro",
              disabled: true,
              href: "",
            },
            {
              text: "Formulários Personalizados",
              disabled: false,
              to: "cadastro.formulario",
            },
            {
              text: "Inserir Campo Personalizados",
              disabled: true,
              href: "",
            }
          ]
        },
      },
      {
        path: 'cadastro/campos-personalizados/editar/:intId',
        name: 'cadastro.formulario.edit',
        component: () => import('@/views/Cliente/Cadastro/FormularioPersonalizado/Editar'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa fa-list-ol',
            name: 'Formulários Personalizados'
          },
          menuNavigation: [{
              text: "Cadastro",
              disabled: true,
              href: "",
            },
            {
              text: "Formulários Personalizados",
              disabled: false,
              to: "cadastro.formulario",
            },
            {
              text: "Editar Campo Personalizados",
              disabled: true,
              href: "",
            }
          ]
        },
      },



      {
        path: 'cadastro/fornecedor/inserir',
        name: 'cadastro.fornecedor.register',
        component: () => import('@/views/Cliente/Cadastro/Fornecedor/Inserir'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa fa-cart-arrow-down',
            name: 'Fornecedor'
          },
          menuNavigation: [{
              text: "Cadastro",
              disabled: true,
              href: "",
            },
            {
              text: "Fornecedor",
              disabled: false,
              to: "cadastro.fornecedor",
            },
            {
              text: "Inserir Fornecedor",
              disabled: true,
              href: "",
            }
          ]
        },
      },
      {
        path: 'cadastro/fornecedor/editar/:intId',
        name: 'cadastro.fornecedor.edit',
        component: () => import('@/views/Cliente/Cadastro/Fornecedor/Editar'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa fa-cart-arrow-down',
            name: 'Editar Fornecedor'
          },
          menuNavigation: [{
              text: "Cadastro",
              disabled: true,
              href: "",
            },
            {
              text: "Fornecedor",
              disabled: false,
              to: "cadastro.fornecedor",
            },
            {
              text: "Editar Fornecedor",
              disabled: true,
              href: "",
            }
          ]
        },
      },
      {
        path: 'cadastro/funcionario/',
        name: 'cadastro.funcionario',
        component: () => import('@/views/Cliente/Cadastro/Funcionario/Index'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa fa-address-card',
            name: 'Funcionário'
          },
          menuNavigation: [{
              text: "Cadastro",
              disabled: true,
              href: "",
            },
            {
              text: "Funcionário",
              disabled: true,
              href: "",
            },
          ]
        },
      },
      {
        path: 'cadastro/funcionario/inserir/',
        name: 'cadastro.funcionario.register',
        component: () => import('@/views/Cliente/Cadastro/Funcionario/Inserir'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa fa-address-card',
            name: 'Funcionário'
          },
          menuNavigation: [{
              text: "Cadastro",
              disabled: true,
              href: "",
            },
            {
              text: "Funcionário",
              disabled: false,
              to: "cadastro.funcionario",
            },
            {
              text: "Inserir Funcionario",
              disabled: true,
              href: "",
            }
          ]
        },
      },
      {
        path: 'cadastro/funcionario/editar/:intId',
        name: 'cadastro.funcionario.edit',
        component: () => import('@/views/Cliente/Cadastro/Funcionario/Editar'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa fa-address-card',
            name: 'Funcionário'
          },
          menuNavigation: [{
              text: "Cadastro",
              disabled: true,
              href: "",
            },
            {
              text: "Funcionário",
              disabled: false,
              to: "cadastro.funcionario",
            },
            {
              text: "Editar Funcionario",
              disabled: true,
              href: "",
            }
          ]
        },
      },
      {
        path: 'cadastro/usuario',
        name: 'cadastro.usuario',
        component: () => import('@/views/Cliente/Cadastro/Usuario/Index'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa fa-users',
            name: 'Usuário'
          },
          menuNavigation: [{
              text: "Cadastro",
              disabled: true,
              href: "",
            },
            {
              text: "Usuario",
              disabled: true,
              href: "",
            }
          ]
        },
      },
      {
        path: 'cadastro/usuario/inserir/',
        name: 'cadastro.usuario.register',
        component: () => import('@/views/Cliente/Cadastro/Usuario/Inserir'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa fa-users',
            name: 'Usuário'
          },
          menuNavigation: [{
              text: "Cadastro",
              disabled: true,
              href: "",
            },
            {
              text: "Usuário",
              disabled: false,
              to: "cadastro.usuario",
            },
            {
              text: "Inserir Usuario",
              disabled: true,
              href: "",
            }
          ]
        },
      },
      {
        path: 'cadastro/usuario/editar/:intId',
        name: 'cadastro.usuario.edit',
        component: () => import('@/views/Cliente/Cadastro/Usuario/Editar'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa fa-users',
            name: 'Usuário'
          },
          menuNavigation: [{
              text: "Cadastro",
              disabled: true,
              href: "",
            },
            {
              text: "Cadastro",
              disabled: false,
              to: "cadastro.usuario",
            },
            {
              text: "Editar Cadastro",
              disabled: true,
              href: "",
            }
          ]
        },
      },



      {
        path: 'cadastro/funcao',
        name: 'cadastro.funcao',
        component: () => import('@/views/Cliente/Cadastro/Funcao/Index'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa fa-address-card',
            name: 'Funçoes'
          },
          menuNavigation: [{
              text: "Cadastro",
              disabled: true,
              href: "",
            },
            {
              text: "Funções",
              disabled: true,
              href: "",
            }
          ]
        },
      },
      {
        path: 'cadastro/funcao/inserir',
        name: 'cadastro.funcao.register',
        component: () => import('@/views/Cliente/Cadastro/Funcao/Inserir'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa fa-address-card',
            name: 'Inserir Função'
          },
          menuNavigation: [{
              text: "Cadastro",
              disabled: true,
              href: "",
            },
            {
              text: "Funções",
              disabled: false,
              to: "cadastro.funcao",
            },
            {
              text: "Inserir Função",
              disabled: true,
              href: "",
            }
          ]
        },
      },
      {
        path: 'cadastro/funcao/editar/:intId',
        name: 'cadastro.funcao.edit',
        component: () => import('@/views/Cliente/Cadastro/Funcao/Editar'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa fa-address-card',
            name: 'Editar Função'
          },
          menuNavigation: [{
              text: "Cadastro",
              disabled: true,
              href: "",
            },
            {
              text: "Funções",
              disabled: false,
              to: "cadastro.funcao",
            },
            {
              text: "Editar Função",
              disabled: true,
              href: "",
            }
          ]
        },
      },

      {
        path: 'cadastro/exame',
        name: 'cadastro.exame',
        component: () => import('@/views/Cliente/Cadastro/Exame/Index'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa fa-stethoscope',
            name: 'Exames'
          },
          menuNavigation: [{
              text: "Cadastro",
              disabled: true,
              href: "",
            },
            {
              text: "Exames",
              disabled: true,
              href: "",
            }
          ]
        },
      },
      {
        path: 'cadastro/exame/inserir',
        name: 'cadastro.exame.register',
        component: () => import('@/views/Cliente/Cadastro/Exame/Inserir'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa fa-stethoscope',
            name: 'Inserir Exame'
          },
          menuNavigation: [{
              text: "Cadastro",
              disabled: true,
              href: "",
            },
            {
              text: "Exames",
              disabled: false,
              to: "cadastro.exame",
            },
            {
              text: "Inserir Exame",
              disabled: true,
              href: "",
            }
          ]
        },
      },
      {
        path: 'cadastro/exame/editar/:intId',
        name: 'cadastro.exame.edit',
        component: () => import('@/views/Cliente/Cadastro/Exame/Editar'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa fa-stethoscope',
            name: 'Editar Exame'
          },
          menuNavigation: [{
              text: "Cadastro",
              disabled: true,
              href: "",
            },
            {
              text: "Exame",
              disabled: false,
              to: "cadastro.exame",
            },
            {
              text: "Editar Exame",
              disabled: true,
              href: "",
            }
          ]
        },
      },
      {
        path: 'cadastro/risco',
        name: 'cadastro.risco',
        component: () => import('@/views/Cliente/Cadastro/Risco/Index'),
        meta: {
          boolRequiredAuthentication: true,
          menuNavigation: [{
              text: "Cadastro",
              disabled: true,
              href: "",
            },
            {
              text: "Riscos",
              disabled: true,
              href: "",
            }
          ]
        },
      },
      {
        path: 'cadastro/risco/inserir',
        name: 'cadastro.risco.register',
        component: () => import('@/views/Cliente/Cadastro/Risco/Inserir'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa fa-exclamation-triangle',
            name: 'Inserir Risco'
          },
          menuNavigation: [{
              text: "Cadastro",
              disabled: true,
              href: "",
            },
            {
              text: "Riscos",
              disabled: false,
              to: "cadastro.risco",
            },
            {
              text: "Inserir Risco",
              disabled: true,
              href: "",
            }
          ]
        },
      },
      {
        path: 'cadastro/risco/editar/:intId',
        name: 'cadastro.risco.edit',
        component: () => import('@/views/Cliente/Cadastro/Risco/Editar'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa fa-exclamation-triangle',
            name: 'Editar Risco'
          },
          menuNavigation: [{
              text: "Cadastro",
              disabled: true,
              href: "",
            },
            {
              text: "Riscos",
              disabled: false,
              to: "cadastro.risco",
            },
            {
              text: "Editar risco",
              disabled: true,
              href: "",
            }
          ]
        },
      },
      {
        path: 'cadastro/profissional',
        name: 'cadastro.profissional',
        component: () => import('@/views/Cliente/Cadastro/Profissional/Index'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa fa-id-badge',
            name: 'Profissionais'
          },
          menuNavigation: [{
              text: "Cadastro",
              disabled: true,
              href: "",
            },
            {
              text: "Profissionais",
              disabled: true,
              href: "",
            }
          ]
        },
      },
      {
        path: 'cadastro/profissional/inserir',
        name: 'cadastro.profissional.register',
        component: () => import('@/views/Cliente/Cadastro/Profissional/Inserir'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa fa-id-badge',
            name: 'Inserir Profissional'
          },
          menuNavigation: [{
              text: "Cadastro",
              disabled: true,
              href: "",
            },
            {
              text: "Profissionais",
              disabled: false,
              to: "cadastro.profissional",
            },
            {
              text: "Inserir Profissional",
              disabled: true,
              href: "",
            }
          ]
        },
      },
      {
        path: 'cadastro/profissional/editar/:intId',
        name: 'cadastro.profissional.edit',
        component: () => import('@/views/Cliente/Cadastro/Profissional/Editar'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa fa-id-badge',
            name: 'Editar Profissional'
          },
          menuNavigation: [{
              text: "Cadastro",
              disabled: true,
              href: "",
            },
            {
              text: "Profissionais",
              disabled: false,
              to: "cadastro.profissional",
            },
            {
              text: "Editar Profissional",
              disabled: true,
              href: "",
            }
          ]
        },
      },
      //ABSENTEISMO
      {
        path: 'absenteismo',
        name: 'absenteismo',
        component: () => import('@/views/Cliente/Absenteismo/Index'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fas fa-user-times',
            name: 'Absenteísmo'
          },
          menuNavigation: [{
            text: "Absenteísmo",
            disabled: true,
            href: "",
          }, ]
        },
      },
      {
        path: 'absenteismo/inserir',
        name: 'absenteismo.register',
        component: () => import('@/views/Cliente/Absenteismo/Inserir'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fas fa-user-times',
            name: 'Absenteísmo'
          },
          menuNavigation: [{
              text: "Absenteísmo",
              disabled: false,
              to: "absenteismo",
            },
            {
              text: "Inserir Absenteísmo",
              disabled: true,
              href: "",
            }
          ]
        },
      },
      {
        path: 'absenteismo/editar/:intId',
        name: 'absenteismo.edit',
        component: () => import('@/views/Cliente/Absenteismo/Editar'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fas fa-user-times',
            name: 'Absenteísmo'
          },
          menuNavigation: [{
              text: "Absenteísmo",
              disabled: false,
              to: "absenteismo",
            },
            {
              text: "Editar Absenteísmo",
              disabled: true,
              href: "",
            }
          ]
        },
      },
      //AGENDAMENTO
      {
        path: 'agendamento',
        name: 'agendamento.list',
        component: () => import('@/views/Cliente/Agendamento/Index'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa fa-calendar',
            name: 'Gerenciar Agendamento'
          },
          menuNavigation: [{
            text: "Gerenciar Agendamento",
            disabled: true,
            to: "agendamento.list",
          }, ]
        },

      },
      {
        path: 'agendamento/configuracoes',
        name: 'agendamento.config',
        component: () => import('@/views/Cliente/Agendamento/Configuracao'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa-solid fa-gears',
            name: 'Configurações'
          },
          menuNavigation: [{
              text: "Gerenciar Agendamento",
              disabled: false,
              to: "agendamento.list",
            },
            {
              text: "Configurações",
              disabled: true,
              href: "",
            }
          ]
        },

      },

      //CONSULTA
      {
        path: 'consulta',
        name: 'consulta.search',
        component: () => import('@/views/Cliente/Consulta/Index'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa-solid fa-user-doctor',
            name: 'Consultas'
          },
        },
      },
      {
        path: 'consulta/inserir/:intFuncionarioId?',
        name: 'consulta.register',
        component: () => import('@/views/Cliente/Consulta/Inserir'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa-solid fa-user-doctor',
            name: 'Consultas'
          },
          menuNavigation: [{
              text: "Consultas",
              disabled: false,
              to: "consulta.search",
            },
            {
              text: "Registrar Nova Consulta",
              disabled: true,
              href: "",
            }
          ]
        },
      },
      {
        path: 'consulta/editar/:intId',
        name: 'consulta.edit',
        component: () => import('@/views/Cliente/Consulta/Editar'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa-solid fa-user-doctor',
            name: 'Consultas'
          },
          menuNavigation: [{
              text: "Consultas",
              disabled: false,
              to: "consulta.search",
            },
            {
              text: "Editar Consulta",
              disabled: true,
              href: "",
            }
          ]
        },
      },

      //MODELO DE CAT
      {
        path: 'modelo/absenteismo',
        name: 'modelo._absenteismo',
        component: () => import('@/views/Cliente/Modelo/Absenteismo/Index'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fas fa-user-times',
            name: 'Modelo de Absenteísmo'
          },
          menuNavigation: [{
            text: "Modelo de Absenteísmo",
            disabled: true,
          }, ]
        },
      },
      {
        path: 'modelo/absenteismo/editar/:intId',
        name: 'modelo._absenteismo.edit',
        component: () => import('@/views/Cliente/Modelo/Absenteismo/Editar'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fas fa-user-times',
            name: 'Editar Modelo de Absenteísmo'
          },
          menuNavigation: [{
              text: "Modelo de Absenteísmo",
              disabled: false,
              to: "modelo._absenteismo",
            },
            {
              text: "Editar",
              disabled: true,
              href: "",
            }
          ]
        },
      },
      {
        path: 'modelo/absenteismo/inserir',
        name: 'modelo._absenteismo.register',
        component: () => import('@/views/Cliente/Modelo/Absenteismo/Inserir'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fas fa-user-times',
            name: 'Modelo de Absenteísmo'
          },
          menuNavigation: [{
              text: "Modelo de Absenteísmo",
              disabled: false,
              to: "modelo._absenteismo",
            },
            {
              text: "Inserir",
              disabled: true,
              href: "",
            }
          ]
        },
      },
      //MODELO CONSULTA
      {
        path: 'modelo/consulta',
        name: 'modelo._consulta',
        component: () => import('@/views/Cliente/Modelo/Consulta/Index'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa-solid fa-user-doctor',
            name: 'Modelo de Consulta'
          },
          menuNavigation: [{
            text: "Modelo de Consulta",
            disabled: true,
            to: "modelo._consulta",
          }, ]
        },
      },
      {
        path: 'modelo/consulta/editar/:intId',
        name: 'modelo._consulta.edit',
        component: () => import('@/views/Cliente/Modelo/Consulta/Editar'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa-solid fa-user-doctor',
            name: 'Modelo de Consulta'
          },
          menuNavigation: [{
              text: "Modelo de Consulta",
              disabled: false,
              to: "modelo._consulta",
            },
            {
              text: "Editar",
              disabled: true,
              href: "",
            }
          ]
        },
      },
      {
        path: 'modelo/consulta/inserir',
        name: 'modelo._consulta.register',
        component: () => import('@/views/Cliente/Modelo/Consulta/Inserir'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa-solid fa-user-doctor',
            name: 'Modelo de Consulta'
          },
          menuNavigation: [{
              text: "Modelo de Consulta",
              disabled: false,
              to: "modelo._consulta",
            },
            {
              text: "Inserir",
              disabled: true,
              href: "",
            }
          ]
        },
      },
      //MODELO PRONTUARIO
      {
        path: 'modelo/prontuario',
        name: 'modelo._prontuario',
        component: () => import('@/views/Cliente/Modelo/Prontuario/Index'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa fa-file-word-o',
            name: 'Modelo de Prontuário'
          },
          menuNavigation: [{
            text: "Modelo de Prontuário",
            disabled: true,
            to: "modelo._prontuario",
          }, ]
        },
      },
      {
        path: 'modelo/prontuario/editar/:intId',
        name: 'modelo._prontuario.edit',
        component: () => import('@/views/Cliente/Modelo/Prontuario/Editar'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa fa-file-word-o',
            name: 'Modelo de Prontuário'
          },
          menuNavigation: [{
              text: "Modelo de Prontuário",
              disabled: false,
              to: "modelo._prontuario",
            },
            {
              text: "Editar",
              disabled: true,
              href: "",
            }
          ]
        },
      },
      {
        path: 'modelo/prontuario/inserir',
        name: 'modelo._prontuario.register',
        component: () => import('@/views/Cliente/Modelo/Prontuario/Inserir'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa fa-file-word-o',
            name: 'Modelo de Prontuário'
          },
          menuNavigation: [{
              text: "Modelo de Prontuário",
              disabled: false,
              to: "modelo._prontuario",
            },
            {
              text: "Inserir",
              disabled: true,
              href: "",
            }
          ]
        },
      },
      //MODELO GUIA EXAMES
      {
        path: 'modelo/guia',
        name: 'modelo._guia',
        component: () => import('@/views/Cliente/Modelo/Guia/Index'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa-solid fa-table-list',
            name: 'Modelo de Guia de Exames'
          },
          menuNavigation: [{
            text: "Modelo de Guia de Exames",
            disabled: true,
            to: "modelo._guia",
          }]
        },
      },
      {
        path: 'modelo/guia/editar/:intId',
        name: 'modelo._guia.edit',
        component: () => import('@/views/Cliente/Modelo/Guia/Editar'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa-solid fa-table-list',
            name: 'Modelo de Guia de Exames'
          },
          menuNavigation: [{
              text: "Modelo de Guia de Exames",
              disabled: false,
              to: "modelo._guia",
            },
            {
              text: "Editar",
              disabled: true,
              href: "",
            }
          ]
        },
      },
      {
        path: 'modelo/guia/inserir',
        name: 'modelo._guia.register',
        component: () => import('@/views/Cliente/Modelo/Guia/Inserir'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa-solid fa-table-list',
            name: 'Modelo de Guia de Exames'
          },
          menuNavigation: [{
              text: "Modelo de Guia de Exames",
              disabled: false,
              to: "modelo._guia",
            },
            {
              text: "Editar",
              disabled: true,
              href: "",
            }
          ]
        },
      },
      //MODELO PGR
      {
        path: 'modelo/pgr',
        name: 'modelo._pgr',
        component: () => import('@/views/Cliente/Modelo/PGR/Index'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa-solid fa-triangle-exclamation',
            name: 'Modelo de PGR'
          },
          menuNavigation: [{
            text: "Modelo PGR",
            disabled: true,
            href: "",
          }]
        },
      },
      {
        path: 'modelo/pgr/editar/:intId',
        name: 'modelo._pgr.edit',
        component: () => import('@/views/Cliente/Modelo/PGR/Editar'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa-solid fa-triangle-exclamation',
            name: 'Modelo de PGR'
          },
          menuNavigation: [{
              text: "Modelo PGR",
              disabled: false,
              to: "modelo._pgr",
            },
            {
              text: "Editar",
              disabled: true,
              href: "",
            }
          ]
        },
      },
      {
        path: 'modelo/pgr/inserir',
        name: 'modelo._pgr.register',
        component: () => import('@/views/Cliente/Modelo/PGR/Inserir'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa-solid fa-triangle-exclamation',
            name: 'Modelo de PGR'
          },
          menuNavigation: [{
              text: "Modelo PGR",
              disabled: false,
              to: "modelo._pgr",
            },
            {
              text: "Inserir",
              disabled: true,
              href: "",
            }
          ]
        },
      },
      //MODELO DE PCMSO
      {
        path: 'modelo/pcmso',
        name: 'modelo._pcmso',
        component: () => import('@/views/Cliente/Modelo/PCMSO/Index'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa-solid fa-suitcase-medical',
            name: 'Modelo de PCMSO'
          },
          menuNavigation: [{
            text: "Modelo de PCMSO",
            disabled: true,
            href: "",
          }]
        },
      },
      {
        path: 'modelo/pcmso/editar/:intId',
        name: 'modelo._pcmso.edit',
        component: () => import('@/views/Cliente/Modelo/PCMSO/Editar'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa-solid fa-suitcase-medical',
            name: 'Modelo de PCMSO'
          },
          menuNavigation: [{
              text: "Modelo de PCMSO",
              disabled: false,
              to: "modelo._pcmso",
            },
            {
              text: "Editar",
              disabled: true,
              href: "",
            }
          ]
        },
      },
      {
        path: 'modelo/pcmso/inserir',
        name: 'modelo._pcmso.register',
        component: () => import('@/views/Cliente/Modelo/PCMSO/Inserir'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa-solid fa-suitcase-medical',
            name: 'Modelo de PCMSO'
          },
          menuNavigation: [{
              text: "Modelo de PCMSO",
              disabled: false,
              to: "modelo._pcmso",
            },
            {
              text: "Editar",
              disabled: true,
              href: "",
            }
          ]
        },
      },
      //MODELO DE LTCAT
      {
        path: 'modelo/ltcat',
        name: 'modelo._ltcat',
        component: () => import('@/views/Cliente/Modelo/LTCAT/Index'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa-solid fa-person-circle-exclamation',
            name: 'Modelo de LTCAT'
          },
          menuNavigation: [{
            text: "Modelo LTCAT",
            disabled: true,
            href: "",
          }]
        },
      },
      {
        path: 'modelo/ltcat/editar/:intId',
        name: 'modelo._ltcat.edit',
        component: () => import('@/views/Cliente/Modelo/LTCAT/Editar'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa-solid fa-person-circle-exclamation',
            name: 'Modelo de LTCAT'
          },
          menuNavigation: [{
              text: "Modelo LTCAT",
              disabled: false,
              to: "modelo._ltcat",
            },
            {
              text: "Editar",
              disabled: true,
              href: "",
            }
          ]
        },
      },
      {
        path: 'modelo/ltcat/inserir',
        name: 'modelo._ltcat.register',
        component: () => import('@/views/Cliente/Modelo/LTCAT/Inserir'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa-solid fa-person-circle-exclamation',
            name: 'Modelo de LTCAT'
          },
          menuNavigation: [{
              text: "Modelo LTCAT",
              disabled: false,
              to: "modelo._ltcat",
            },
            {
              text: "Inserir",
              disabled: true,
              href: "",
            }
          ]
        },
      },
      //MODELO DE PPP
      {
        path: 'modelo/ppp',
        name: 'modelo._ppp',
        component: () => import('@/views/Cliente/Modelo/PPP/Index'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa fa-address-book',
            name: 'Modelo de PPP'
          },
          menuNavigation: [{
            text: "Modelo",
            disabled: true,
            href: "",
          }]
        },
      },
      {
        path: 'modelo/ppp/editar/:intId',
        name: 'modelo._ppp.edit',
        component: () => import('@/views/Cliente/Modelo/PPP/Editar'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa fa-address-book',
            name: 'Modelo de PPP'
          },
          menuNavigation: [{
              text: "Modelo",
              disabled: false,
              to: "modelo._ppp",
            },
            {
              text: "Editar",
              disabled: true,
              href: "",
            }
          ]
        },
      },
      {
        path: 'modelo/ppp/inserir',
        name: 'modelo._ppp.register',
        component: () => import('@/views/Cliente/Modelo/PPP/Inserir'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa fa-address-book',
            name: 'Modelo de PPP'
          },
          menuNavigation: [{
              text: "Modelo de PPP",
              disabled: false,
              to: "modelo._ppp",
            },
            {
              text: "Inserir",
              disabled: true,
              href: "",
            }
          ]
        },
      },
      //MARKETING CAMPANHA
      {
        path: 'marketing/campanha',
        name: 'marketing.campanha',
        component: () => import('@/views/Cliente/Marketing/Campanha/Index'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa fa-bullhorn',
            name: 'Marketing'
          },
          menuNavigation: [{
              text: "Marketing",
              disabled: true,
            },
            {
              text: "Campanha",
              disabled: true,
              to: "marketing.campanha",
            }
          ]
        },
      },
      {
        path: 'marketing/campanha/editar/:intId',
        name: 'marketing.campanha.edit',
        component: () => import('@/views/Cliente/Marketing/Campanha/Editar'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa fa-bullhorn',
            name: 'Marketing'
          },
          menuNavigation: [{
              text: "Marketing",
              disabled: true,
              to: "marketing.campanha",
            },
            {
              text: "Campanha",
              disabled: false,
              to: "marketing.campanha",
            },
            {
              text: "Editar",
              disabled: true,
              href: "",
            }
          ]
        },
      },
      {
        path: 'marketing/guia/inserir',
        name: 'marketing.campanha.register',
        component: () => import('@/views/Cliente/Marketing/Campanha/Inserir'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa fa-bullhorn',
            name: 'Marketing'
          },
          menuNavigation: [{
              text: "Marketing",
              disabled: true,
            },
            {
              text: "Campanha",
              disabled: false,
              to: "marketing.campanha",
            },
            {
              text: "Editar",
              disabled: true,
              href: "",
            }
          ]
        },
      },
      //MARKETING LISTA
      {
        path: 'marketing/lista',
        name: 'marketing.lista',
        component: () => import('@/views/Cliente/Marketing/Lista/Index'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa fa-bullhorn',
            name: 'Marketing'
          },
          menuNavigation: [{
              text: "Marketing",
              disabled: true,
            },
            {
              text: "Lista de Contato",
              disabled: true,
              to: "marketing.lista",
            }
          ]
        },
      },
      {
        path: 'marketing/lista/editar/:intId',
        name: 'marketing.lista.edit',
        component: () => import('@/views/Cliente/Marketing/Lista/Editar'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa fa-bullhorn',
            name: 'Marketing'
          },
          menuNavigation: [{
              text: "Marketing",
              disabled: true,
            },
            {
              text: "Lista de Contato",
              disabled: false,
              to: "marketing.lista",
            },
            {
              text: "Editar",
              disabled: true,
              href: "",
            }
          ]
        },
      },
      {
        path: 'marketing/lista/inserir',
        name: 'marketing.lista.register',
        component: () => import('@/views/Cliente/Marketing/Lista/Inserir'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa fa-bullhorn',
            name: 'Marketing'
          },
          menuNavigation: [{
              text: "Marketing",
              disabled: true,
              to: "marketing.campanha",
            },
            {
              text: "Lista de Contato",
              disabled: false,
              to: "marketing.lista",
            },
            {
              text: "Editar",
              disabled: true,
              href: "",
            }
          ]
        },
      },
      //MARKETING TEMPLATE
      {
        path: 'marketing/template',
        name: 'marketing.template',
        component: () => import('@/views/Cliente/Marketing/Template/Index'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa fa-bullhorn',
            name: 'Marketing'
          },
          menuNavigation: [{
              text: "Marketing",
              disabled: true,
            },
            {
              text: "Modelo de Campanha",
              disabled: true,
              to: "marketing.template",
            }
          ]
        },
      },
      {
        path: 'marketing/template/editar/:intId',
        name: 'marketing.template.edit',
        component: () => import('@/views/Cliente/Marketing/Template/Editar'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa fa-bullhorn',
            name: 'Marketing'
          },
          menuNavigation: [{
              text: "Marketing",
              disabled: true,
            },
            {
              text: "Template",
              disabled: false,
              to: "marketing.template",
            },
            {
              text: "Editar",
              disabled: true,
              href: "",
            }
          ]
        },
      },
      {
        path: 'marketing/template/inserir',
        name: 'marketing.template.register',
        component: () => import('@/views/Cliente/Marketing/Template/Inserir'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa fa-bullhorn',
            name: 'Marketing'
          },
          menuNavigation: [{
              text: "Marketing",
              disabled: true,
            },
            {
              text: "Template",
              disabled: false,
              to: "marketing.template",
            },
            {
              text: "Editar",
              disabled: true,
              href: "",
            }
          ]
        },
      },
      //CONFIGURAÇÃO
      {
        path: 'consulta/configuracao',
        name: 'consulta.config',
        component: () => import('@/views/Cliente/Consulta/Configuracao'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa-solid fa-gears',
            name: 'Configurações'
          },
          menuNavigation: [{
              text: "Consultas",
              disabled: false,
              to: "consulta.search",
            },
            {
              text: "Configurações",
              disabled: true,
              href: "",
            }
          ]
        },
      },
      {
        path: 'pcmso',
        name: 'pcmso',
        component: () => import('@/views/Cliente/PCMSO/Index'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa-solid fa-suitcase-medical',
            name: 'PCMSO'
          },
        },
      },
      {
        path: 'pcmso/inserir',
        name: 'pcmso.register',
        component: () => import('@/views/Cliente/PCMSO/Inserir'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa-solid fa-suitcase-medical',
            name: 'Inserir PCMSO'
          },
          menuNavigation: [{
              text: "PCMSO",
              disabled: false,
              to: "pcmso",
            },
            {
              text: "Inserir PCMSO",
              disabled: true,
              href: "",
            }
          ]
        },
      },
      {
        path: 'pcmso/editar/:intCadastroGeralEmpresaId/:intCadastroGeralUnidadeId?/:intRevisaoId?/:intCadastroGeralSetorId?',
        name: 'pcmso.edit',
        component: () => import('@/views/Cliente/PCMSO/Editar'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa-solid fa-suitcase-medical',
            name: 'Editar PCMSO'
          },
          menuNavigation: [{
              text: "PCMSO",
              disabled: false,
              to: "pcmso",
            },
            {
              text: "Editar PCMSO",
              disabled: true,
              href: "",
            }
          ]
        },
      },
      //GRO
      {
        path: 'gro',
        name: 'gro',
        component: () => import('@/views/Cliente/GRO/Index'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa fa-plus-square',
            name: 'Gerenciamento de Riscos Ocupacionais'
          },
        },
      },
      {
        path: 'gro/inserir',
        name: 'gro.register',
        component: () => import('@/views/Cliente/GRO/Inserir'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa fa-plus-square',
            name: 'Gerenciamento de Riscos Ocupacionais'
          },
          menuNavigation: [{
              text: "Gerenciamento de Riscos Ocupacionais",
              disabled: false,
              to: "gro",
            },
            {
              text: "Inserir Gerenciamento de Riscos Ocupacionais",
              disabled: true,
              href: "",
            }
          ]
        },
      },
      {
        path: 'gro/editar/:intCadastroGeralEmpresaId/:intCadastroGeralUnidadeId?/:intRevisaoId?/:intCadastroGeralSetorId?',
        name: 'gro.edit',
        component: () => import('@/views/Cliente/GRO/Editar'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa fa-industry',
            name: 'Editar Gerenciamento de Riscos Ocupacionais'
          },
          menuNavigation: [{
              text: "Gerenciamento de Riscos Ocupacionais",
              disabled: false,
              to: "gro",
            },
            {
              text: "Editar Gerenciamento de Riscos Ocupacionais",
              disabled: true,
              href: "",
            }
          ]
        },
      },
      //MATRIZ DE RISCO
      {
        path: 'gro/matriz',
        name: 'gro.matriz',
        component: () => import('@/views/Cliente/GRO/Matriz'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa fa-table',
            name: 'Matriz de Severidade'
          },
          menuNavigation: [{
              text: "Gerenciamento de Riscos Ocupacionais",
              disabled: false,
              to: "gro",
            },
            {
              text: "Matriz de Severidade",
              disabled: true,
              href: "",
            }
          ]
        },
      },
      //FABRICANTE
      {
        path: 'cadastro/fabricante',
        name: 'gro.fabricante',
        component: () => import('@/views/Cliente/Cadastro/Fabricante/Index'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa fa-building-o',
            name: 'Fabricante'
          },
          menuNavigation: [{
              text: "Gerenciamento de Riscos Ocupacionais",
              disabled: false,
              to: "gro",
            },
            {
              text: "Fabricante",
              disabled: true,
              href: "",
            }
          ]
        },
      },
      {
        path: 'cadastro/fabricante/inserir',
        name: 'gro.fabricante.register',
        component: () => import('@/views/Cliente/Cadastro/Fabricante/Inserir'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa fa-building-o',
            name: 'Fabricante'
          },
          menuNavigation: [{
              text: "Gerenciamento de Riscos Ocupacionais",
              disabled: false,
              to: "gro",
            },
            {
              text: "Fabricante",
              disabled: false,
              to: "gro.fabricante",
            },
            {
              text: "Inserir Fabricante",
              disabled: true,
              href: "",
            }
          ]
        },
      },
      {
        path: 'cadastro/fabricante/editar/:intId',
        name: 'gro.fabricante.edit',
        component: () => import('@/views/Cliente/Cadastro/Fabricante/Editar'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa fa-building-o',
            name: 'Fabricante'
          },
          menuNavigation: [{
              text: "Gerenciamento de Riscos Ocupacionais",
              disabled: false,
              href: "",
            },
            {
              text: "Fabricante",
              disabled: false,
              to: "gro.fabricante",
            },
            {
              text: "Editar Fabricante",
              disabled: true,
              href: "",
            }
          ]
        },
      },
      // EPI
      {
        path: 'gro/epi',
        name: 'gro.epi',
        component: () => import('@/views/Cliente/GRO/Epi/Index'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa fa-headphones',
            name: 'Equipamento de Proteção Individual'
          },
          menuNavigation: [{
              text: "Gerenciamento de Riscos Ocupacionais",
              disabled: false,
              to: "gro",
            },
            {
              text: "Equipamento de Proteção Individual",
              disabled: true,
              href: "",
            }
          ]
        },
      },
      {
        path: 'gro/epi/inserir',
        name: 'gro.epi.register',
        component: () => import('@/views/Cliente/GRO/Epi/Inserir'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa fa-headphones',
            name: 'Equipamento de Proteção Individual'
          },
          menuNavigation: [{
              text: "Gerenciamento de Riscos Ocupacionais",
              disabled: false,
              to: "gro",
            },
            {
              text: "Equipamento de Proteção Individual",
              disabled: false,
              to: "gro.epi",
            },
            {
              text: "Inserir",
              disabled: true,
              href: "",
            }
          ]
        },
      },
      {
        path: 'gro/epi/editar/:intId',
        name: 'gro.epi.edit',
        component: () => import('@/views/Cliente/GRO/Epi/Editar'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa fa-headphones',
            name: 'Equipamento de Proteção Individual'
          },
          menuNavigation: [{
              text: "Gerenciamento de Riscos Ocupacionais",
              disabled: false,
              to: "gro",
            },
            {
              text: "Equipamento de Proteção Individual",
              disabled: false,
              to: "gro.epi",
            },
            {
              text: "Editar",
              disabled: true,
              href: "",
            }
          ]
        },
      },
      // EPC
      {
        path: 'gro/epc',
        name: 'gro.epc',
        component: () => import('@/views/Cliente/GRO/Epc/Index'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa fa-cubes',
            name: 'Equipamento de Proteção Coletiva'
          },
          menuNavigation: [{
              text: "Gerenciamento de Riscos Ocupacionais",
              disabled: false,
              to: "gro",
            },
            {
              text: "Equipamento de Proteção Coletiva",
              disabled: true,
              href: "",
            }
          ]
        },
      },
      {
        path: 'gro/epc/inserir',
        name: 'gro.epc.register',
        component: () => import('@/views/Cliente/GRO/Epc/Inserir'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa fa-cubes',
            name: 'Equipamento de Proteção Coletiva'
          },
          menuNavigation: [{
              text: "Gerenciamento de Riscos Ocupacionais",
              disabled: false,
              to: "gro",
            },
            {
              text: "Equipamento de Proteção Coletiva",
              disabled: false,
              to: "gro.epc",
            },
            {
              text: "Inserir",
              disabled: true,
              href: "",
            }
          ]
        },
      },
      {
        path: 'gro/epc/editar/:intId',
        name: 'gro.epc.edit',
        component: () => import('@/views/Cliente/GRO/Epc/Editar'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa fa-cubes',
            name: 'Equipamento de Proteção Coletiva'
          },
          menuNavigation: [{
              text: "Gerenciamento de Riscos Ocupacionais",
              disabled: false,
              to: "gro",
            },
            {
              text: "Equipamento de Proteção Coletiva",
              disabled: false,
              to: "gro.epc",
            },
            {
              text: "Editar",
              disabled: true,
              href: "",
            }
          ]
        },
      },
      //GRO CONFIGURAÇÕES
      {
        path: 'gro/configuracao',
        name: 'gro.config',
        component: () => import('@/views/Cliente/GRO/Configuracao'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa-solid fa-gears',
            name: 'Configurações'
          },
          menuNavigation: [{
              text: "Gerenciamento de Riscos Ocupacionais",
              disabled: false,
              to: "gro",
            },
            {
              text: "Configurações",
              disabled: true,
              href: "",
            }
          ]
        },
      },
      //
      {
        path: 'ppp',
        name: 'ppp',
        component: () => import('@/views/Cliente/PPP/Index'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa fa-address-book',
            name: 'PPP'
          },
          menuNavigation: [{
            text: "Periódico",
            disabled: false,
            to: "ppp",
          }, ]
        },
      },
      //PERIODICO LISTA DE CONVOCAÇAO
      {
        path: 'periodico/lista-convocacao',
        name: 'periodico.lista',
        component: () => import('@/views/Cliente/Periodico/Index'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa fa-list-ol',
            name: 'Lista de Convocação'
          },
          menuNavigation: [{
              text: "Periódico",
              disabled: true,
            },
            {
              text: "Lista de Convocação",
              disabled: true,
              to: "periodico.lista",
            },
          ]
        },
      },
      //PERIODICO DISPARAR
      {
        path: 'periodico/disparar-lista-convocacao',
        name: 'periodico.disparar',
        component: () => import('@/views/Cliente/Periodico/Disparar/Index'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa fa-paper-plane',
            name: 'Disparar Lista de Convocação'
          },
          menuNavigation: [{
              text: "Periódico",
              disabled: true,
            },
            {
              text: "Disparar Lista de Convocação",
              disabled: true,
              to: "periodico.disparar",
            },
          ]
        },
      },
      {
        path: 'periodico/disparar-lista-convocacao/editar/:intId',
        name: 'periodico.disparar.edit',
        component: () => import('@/views/Cliente/Periodico/Disparar/Editar'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa fa-paper-plane',
            name: 'Disparar Lista de Convocação'
          },
          menuNavigation: [{
              text: "Periódico",
              disabled: true,
            },
            {
              text: "Disparar Lista de Convocação",
              disabled: false,
              to: "periodico.disparar",
            },
            {
              text: "Editar",
              disabled: true,
            },
          ]
        },
      },
      {
        path: 'periodico/disparar-lista-convocacao/inserir',
        name: 'periodico.disparar.register',
        component: () => import('@/views/Cliente/Periodico/Disparar/Inserir'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa fa-paper-plane',
            name: 'Disparar Lista de Convocação'
          },
          menuNavigation: [{
              text: "Periódico",
              disabled: true,
            },
            {
              text: "Disparar Lista de Convocação",
              disabled: false,
              to: "periodico.disparar",
            },
            {
              text: "Inserir",
              disabled: true,
            },
          ]
        },
      },
      //PERIODICO CONFIGURACAO
      {
        path: 'periodico/configuracao',
        name: 'periodico.config',
        component: () => import('@/views/Cliente/Periodico/Configuracao'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa-solid fa-gears',
            name: 'Configuração Periódico'
          },
          menuNavigation: [{
              text: "Periódico",
              disabled: true,
            },
            {
              text: "Configuração",
              disabled: false,
              to: "periodico.config",
            },
          ]
        },
      },

      //ESOCIAL
      {
        path: 'esocial',
        name: 'esocial',
        component: () => import('@/views/Cliente/Esocial/Index'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa-solid fa-file-code',
            name: 'E-Social'
          },
          menuNavigation: [{
            text: "E-Social",
            disabled: false,
            to: "esocial",
          }, ]
        },
      },
      {
        path: 'esocial/lote',
        name: 'esocial.lote',
        component: () => import('@/views/Cliente/Esocial/Lote'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa-solid fa-file-signature',
            name: 'Monitoramento de Lote'
          },
          menuNavigation: [{
            text: "Monitoramento de Lote",
            disabled: false,
            to: "esocial.lote",
          }, ]
        },
      },
      {
        path: 'esocial/configuracao',
        name: 'esocial.config',
        component: () => import('@/views/Cliente/Esocial/Configuracao'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa-solid fa-gears',
            name: 'Configuracao E-Social'
          },
          menuNavigation: [{
            text: "Configuracao E-Social",
            disabled: false,
            to: "esocial.config",
          }, ]
        },
      },
      //AUDIOMETRIA
      {
        path: 'audiometria/audiometro',
        name: 'audiometria.audiometro',
        component: () => import('@/views/Cliente/Audiometria/Audiometro/Index'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa-solid fa-sliders',
            name: 'Audiomêtro'
          },
          menuNavigation: [{
            text: "Audiomêtro",
            disabled: true,
          }, ]
        },
      },
      {
        path: 'audiometria/audiometro/inserir',
        name: 'audiometria.audiometro.register',
        component: () => import('@/views/Cliente/Audiometria/Audiometro/Inserir'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa fa-file-audio-o',
            name: 'Audiomêtro'
          },
          menuNavigation: [{
              text: "Audiomêtro",
              disabled: false,
              to: "audiometria.audiometro",
            },
            {
              text: "Inserir",
              disabled: true,
            },
          ]
        },
      },
      {
        path: 'audiometria/audiometro/editar/:intId',
        name: 'audiometria.audiometro.edit',
        component: () => import('@/views/Cliente/Audiometria/Audiometro/Editar'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa fa-file-audio-o',
            name: 'Audiomêtro'
          },
          menuNavigation: [{
              text: "Audiomêtro",
              disabled: false,
              to: "audiometria.audiometro",
            },
            {
              text: "Editar",
              disabled: true,
            },
          ]
        },
      },
      {
        path: 'audiometria/configuracao',
        name: 'audiometria.config',
        component: () => import('@/views/Cliente/Audiometria/Configuracao'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa-solid fa-gears',
            name: 'Configurações'
          },
          menuNavigation: [{
            text: "Configurações",
            disabled: true,
          }, ]
        },
      },
      //RELATORIOS
      {
        path: 'relatorio/sesmt',
        name: 'relatorio.sesmt',
        component: () => import('@/views/Cliente/Relatorio/SESMT'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa fa-line-chart',
            name: 'Relatório'
          },
          menuNavigation: [{
            text: "Relatório",
            disabled: true,
            to: "relatorio",
          }, {
            text: "SESMT",
            disabled: false,
            to: "relatorio.sesmt",
          }
        ]
        },
      },
      //CONTAS A RECEBER
      {
        path: 'financeiro/contas-receber',
        name: 'financeiro.receita',
        component: () => import('@/views/Cliente/Financeiro/Receita/Index'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fas fa-dollar-sign',
            name: 'Financeiro'
          },
          menuNavigation: [{
              text: "Financeiro",
              disabled: true,
            },
            {
              text: "Contas a Receber",
              disabled: true,
              to: "financeiro.receita",
            }
          ]
        },
      },
      {
        path: 'financeiro/contas-receber/inserir',
        name: 'financeiro.receita.register',
        component: () => import('@/views/Cliente/Financeiro/Receita/Inserir'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fas fa-dollar-sign',
            name: 'Financeiro'
          },
          menuNavigation: [{
              text: "Financeiro",
              disabled: true,
            },
            {
              text: "Contas a Receber",
              disabled: false,
              to: "financeiro.receita",
            },
            {
              text: "Inserir",
              disabled: true,
            },
          ]
        },
      },
      {
        path: 'financeiro/contas-receber/editar/:intId/:intMovimentoId?',
        name: 'financeiro.receita.edit',
        component: () => import('@/views/Cliente/Financeiro/Receita/Editar'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fas fa-dollar-sign',
            name: 'Financeiro'
          },
          menuNavigation: [{
              text: "Financeiro",
              disabled: true,
            },
            {
              text: "Contas a Receber",
              disabled: false,
              to: "financeiro.receita",
            },
            {
              text: "Inserir",
              disabled: true,
            },
          ]
        },
      },
      //CONTAS A PAGAR
      {
        path: 'financeiro/contas-pagar',
        name: 'financeiro.despesa',
        component: () => import('@/views/Cliente/Financeiro/Despesa/Index'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fas fa-dollar-sign',
            name: 'Financeiro'
          },
          menuNavigation: [{
              text: "Financeiro",
              disabled: true,
            },
            {
              text: "Contas a Pagar",
              disabled: true,
              to: "financeiro.despesa",
            }
          ]
        },
      },
      {
        path: 'financeiro/contas-pagar/inserir',
        name: 'financeiro.despesa.register',
        component: () => import('@/views/Cliente/Financeiro/Despesa/Inserir'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fas fa-dollar-sign',
            name: 'Financeiro'
          },
          menuNavigation: [{
              text: "Financeiro",
              disabled: true,
            },
            {
              text: "Contas a Pagar",
              disabled: false,
              to: "financeiro.despesa",
            },
            {
              text: "Inserir",
              disabled: true,
            },
          ]
        },
      },
      {
        path: 'financeiro/contas-pagar/editar/:intId/:intMovimentoId?',
        name: 'financeiro.despesa.edit',
        component: () => import('@/views/Cliente/Financeiro/Despesa/Editar'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fas fa-dollar-sign',
            name: 'Financeiro'
          },
          menuNavigation: [{
              text: "Financeiro",
              disabled: true,
            },
            {
              text: "Contas a Pagar",
              disabled: false,
              to: "financeiro.despesa",
            },
            {
              text: "Inserir",
              disabled: true,
            },
          ]
        },
      },
      //FINANCEIRO FLUXO CAIXA
      {
        path: 'financeiro/fluxo-caixa',
        name: 'financeiro.caixa',
        component: () => import('@/views/Cliente/Financeiro/Caixa/Index'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fas fa-dollar-sign',
            name: 'Financeiro'
          },
          menuNavigation: [{
              text: "Financeiro",
              disabled: true,
            },
            {
              text: "Fluxo de Caixa",
              disabled: true,
              to: "financeiro.caixa",
            }
          ]
        },
      },
      //FINANCEIRO CONFIGURACOES
      {
        path: 'financeiro/configuracoes',
        name: 'financeiro.config',
        component: () => import('@/views/Cliente/Financeiro/Configuracao/Index'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fas fa-dollar-sign',
            name: 'Financeiro'
          },
          menuNavigation: [{
              text: "Financeiro",
              disabled: true,
            },
            {
              text: "Configurações",
              disabled: true,
              to: "financeiro.config",
            }
          ]
        },
      },
      //NOTA FISCAL
      {
        path: 'nota-fiscal',
        name: 'nfse',
        component: () => import('@/views/Cliente/NotaFiscal/Index'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'far fa-file-code',
            name: 'Nota Fiscal'
          },
          menuNavigation: [{
            text: "Nota Fiscal",
            disabled: false,
            to: "nfse",
          }, ]
        },
      },
      {
        path: 'nota-fiscal/configuracao',
        name: 'nfse.config',
        component: () => import('@/views/Cliente/NotaFiscal/Configuracao'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'far fa-file-code',
            name: 'Nota Fiscal'
          },
          menuNavigation: [{
            text: "Nota Fiscal",
            disabled: false,
            to: "nfse",
          }, 
          {
            text: "Configurações",
            disabled: true,
            to: "nfse.config",
          }
        ]
        },
      },
      //
      {
        path: 'configuracoes',
        name: 'configuracoes',
        component: () => import('@/views/Cliente/Configuracoes/Index'),
        meta: {
          boolRequiredAuthentication: true,
        },
      },
      //CERTIFICADO
      {
        path: 'configuracoes/certificado',
        name: 'configuracoes.certificado',
        component: () => import('@/views/Cliente/Configuracoes/Certificado/Index'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa fa-certificate',
            name: 'Certificado'
          },
          menuNavigation: [{
              text: "Configurações",
              disabled: true,
            },
            {
              text: "Certificado",
              disabled: true,
              to: "configuracoes.certificado",
            },
          ]
        },
      },
      {
        path: 'configuracoes/certificado/inserir',
        name: 'configuracoes.certificado.register',
        component: () => import('@/views/Cliente/Configuracoes/Certificado/Inserir'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa fa-certificate',
            name: 'Certificado'
          },
          menuNavigation: [{
              text: "Configurações",
              disabled: true,
            },
            {
              text: "Certificado",
              disabled: false,
              to: "configuracoes.certificado",
            },
            {
              text: "Inserir",
              disabled: true,
              to: "configuracoes.certificado.register",
            },
          ]
        },
      },
      {
        path: 'configuracoes/certificado/editar/:intId',
        name: 'configuracoes.certificado.edit',
        component: () => import('@/views/Cliente/Configuracoes/Certificado/Editar'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa fa-certificate',
            name: 'Certificado'
          },
          menuNavigation: [{
              text: "Configurações",
              disabled: true,
            },
            {
              text: "Certificado",
              disabled: false,
              to: "configuracoes.certificado",
            },
            {
              text: "Inserir",
              disabled: true,
              to: "configuracoes.certificado.edit",
            },
          ]
        },
      },
      //CONTA BANCARIA
      {
        path: 'configuracoes/conta-bancaria',
        name: 'configuracoes.contaBancaria',
        component: () => import('@/views/Cliente/Configuracoes/ContaBancaria/Index'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fas fa-money-check-alt',
            name: 'Conta Bancária'
          },
          menuNavigation: [{
              text: "Configurações",
              disabled: true,
            },
            {
              text: "Conta Bancária",
              disabled: true,
              to: "configuracoes.contaBancaria",
            },
          ]
        },
      },
      {
        path: 'configuracoes/conta-email/inserir',
        name: 'configuracoes.contaBancaria.register',
        component: () => import('@/views/Cliente/Configuracoes/ContaBancaria/Inserir'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fas fa-money-check-alt',
            name: 'Conta Bancária'
          },
          menuNavigation: [{
              text: "Configurações",
              disabled: true,
            },
            {
              text: "Conta Bancária",
              disabled: false,
              to: "configuracoes.contaBancaria",
            },
            {
              text: "Inserir",
              disabled: true,
              to: "configuracoes.contaBancaria.register",
            },
          ]
        },
      },
      {
        path: 'configuracoes/conta-email/editar/:intId',
        name: 'configuracoes.contaBancaria.edit',
        component: () => import('@/views/Cliente/Configuracoes/ContaBancaria/Editar'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fas fa-money-check-alt',
            name: 'Conta Bancária'
          },
          menuNavigation: [{
              text: "Configurações",
              disabled: true,
            },
            {
              text: "Conta Bancária",
              disabled: false,
              to: "configuracoes.contaBancaria",
            },
            {
              text: "Editar",
              disabled: true,
              to: "configuracoes.contaBancaria.edit",
            },
          ]
        },
      },
      //CONTA EMAIL
      {
        path: 'configuracoes/conta-email',
        name: 'configuracoes.contaEmail',
        component: () => import('@/views/Cliente/Configuracoes/ContaEmail/Index'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa fa-envelope-open',
            name: 'Conta de Email'
          },
          menuNavigation: [{
              text: "Configurações",
              disabled: true,
            },
            {
              text: "Conta de Email",
              disabled: true,
              to: "configuracoes.contaEmail",
            },
          ]
        },
      },
      {
        path: 'configuracoes/conta-email/inserir',
        name: 'configuracoes.contaEmail.register',
        component: () => import('@/views/Cliente/Configuracoes/ContaEmail/Inserir'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa fa-envelope-open',
            name: 'Conta de Email'
          },
          menuNavigation: [{
              text: "Configurações",
              disabled: true,
            },
            {
              text: "Conta de Email",
              disabled: false,
              to: "configuracoes.contaEmail",
            },
            {
              text: "Inserir",
              disabled: true,
              to: "configuracoes.contaEmail.register",
            },
          ]
        },
      },
      {
        path: 'configuracoes/conta-email/editar/:intId',
        name: 'configuracoes.contaEmail.edit',
        component: () => import('@/views/Cliente/Configuracoes/ContaEmail/Editar'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fa fa-envelope-open',
            name: 'Conta de Email'
          },
          menuNavigation: [{
              text: "Configurações",
              disabled: true,
            },
            {
              text: "Conta de Email",
              disabled: false,
              to: "configuracoes.contaEmail",
            },
            {
              text: "Inserir",
              disabled: true,
              to: "configuracoes.contaEmail.edit",
            },
          ]
        },
      },
      //CANAL INTEGRACAO
      {
        path: 'configuracoes/canal-integracao/editar/',
        name: 'configuracoes.canalIntegracao',
        component: () => import('@/views/Cliente/Configuracoes/CanalIntegracao/Editar'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fas fa-globe-americas',
            name: 'Canal de Integração'
          },
          menuNavigation: [{
              text: "Configurações",
              disabled: true,
            },
            {
              text: "Canal de Integração",
              disabled: true,
              to: "configuracoes.canalIntegracao.edit",
            },
          ]
        },
      },
      //IMPORTACAO DE DADOS
      //CONTA EMAIL
      {
        path: 'configuracoes/importacao',
        name: 'configuracoes.importacao',
        component: () => import('@/views/Cliente/Configuracoes/Importacao/Index'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fas fa-file-import',
            name: 'Importação de Dados'
          },
          menuNavigation: [{
              text: "Configurações",
              disabled: true,
            },
            {
              text: "Importação de Dados",
              disabled: true,
              to: "configuracoes.importacao",
            },
          ]
        },
      },
      {
        path: 'configuracoes/importacao/inserir',
        name: 'configuracoes.importacao.register',
        component: () => import('@/views/Cliente/Configuracoes/Importacao/Inserir'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fas fa-file-import',
            name: 'Importação de Dados'
          },
          menuNavigation: [{
              text: "Configurações",
              disabled: true,
            },
            {
              text: "Importação de Dados",
              disabled: false,
              to: "configuracoes.importacao",
            },
            {
              text: "Inserir",
              disabled: true,
              to: "configuracoes.importacao.register",
            },
          ]
        },
      },
      {
        path: 'configuracoes/importacao/editar/:intId',
        name: 'configuracoes.importacao.edit',
        component: () => import('@/views/Cliente/Configuracoes/Importacao/Editar'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fas fa-file-import',
            name: 'Importação de Dados'
          },
          menuNavigation: [{
              text: "Configurações",
              disabled: true,
            },
            {
              text: "Importação de Dados",
              disabled: false,
              to: "configuracoes.importacao",
            },
            {
              text: "Inserir",
              disabled: true,
              to: "configuracoes.importacao.edit",
            },
          ]
        },
      },
      //UPLOAD DE DOCUMENTO
      {
        path: 'configuracoes/upload',
        name: 'configuracoes.upload',
        component: () => import('@/views/Cliente/Configuracoes/Upload/Index'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fas fa-file-upload',
            name: 'Upload de Documento'
          },
          menuNavigation: [{
              text: "Configurações",
              disabled: true,
            },
            {
              text: "Upload de Documento",
              disabled: true,
              to: "configuracoes.upload",
            },
          ]
        },
      },
      //SUPORTE CLIENTE
      {
        path: 'suporte/cliente',
        name: 'suporte.cliente',
        component: () => import('@/views/Cliente/Suporte/Cliente/Index'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fas fa-user-cog',
            name: 'Clientes'
          },
          menuNavigation: [{
              text: "Suporte",
              disabled: true,
            },
            {
              text: "Clientes",
              disabled: true,
              to: "suporte.cliente",
            },
          ]
        },
      },
      {
        path: 'suporte/cliente/inserir',
        name: 'suporte.cliente.register',
        component: () => import('@/views/Cliente/Suporte/Cliente/Inserir'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fas fa-user-cog',
            name: 'Clientes'
          },
          menuNavigation: [{
              text: "Suporte",
              disabled: true,
            },
            {
              text: "Clientes",
              disabled: false,
              to: "suporte.cliente",
            },
            {
              text: "Inserir",
              disabled: true,
            },
          ]
        },
      },
      {
        path: 'suporte/cliente/editar/:intId',
        name: 'suporte.cliente.edit',
        component: () => import('@/views/Cliente/Suporte/Cliente/Editar'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fas fa-user-cog',
            name: 'Clientes'
          },
          menuNavigation: [{
              text: "Suporte",
              disabled: true,
            },
            {
              text: "Clientes",
              disabled: false,
              to: "suporte.cliente",
            },
            {
              text: "Editar",
              disabled: true,
            },
          ]
        },
      },
      //SUPORTE LOG
      {
        path: 'suporte/log',
        name: 'suporte.log',
        component: () => import('@/views/Cliente/Suporte/Log/Visao'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fas fa-history',
            name: 'Logs do Sistema'
          },
          menuNavigation: [{
              text: "Suporte",
              disabled: true,
            },
            {
              text: "Logs do Sistema",
              disabled: true,
              to: "suporte.log",
            },
          ]
        },
      },
      {
        path: 'suporte/retaguarda',
        name: 'suporte.retaguarda',
        component: () => import('@/views/Cliente/Suporte/Log/Retaguarda'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fab fa-windows',
            name: 'Logs de Fila'
          },
          menuNavigation: [{
              text: "Suporte",
              disabled: true,
            },
            {
              text: "Logs de Fila",
              disabled: true,
              to: "suporte.retaguarda",
            },
          ]
        },
      },
      {
        path: 'suporte/email',
        name: 'suporte.email',
        component: () => import('@/views/Cliente/Suporte/Log/Email'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fas fa-envelope-open',
            name: 'Logs de Email'
          },
          menuNavigation: [{
              text: "Suporte",
              disabled: true,
            },
            {
              text: "Logs de Email",
              disabled: true,
              to: "suporte.email",
            },
          ]
        },
      },
      //MEU CADASTRP
      {
        path: 'meu-cadastro',
        name: 'meucadastro',
        component: () => import('@/views/Cliente/Cadastro/MeuCadastro/Editar'),
        meta: {
          boolRequiredAuthentication: true,
          menu: {
            icon: 'fas fa-history',
            name: 'Meu Cadastro'
          },
          menuNavigation: [{
            text: "Editar",
            disabled: true,
          }, ]
        },
      },
    ]
  },

  {
    path: '/:catchAll(.*)*',
    component: () => import('@/views/Error404.vue')
  }
]

export default routes