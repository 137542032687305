<template>
   <div>
      <v-snackbar
        v-model="show"
        right
        top
        :color="type"
      >
        <span v-html="message"></span>

        <template v-slot:action="{ attrs }">
            <ed-button icon-left="fa fa-window-close" @click="show = false" onlyIcon icon color="white"/>
        </template>
      </v-snackbar>
  </div>
</template>

<script>
import {EdButton} from '@/components/common/form'

export default {
  name: "EdMessage",
  components: {EdButton},
  mixins: [],

  watch: {
  },
  data() {
    return {
      show: false,
      message: null,
      title: null,
      promise: null,
      isAlert:false,
      type:'success',
      promiseResolve: () => {},
      promiseReject: () => {},
    };
  },

  mounted() {},

  methods: {

    success(message = "", title = "Atenção") {
      this.message = message;
      this.title = title;
      this.type = 'success';
      this.show = !this.show;
    },

    error(message = "", title = "Atenção") {
      this.message = message;
      this.title = title;
      this.type = 'error';
      this.show = !this.show;
    },

    warning(message = "", title = "Atenção") {
      this.message = message;
      this.title = title;
      this.type = 'warning';
      this.show = !this.show;
    },

    resolve() {
      if (this.promiseResolve) this.promiseResolve();
      this.reset();
    },

    reject() {
      if (this.promiseReject) this.promiseReject();
      this.reset();
    },

    reset() {
      this.promise = null;
      this.promiseResolve = null;
      this.promiseReject = null;
      this.message = null;
      this.title = null;
      this.dialog = false;
      //this.$destroy();
    },
  },
};
</script>
