
const setResponse = (context,objDados) => {
	context.commit('SET_RESPONSE', objDados);
}

export{
	setResponse
}


