
const setLayout = (context,value) => {
	context.commit('SET_LAYOUT', value);
}

const setLoadingContent = (context,value) => {
	context.commit('SET_LOADING_CONTENT', value);
}

export{
	setLayout,
	setLoadingContent
}


