
const routes = [
  {
    path: '/',
    component: () => import('@/layouts/Admin'),
    children: [
      { path: '', component: () => import('@/views/Admin/Index') }
    ]
  },

  {
    path: '/:catchAll(.*)*',
    component: () => import('@/views/Error404.vue')
  }
]

export default routes
