<template>
  <div>
    <div class="ed-form-label text-left mb-2" v-if="!hideDetails">
      <div class="row ma-0 pa-0">
        <div class="col pa-0">
          <span style="color: #c63535" v-if="boolRequired">*</span> {{ label }}
        </div>
        <div class="col pa-0" v-if="this.$slots.label">
          <slot name="label"></slot>
        </div>
      </div>
    </div>
    <v-select
      label="Selecione"
      :outlined="outlined"
      :dense="dense"
      :solo="solo"
      :name="name"
      :value="value"
      :error="getError"
      :error-messages="getErrorContents"
      :loading="loading"
      :rules="getRules"
      :items="arrayTipo"
      :info="info"
      :readonly="readonly"
      :disabled="disabled"
      :return-object="isObject"
      :item-text="getLabelText"
      :multiple="multiple"
      :item-value="itemValue"
      @change="$emit('input', $event)"
      @input="onSelectedTipo($event)"
    >
      <template v-slot:append v-if="!!$slots.append || info || clearable">
        <i :class="$utilidade.getIcone('info')" :title="info" v-if="info" />
        <ed-button 
            :iconLeft="$utilidade.getIcone('clear')" 
            class="mt-0 pa-0"
            onlyIcon 
            color="#666666"
            small
            text
            v-if="showClearable" 
            @click="clear" 
        />
        <slot name="append" />
      </template>

      <template v-slot:selection="{ item, index }" v-if="multiple">
        <v-chip v-if="index === 0">
          <span>{{ item[itemText] }}</span>
        </v-chip>
        <span v-if="index === 1" class="grey--text text-caption">
          (+{{ value.length - 1 }})
        </span>
      </template>
    </v-select>
  </div>
</template>

<script>
import EdFormMixin from "./mixins/formControl";
import EdInputSelect from "./EdInputSelect";
import EdButton from './EdButton'

export default {
  name: "EdInputTipo",
  mixins: [EdFormMixin],
  components: { EdInputSelect,EdButton },
  props: {
    tipo: {
      type: [String, Number],
      required: true,
      default: null,
    },
    itemText: {
      type: [String, Number],
      required: false,
      default: "strNome",
    },
    itemValue: {
      type: [String, Number],
      required: false,
      default: "intId",
    },
    isObject: {
      ype: [Boolean],
      required: false,
      default: false,
    },
  },
  data() {
    return {
      arrayTipo: [],
    };
  },
  beforeDestroy() {
    this.arrayTipo = [];
  },
  created() {},
  mounted() {
    this.getTipo();
  },
  computed: {},
  methods: {
    onSelectedTipo(val) {
      let self = this;

      let objItem = null 

      this.arrayTipo.forEach(element => {
        if(element[this.itemValue] == val){
          objItem = element
        }
      });
      this.$emit("selected", objItem);
    },

    getTipo() {
      if (!this.tipo) return;

      this.$root.$request
        .get("Common/Tipo", { intTipoId: this.tipo })
        .then((objResponse) => {
          if (objResponse.status == 200) {
            this.arrayTipo = objResponse.result;
          }
        });
    },
  },
  watch: {
    tipo() {
      this.getTipo();
    },
    value() {},
  },
};
</script>
