<template>
  <div id="app">
    <div class="splash-content" v-if="loadingContent">
      <img width="120" :src="'/logo.png'" />
      <span>Carregando...</span>
    </div>

    <v-app id="inspire" class="hidden-x">
      <v-main>
        <ed-session ref="session" :key="'session-'+versao"></ed-session>
        <ed-request ref="request" :key="'request-'+versao">></ed-request>
        <ed-dialog ref="dialog" :key="'dialog-'+versao">></ed-dialog>
        <ed-message ref="message" :key="'message-'+versao">></ed-message>
        <ed-socket ref="socket" :key="'socket-'+versao">></ed-socket>
        <router-view />
      </v-main>
      <!-- <ed-button  rounded fab right absolute label="asdf" color="red"/> -->
      <Footer v-if="boolCarregado && !$route.meta.boolFooterDisble && $root.$session.usuario" :key="'footer-'+versao"/>
  
    </v-app>
  </div>
</template>


<script>
import Footer from "@/components/common/menu/Footer";
import {EdButton} from "@/components/common/form";

import {
  EdSession,
  EdRequest,
  EdSocket,
  EdDialog,
  EdMessage,
} from "@/components/common/service";

export default {
  name: "app",
  props: {},
  components: {
    EdSession,
    EdRequest,
    EdSocket,
    EdDialog,
    EdMessage,
    Footer,
    EdButton
  },
  computed: {
    loadingContent: {
      cache: false,
      get() {
        return this.$store.state.common.loadingContent;
      },
      set(value) {
        this.$store.dispatch("common/setLoadingContent", value);
      },
    },
  },
  created() {
    
  },
  mounted() {
    this.$root.$session = this.$refs.session;
    this.$root.$request = this.$refs.request;
    this.$root.$dialog = this.$refs.dialog;
    this.$root.$message = this.$refs.message;
    this.$root.$socket = this.$refs.socket;
    
    this.boolCarregado = true
  },
  data() {
    return {
      boolCarregado:false,
      versao:this.$cookie.get('versao')
    }
  },
};
</script>

<style lang="scss">
// @import 'assets/css/plugins.bundle';
// @import 'assets/css/prismjs.bundle';
//@import 'assets/css/style.bundle';
@import "assets/css/default";
@import "assets/css/style.video";
</style>
