<template>
  <v-form
    :ref="ref"
    :disabled="block || loading"
    autocomplete="off"
    lazy-validation
    class="ed-form"
  >
    <div class="col-md-12 pa-2 justify-center text-center">
      <div class="row ed-form-row">
        <slot />
        <div
          class="col-md-12 pa-0 ma-0 text-center d-flex align-center flex-column"
        >
          <vue-recaptcha
            v-if="recaptcha"
            style="width: 304px !imaportant"
            :sitekey="recaptchaKey"
            :key="recaptchaId"
            :loadRecaptchaScript="true"
            @verify="onRecaptchaVerify"
            @error="onRecaptchaError"
            @expired="onRecaptchaError"
            @render="onRecaptchaRender"
          />
        </div>
      </div>
    </div>

    <div
      :class="
        'row ma-0 mt-0 ed-form-footer ' +
        classFooter +
        (disableFixedSave || $root.$session.isMobile ? '' : ' ed-form-fixed ')
      "
      :style="
        'position:' +
        (disableFixedSave || $root.$session.isMobile
          ? ''
          : savePosition + ' !important')
      "
    >
      <div class="col-12 pa-0 ma-0">
        <br>
        <v-divider></v-divider>
      </div>
      <div
        :class="
          'col-12 ' +
          ($root.$session.isMobile || disableAlignRight
            ? 'text-center'
            : 'text-right')
        "
      >
        <slot name="form-footer" />
        <ed-button
          v-if="handlerSave"
          :color="colorButton"
          :label="labelButton"
          :disabled="block || !isValidRecaptcha"
          @click="validar"
          :iconLeft="iconButton"
          title="Salvar alterações"
          size="100%"
        />
      </div>
    </div>
  </v-form>
</template>

<script>
import EdButton from "./EdButton";
import { VueRecaptcha } from "vue-recaptcha";

export default {
  name: "EdForm",
  mixins: [],
  components: { EdButton, VueRecaptcha },
  props: {
    block: {
      type: Boolean,
      required: false,
      default: false,
    },
    disableFixedSave: {
      type: Boolean,
      required: false,
      default: true,
    },
    disableAlignRight: {
      type: Boolean,
      required: false,
      default: false,
    },
    reset: {
      type: Boolean,
      required: false,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    blockMessage: {
      type: String,
      required: false,
      default: null,
    },
    handlerSave: {
      type: Function,
      required: false,
      default: null,
    },
    labelButton: {
      type: String,
      required: false,
      default: "Salvar",
    },
    colorButton: {
      type: String,
      required: false,
      default: "primary",
    },
    iconButton: {
      type: String,
      required: false,
      default: "fa-regular fa-floppy-disk",
    },
    classFooter: {
      type: String,
      default: "text-right",
    },
    recaptcha: {
      type: [Boolean,Number,Object],
      default: false,
    },
    formData: {
      type: Object,
      default: null,
    },
    savePosition: {
      type: String,
      default: "fixed",
    },
  },
  data() {
    return {
      ref: "form-" + this.$utilidade.randomString(),
      recaptchaKey: process.env.VUE_APP_GOOGLE_RECAPTCHA,
      recaptchaId: 1,
    };
  },
  beforeDestroy() {},
  created() {},
  mounted() {},
  computed: {
    isValidRecaptcha() {
      if (!this.recaptcha) return true;

      return this.formData && this.formData.recaptcha;
    },
  },
  methods: {
    validar() {
      if (!this.isValidRecaptcha) {
        this.$root.$dialog.warning("Por favor confirma se você não é um robô");
        return;
      }

      if (!this.$refs[this.ref].validate()) {
        this.$root.$dialog.warning(
          "Algo não foi corretamente preenchido no formulário.\nPor favor, verifique os campos novamente."
        );
        return;
      }

      if (this.handlerSave) {
        this.handlerSave();
      }
    },

    resetValidation() {
      this.$refs[this.ref].resetValidation();
    },

    onRecaptchaVerify(ev) {
      this.formData.recaptcha = ev;
      this.$emit("recaptcha", ev);
    },

    onRecaptchaError(ev) {
      this.formData.recaptcha = null;
      this.$emit("recaptcha", null);
    },

    onRecaptchaRender(id) {},
  },
  watch: {
    block() {},
    loading() {},
    blockMessage() {},
    recaptchaResponse() {},
    handlerSave() {},
    reset() {
      this.resetValidation();
    },
    "formData.recaptcha"() {},
    "$root.$request.getResponse"() {
      let response = this.$root.$request.getResponse;

      if (response && response.status == 503) {
        this.recaptchaId++;
      }
    },
  },
};
</script>
