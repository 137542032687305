import Vue from 'vue'

const SET_DRAWER = (state,drawer) => {
	state.drawer = drawer
}

const SET_MINI = (state,mini) => {
	state.mini = mini
}

const SET_MINI_WIDTH = (state,miniWidth) => {
	state.miniWidth = miniWidth
}

const SET_ACTIVE = (state,value) => {
	state.active = value
}

export{
	SET_DRAWER,
	SET_ACTIVE,
	SET_MINI,
	SET_MINI_WIDTH
}
