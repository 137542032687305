<template>
  <!-- <ed-input-select
    :label="label"
    :name="name"
    :value="value"
    :info="info"
    :readonly="readonly"
    :disabled="disabled"
    :color="color"
    :items="[
      { intId: 1, strNome: 'SIM' },
      { intId: 0, strNome: 'NÃO' },
    ]"
    @input="$emit('input', $event)"
    chips
  /> -->

  <div>
    <div class="ed-form-label text-left mb-2" v-if="!hideDetails && !inline">
      <span style="color: #c63535" v-if="boolRequired">*</span>

      {{ label }}
      <i :class="$utilidade.getIcone('info')" :title="info" v-if="info" />
    </div>

    <v-switch
      v-model="model"
      @change="onChange"
      @click="$emit('click', $event)"
      :label="inline ? label : null"
      :rules="getRules"
      :error="getError"
      :hide-details="true"
      :outlined="outlined"
      :dense="dense"
      :solo="solo"
      :disabled="disabled"
      :persistent-hint="info ? true : false"
      :prepend-icon="iconLeft"
      :color="color"
      inset
      :error-messages="getErrorContents"
    >
    </v-switch>
  </div>
</template>

<script>
import EdFormMixin from "./mixins/formControl";
import EdInputSelect from "./EdInputSelect";

export default {
  name: "EdInputSwitch",
  mixins: [EdFormMixin],
  components: { EdInputSelect },
  props: {
    inline: {
      type: [Boolean],
      required: false,
      default: false,
    },
  },
  data() {
    return {
      model: null,
    };
  },
  beforeDestroy() {},
  created() {},
  mounted() {
    this.initialize();
  },
  computed: {},
  methods: {
    initialize() {
      this.model = this.value;

      if (this.value) {
        this.onChange(this.value);
      }
    },

    onChange(ev) {
      if (this.value && this.value == ev) return;
      this.$emit("input", ev);
      this.$emit("change", ev);
    },
  },
  watch: {
    value() {
      this.initialize();
    },
  },
};
</script>
