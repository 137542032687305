<template>
  <div></div>
</template>

<script>
export default {
  name: "ed-form-mixin",
  components: {},
  props: {
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    clearable: {
      type: Boolean,
      required: false,
      default: false,
    },
    name: {
      type: [String],
      required: false,
      default: null,
    },
    type: {
      type: [String],
      required: false,
      default: null,
    },
    color: {
      type: [String],
      required: false,
      default: null,
    },
    width: {
      type: [Number, String],
      required: false,
      default: "auto",
    },
    info: {
      type: [Number, String],
      required: false,
      default: null,
    },
    maxWidth: {
      type: [Number, String],
      required: false,
      default: null,
    },
    maxlength: {
      type: [Number, String],
      required: false,
      default: null,
    },
    label: {
      type: [Number, String, Boolean],
      required: false,
      default: null,
    },
    title: {
      type: [Number, String],
      required: false,
      default: null,
    },
    value: {
      type: [Number, String, Boolean, Array, Object, File],
      required: false,
      default: null,
    },
    color: {
      type: [String],
      required: false,
      default: "primary",
    },
    backgroundColor: {
      type: [String],
      required: false,
      default: "",
    },
    iconClass: {
      type: String,
      required: false,
      default: null,
    },
    iconRight: {
      type: String,
      required: false,
      default: null,
    },
    iconLeft: {
      type: String,
      required: false,
      default: null,
    },
    iconColor: {
      type: String,
      required: false,
      default: "default",
    },
    classe: {
      type: [String],
      required: false,
      default: null,
    },
    rules: {
      type: [String],
      required: false,
      default: function () {
        return null;
      },
    },
    outlined: {
      type: Boolean,
      required: false,
      default: true,
    },
    dense: {
      type: Boolean,
      required: false,
      default: true,
    },
    solo: {
      type: Boolean,
      required: false,
      default: true,
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      required: false,
      default: false,
    },
    placeholder: {
      type: String,
      default: null,
    },
    route: {
      type: [String],
      required: false,
      default: null,
    },
    hint: {
      type: [String],
      required: false,
      default: null,
    },
    itemValue: {
      type: [String, Function],
      required: false,
      default: "intId",
    },
    itemText: {
      type: [String, Number, Function],
      required: false,
      default: "strNome",
    },
    itemColor: {
      type: [String, Number, Function],
      required: false,
      default: null,
    },
    customClass: {
      type: [String],
      required: false,
      default: "",
    },
    customStyle: {
      type: [String],
      required: false,
      default: "",
    },
    isObject: {
      type: [Boolean],
      required: false,
      default: false,
    },
  },
  data() {
    return {
      intUniqId: Date.now(),
      key: Date.now(),
      boolRequired: false,
      validateClass: "",
      labelHtml: "",
    };
  },
  methods: {
    generateKey() {
      this.key = this.$utilidade.base64Encode(JSON.stringify([Date.now()]));
    },

    clear() {
      if (this.value !== null && typeof this.value == "object") {
        this.$emit("input", []);
      } else {
        this.$emit("input", null);
      }
    },

    updateValue($event) {
      let type = typeof this.value;

      switch (type) {
        case "boolean":
          this.$emit("input", !this.value);
          this.$emit("click", !this.value);
          break;

        default:
          if ($event.target.value === undefined) {
            this.$emit("click", false);
          } else {
            this.$emit("input", $event.target.value);
            this.$emit("change", $event.target.value);
          }
          break;
      }
    },

    focus() {
      if (this.$refs.field) {
        this.$refs.field.focus();
      }
    },

    checkDisable(val) {
      if (
        val &&
        (val.bloqueado ||
          val.disabled ||
          val.ativo == 0 ||
          val.boolBloqueado == 0)
      )
        return true;

      if (this.itemDisabled != null) {
        return this.itemDisabled(val);
      }

      return false;
    },
    onSelected(ev) {
      let val = ev 

      if(!this.isObject && ev && ev[this.itemValue]){
        val = ev[this.itemValue]
      }
      
      //this.$emit("input", val);
      this.$emit("selected", ev);
    },

    getItemText(item) {
      let retorno = null;
      if (item && item[this.itemText]) {
        retorno = item[this.itemText];
      }

      switch (this.route) {
        case "Cadastro/EquipamentoFabricante":
          retorno = item.equipamento
            ? item.strNumeroCa + " - " + item.equipamento.strNome
            : "";
          break;

        default:
          retorno = (item.strCodigo ? item.strCodigo + " - " : "") + retorno;
          break;
      }

      return retorno ? retorno.toString() : "";
    },

    getLabelText(route, item) {
      let retorno = item[this.itemText];

      switch (route) {
        case "Cadastro/EquipamentoFabricante":
          retorno = item.equipamento ? item.equipamento.strNome : "";
          break;

        case "Common/Parametro":
          retorno =
            (item.strCodigo ? item.strCodigo + " - " : "") + item.strNome;
          break;

        default:
          retorno = (item.strCodigo ? item.strCodigo + " - " : "") + retorno;
          break;
      }

      return retorno;
    },

    getLabelDetail(route, item) {
      let retorno = "";

      switch (route) {
        case "Common/Cnae":
        case "Esocial/EsocialTabelaConteudo":
          retorno = item.strCodigo + " " + item.strNome;
          break;

        case "Cadastro/CadastroGeral":
          if(item.strCnpj){
            retorno = item.strCnpj;
          }
          break;

        case "System/Banco":
          let strImagem = "/img/bancos/" + item.strNumeroBanco + ".png";

          if (item.strImagem) {
            strImagem = item.strImagem;
          }
          retorno = '<img width="20" src="' + strImagem + '">  ' + item.strNumeroBanco;
          break;

        case "Cadastro/Funcionario":
          retorno = "CPF: " + item.strCpf;

          if (item.empresa) {
            retorno +=
              " | " + item.empresa.strNome + " " + item.empresa.strCnpj;
          }
          break;

        case "Documento/RevisaoSetor":
          retorno = "GHE: " + item.intOrdem;
          break;

        case "System/Epi":
          retorno =
            "CA: " +
            item.strNumeroCa +
            "<br>" +
            "FABRICANTE: " +
            item.strCnpjFabricante +
            " " +
            item.strNomeFabricante;
          break;

        case "Cadastro/EquipamentoFabricante":
          retorno =
            "CA: " +
            item.strNumeroCa +
            (!item.fabricante
              ? ""
              : "<br>FABRICANTE: " +
                this.$utilidade.textLimit(
                  item.fabricante.strCnpj + " - " + item.fabricante.strNome,
                  100
                ));
          break;

        case "Documento/Revisao":
          if (item.revisao) {
            retorno =
              item.strOrdem +
              " (" +
              item.revisao.strNome +
              " em " +
              this.$utilidade.toDate(item.strDataDocumento) +
              ")";
          }

          break;
        case "Cadastro/Funcao":
          retorno = "CBO: " + item.strCbo;
          break;

        case "Cadastro/Profissional":
          retorno = item.tipo_profissional.strNome;

          if (item.strDocumento) {
            retorno +=
              " | " +
              item.strDocumento +
              (item.strDocumentoUf ? "/" + item.strDocumentoUf : "");
          }
          break;

        case "Configuracoes/ContaBancaria":
          retorno = '<img src="'+item.strImagem+'" width="20"> '+item.strNomeTitular;

          break;

        default:
          break;
      }

      return retorno;
    },

    validate() {},
  },
  mounted() {},
  watch: {
    name() {},
    maxlength() {},
    backgroundColor() {},
    label() {},
    value() {},
    rules() {},
    multiple() {},
    clearable() {},
    required() {},
    loading() {},
    disabled() {},
    readonly() {},
    iconRight() {},
    iconLeft() {},
    iconClass() {
      this.generateKey();
    },
    route() {},
    customClass() {},
    customStyle() {},
  },
  computed: {
    showClearable() {

      if (this.value != null && this.value != undefined) {

        switch (typeof this.value) {
          case 'object':
            if (typeof this.value == "object" ) {
              return true;
            }
            break;

          case 'string':
          case 'number':
            if (this.value) {
              return true;
            }
            break;
        }
      }

      return false;
    },
    getError() {
      let response = this.$root.$request.objResponse;

      if (response && response.status == 400) {
        for (var field in response.options) {
          if (field == this.name) {
            return true;
          }
        }
      }

      return false;
    },

    getErrorContents() {
      let response = this.$root.$request.objResponse;

      if (response && response.status == 400) {
        for (var field in response.options) {
          if (field == this.name) {
            return response.options[field];
          }
        }
      }

      return [];
    },

    getRules() {
      let rules = [],
        arrayAux = [];

      if (this.rules) {
        arrayAux = this.rules.split("|").concat(arrayAux);

        arrayAux.forEach((rule) => {
          if (rule.indexOf("required") != -1) {
            rules.push((v) => !!v || v === 0 || "Campo obrigatório");

            this.boolRequired = true;
          }
        });

        //this.required = boolRequired

        arrayAux.forEach((rule) => {
          if (this.value) {
            if (rule.indexOf("array") != -1) {
              rules.push(
                (v) => (v && v.length > 0) || "Selecione pelo menos 1 item"
              );
            }

            if (rule.indexOf("date_disable_tomorrow") != -1) {
              rules.push(
                (v) =>
                  (v && !this.$utilidade.isTomorrow(v)) ||
                  "A data deve ser menor ou igual que hoje"
              );
            }

            if (rule.indexOf("email") != -1) {
              rules.push(
                (v) => (v && this.$utilidade.isEmail(v)) || "Email inválido"
              );
            }

            if (rule.indexOf("fileSize") != -1) {
              let info = rule.split(':')

              rules.push(
                (v) => (v && this.$utilidade.validateFileLength(rule,v)) || "O arquivo excedeu o limite de "+(Number(info[1]/1000))+'MB'
              );
            }

            if (rule.indexOf("stringSize") != -1) {
              let info = rule.split(':')

              rules.push(
                (v) => (v && this.$utilidade.validateStringLength(rule,v)) || "O campo excedeu o limite de "+info[1]+' carateres'
              );
            }

            if (rule.indexOf("senha") != -1) {
              rules.push(
                (v) =>
                  (v && this.$utilidade.checkPassword(v)) ||
                  "A senha deve conter 6 caracteres ou mais, sendo formado por letras e números."
              );
            }

            if (rule.indexOf("numeric") != -1) {
              rules.push(
                (v) =>
                  (v && this.$utilidade.isNumeric(v)) ||
                  "Digite um número válido"
              );
            }

            if (rule.indexOf("cpf") != -1) {
              rules.push(
                (v) => (v && this.$utilidade.isCpf(v)) || "Digite um CPF válido"
              );
            }

            if (rule.indexOf("cei") != -1) {
              rules.push(
                (v) => (v && this.$utilidade.isCei(v)) || "Digite um CEI válido"
              );
            }

            if (rule.indexOf("pis") != -1) {
              rules.push(
                (v) =>
                  (v && this.$utilidade.isPis(v)) || "Digite um NIT/PIS válido"
              );
            }

            if (rule.indexOf("date") != -1) {
              rules.push(
                (v) =>
                  (v && this.$utilidade.isDate(v)) ||
                  "Digite uma data válida no formato dd/mm/aaaa"
              );
            }

            if (rule.indexOf("cnpj") != -1) {
              rules.push(
                (v) =>
                  (v && this.$utilidade.isCnpj(v)) || "Digite um CNPJ válido"
              );
            }

            if (rule.indexOf("min:") != -1) {
              let min = parseInt(rule.split(":")[1]);

              rules.push(
                (v) =>
                  (v && v.length >= min) ||
                  "Campo deve conter ao menos " + min + " caracteres"
              );
            }

            if (rule.indexOf("max:") != -1) {
              let max = parseInt(rule.split(":")[1]);

              rules.push(
                (v) =>
                  (v && v.length <= max) ||
                  "Campo deve conter até " + max + " caracteres"
              );
            }
          }
        });
      }

      return rules;
    },
  },
};
</script>
