
const setEmpresaLogado = (context,objEmpresa) => {
	context.commit('SET_EMPRESA_LOGADO', objEmpresa);
}

export{
	setEmpresaLogado
}


