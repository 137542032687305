<template>
  <div>
    <div class="ed-form-label text-left mb-2" v-if="!hideDetails">
      <div class="row ma-0 pa-0">
        <div class="col pa-0">
          <span style="color: #c63535" v-if="boolRequired">*</span> {{ label }}
        </div>
        <div class="col pa-0" v-if="this.$slots.label">
          <slot name="label"></slot>
        </div>
      </div>
    </div>

    <v-textarea
      autocomplete="off"
      outlined
      dense
      solo
      :ref="name"
      :required="required"
      :name="name"
      :prepend-inner-icon="iconLeft"
      :append-icon="iconRight"
      :loading="loading"
      :rules="getRules"
      :error="getError"
      :error-messages="getErrorContents"
      :value="value"
      :disabled="disabled"
      :readonly="readonly"
      :color="color"
      :placeholder="placeholder"
      :rows="rows"
      :max="maxlength"
      :persistent-hint="info ? true : false"
      validate-on-blur
      @input="$emit('input', $event)"
      @keydown="$emit('keydown', $event)"
      @blur="$emit('blur', $event)"
      @keyup.enter="$emit('keyup', $event)"
      @change="$emit('change', $event)"
      @keydown.enter="$emit('enter', $event)"
      :auto-grow="resize"
      :no-resize="!resize"
    >
      <template v-slot:prepend v-if="!!$slots.prepend">
        <slot name="prepend" />
      </template>

      <template v-slot:append v-if="!!$slots.append || info">
        <i :class="$utilidade.getIcone('info')" :title="info" v-if="info" />
        <slot name="append" />
      </template>
    </v-textarea>
  </div>
</template>

<script>
import EdFormMixin from "./mixins/formControl";

export default {
  name: "EdInputTextArea",
  mixins: [EdFormMixin],
  props: {
    rows: {
      type: [Number, String],
      required: false,
      default: "3",
    },
    resize: {
      type: [Boolean],
      required: false,
      default: true,
    },
  },
  data() {
    return {};
  },
  beforeDestroy() {},
  created() {},
  mounted() {},
  computed: {},
  methods: {},
};
</script>
