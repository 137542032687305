<template>
  <div>
    <div class="ed-form-label text-left mb-2" v-if="!hideDetails">
      <div class="row ma-0 pa-0">
        <div class="col pa-0">
          <span style="color: #c63535" v-if="boolRequired">*</span> {{ label }}
        </div>
        <div class="col pa-0" v-if="this.$slots.label">
          <slot name="label"></slot>
        </div>
      </div>
    </div>
    <v-select
      label="Selecione"
      :outlined="outlined"
      :dense="dense"
      :solo="solo"
      :name="name"
      :value="value"
      :error="getError"
      :error-messages="getErrorContents"
      :loading="loading"
      :rules="getRules"
      :items="arrayParametro"
      :info="info"
      :readonly="readonly"
      :disabled="disabled"
      :return-object="isObject"
      :item-text="itemText"
      :multiple="multiple"
      :item-value="itemValue"
      @change="$emit('input', $event)"
      @input="onSelectedTipo($event)"
    >
      <template v-slot:selection="{ item, index }" v-if="multiple">
        <v-chip v-if="index === 0">
          <span>{{ item[itemText] }}</span>
        </v-chip>
        <span v-if="index === 1" class="grey--text text-caption">
          (+{{ value.length - 1 }})
        </span>
      </template>
    </v-select>
  </div>
</template>

<script>
import EdFormMixin from "./mixins/formControl";
import EdInputSelect from "./EdInputSelect";

export default {
  name: "EdInputTipo",
  mixins: [EdFormMixin],
  components: { EdInputSelect },
  props: {
    parametro: {
      type: [String, Number],
      required: true,
      default: null,
    },
    itemText: {
      type: [String, Number],
      required: false,
      default: "strNome",
    },
    itemValue: {
      type: [String, Number],
      required: false,
      default: "intId",
    },
    isObject: {
      ype: [Boolean],
      required: false,
      default: false,
    },
  },
  data() {
    return {
      arrayParametro: [],
    };
  },
  beforeDestroy() {
    this.arrayParametro = [];
  },
  created() {},
  mounted() {
    this.getParametro();
  },
  computed: {},
  methods: {
    onSelectedTipo(val) {
      let self = this;
      let objParametro = this.arrayParametro.find((c) => c[self.itemValue] == val);

      this.$emit("selected", objParametro);
    },

    getParametro() {
      if (!this.parametro) return;

      this.$root.$request
        .get("Common/Parametro", { strChave: this.parametro })
        .then((objResponse) => {

          if (objResponse.status == 200 && objResponse.result) {
            this.arrayParametro = objResponse.result.parametros;
          }
        });
    },
  },
  watch: {
    parametro() {
      this.getParametro();
    },
    value() {},
  },
};
</script>
