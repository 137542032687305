<template>
  <div>
    <div class="ed-form-label text-left mb-2" v-if="!hideDetails">
      <span style="color: #c63535" v-if="boolRequired">*</span>
      {{ label }}
    </div>

    <ed-modal v-model="dialog" width="80%">
      <div slot="title">
        <span v-if="value">
          {{ value.strNome }}
        </span>
      </div>

      <div class="" slot="content">
        <v-img :src="strImage" class="grey darken-4"></v-img>
      </div>
    </ed-modal>

    <v-card v-if="type == 'image'">
      <v-img
        :src="strImage ? strImage : '/img/user1.png'"
        class="ed-image"
        max-width="250"
        max-height="250"
        :title="value ? value.strNome : ''"
        @click="dialog = true"
      ></v-img>

      <v-card-text>
        <div class="col-12 ma-0 pa-0 text-center">
          <ed-button
            color="primary"
            :iconLeft="$utilidade.getIcone('editar')"
            title="Editar imagem"
            onlyIcon
            @click="handleFileImport"
            icon
          />

          <ed-button
            :iconLeft="$utilidade.getIcone('camera')"
            title="Capturar imagem da webcam"
            onlyIcon
            @click="boolAbrirCamera = !boolAbrirCamera"
            icon
          />

          <ed-button
            v-if="value"
            color="error"
            :iconLeft="$utilidade.getIcone('deletar')"
            title="Deletar imagem"
            onlyIcon
            @click="reset"
            icon
          />

          <ed-camera :openCamera="boolAbrirCamera" @image="onImagem" />
        </div>
        <div class="col-12 ma-0 pa-0 text-center" v-if="value">
          <span v-if="value.intTamanho">{{
            $utilidade.toMb(value.intTamanho)
          }}</span>
        </div>
      </v-card-text>
    </v-card>

    <v-file-input
      :style="type == 'image' ? 'display:none' : ''"
      :ref="name"
      :id="name"
      class="ma-0"
      show-size
      counter
      :outlined="outlined"
      :dense="dense"
      autocomplete="off"
      :required="required"
      :name="name"
      :prepend-inner-icon="iconLeft ? iconLeft : $utilidade.getIcone('upload')"
      :accept="accept"
      :append-icon="iconRight"
      :loading="loading"
      :rules="getRules"
      :error="getError"
      :error-messages="getErrorContents"
      :value="value"
      :disabled="disabled"
      :readonly="readonly"
      :persistent-hint="info || hint ? true : false"
      :hint="hint"
      validate-on-blur
      @change="initialize($event)"
      :color="color"
      :hide-details="hideDetails"
      :multiple="multiple"
    >
      <template v-slot:prepend v-if="!!$slots.prepend">
        <slot name="prepend" />
      </template>

      <template v-slot:append v-if="!!$slots.append || info">
        <i :class="$utilidade.getIcone('info')" :title="info" v-if="info" />
        <slot name="append" />
      </template>
    </v-file-input>
    <div class="row" v-if="value && value.strNome">
      <div class="col-12 text-right">
        <a :href="'data:'+value.strTipo+';base64,'+value.strConteudo" :download="value.strNome"
          >{{ value.strNome }} ({{ $utilidade.toMb(value.intTamanho) }})</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import EdFormMixin from "./mixins/formControl";
import EdButton from "./EdButton";
import EdModal from "./EdModal";
import EdCamera from "./EdCamera";

export default {
  name: "EdInputText",
  mixins: [EdFormMixin],
  props: {
    accept: {
      type: String,
      required: false,
      default: "",
    },
    url: {
      type: String,
      required: false,
      default: "",
    },
  },
  components: {
    EdButton,
    EdModal,
    EdCamera,
  },
  watch: {
    accept() {},
    value() {
      this.initialize();
    },
  },
  data() {
    return {
      model: null,
      dialog: false,
      boolAbrirCamera: false,
      strImage: "/img/user1.png",
    };
  },
  beforeDestroy() {},
  created() {},
  mounted() {
    this.initialize();
    this.onChangeUrl();
  },
  watch: {
    url() {
      this.onChangeUrl();
    },
    strImage() {},
  },
  computed: {
    getCustomRules() {
      let rules = this.getRules;
      return rules;
    },
  },
  methods: {
    onChangeUrl() {
      if (this.url) {
        this.strImage = this.url;
      }
    },

    onImagem(obj) {
      if (!obj) return;

      this.strImage = obj.image;

      this.$emit("input", obj);
    },

    handleFileImport() {
      document.getElementById(this.name).click();
    },

    reset() {
      this.$refs[this.name].reset();
      this.model = null;
      this.strImage = "/img/user1.png";
      this.$emit("input", null);
    },

    download() {

      console.log('this.value',this.value)
      const linkSource = "data:" + this.value.strTipo + ";base64" + this.value.strConteudo;
      const downloadLink = document.createElement("a");
      downloadLink.href = linkSource;
      downloadLink.download = this.value.strNome;
      downloadLink.click();
    },

    initialize(ev) {
      if (ev) {
        this.$utilidade.fileToBase64(ev).then((objFile) => {
          this.$emit("input", objFile);
          this.model = objFile;
          this.strImage =
            "data:" + objFile.strTipo + ";base64," + objFile.strConteudo;
        });
      } else {
        this.$emit("input", this.value);
      }
    },
  },
};
</script>
