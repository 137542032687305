import Vue from 'vue'

const SET_LAYOUT = (state,value) => {
	state.layout = value
}

const SET_LOADING_CONTENT = (state,value) => {
	state.loadingContent = value
}

export{
	SET_LAYOUT,
	SET_LOADING_CONTENT
}
