<template>
  <div>
    <div class="ed-form-label text-left mb-2" v-if="!hideDetails">
      <div class="row ma-0 pa-0">
        <div class="col pa-0">
          <span style="color: #c63535" v-if="boolRequired">*</span> {{ label }}
        </div>
        <div class="col pa-0" v-if="this.$slots.label">
          <slot name="label"></slot>
        </div>
      </div>
    </div>

    <v-text-field
      class="ma-0 pa-0"
      autocomplete="off"
      :outlined="outlined"
      :dense="dense"
      :solo="solo"
      :required="required"
      :name="name"
      :prepend-inner-icon="iconLeft"
      :append-icon="iconRight"
      :type="type"
      :label="hideDetails && label ? label : null"
      :loading="loading"
      :rules="getRules"
      :error="getError"
      :error-messages="getErrorContents"
      :value="value"
      :disabled="disabled"
      :readonly="readonly"
      placeholder="hh:mm"
      :persistent-hint="info ? true : false"
      :hint="hint"
      :hide-details="hideDetails"
      validate-on-blur
      @input="$emit('input', $event)"
      @keydown="$emit('keydown', $event)"
      @blur="validarHora"
      @keyup="$emit('keyup', $event)"
      @change="$emit('change', $event)"
      :color="color"
      v-mask="'99:99'"
    >
      <template v-slot:prepend v-if="!!$slots.prepend">
        <slot name="prepend" />
      </template>

      <template v-slot:append v-if="!!$slots.append || info">
        <i :class="$utilidade.getIcone('info')" :title="info" v-if="info" />
        <slot name="append" />
      </template>
      
    </v-text-field>
    <!-- <v-dialog
      ref="dialog"
      v-model="modal"
      :return-value.sync="modal"
      persistent
      width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="model"
          class="ma-0"
          :outlined="outlined"
          :dense="dense"
          autocomplete="off"
          :required="required"
          :name="name"
          :prepend-inner-icon="
            iconLeft ? iconLeft : $utilidade.getIcone('hora')
          "
          type="text"
          :append-icon="iconRight"
          :loading="loading"
          :rules="getCustomRules"
          :error="getError"
          :error-messages="getErrorContents"
          :value="value"
          :disabled="disabled"
          :persistent-hint="info || hint ? true : false"
          :hint="hint"
          validate-on-blur
          :color="color"
          :hide-details="hideDetails"
          readonly
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </template>
      <v-time-picker v-model="date" :range="multiple" scrollable>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="modal = false"> Fechar </v-btn>
        <v-btn text color="primary" @click="initialize(date)"> Salvar </v-btn>
      </v-time-picker>
    </v-dialog> -->
  </div>
</template>

<script>
import EdFormMixin from "./mixins/formControl";
import EdInoutText from './EdInputText.vue'

export default {
  name: "EdInputText",
  mixins: [EdFormMixin],
  component:{
    EdInoutText
  },
  props: {
    type: {
      type: String,
      required: false,
      default: "text",
    },
  },
  watch: {
    value() {
      this.initialize();
    },
  },
  data() {
    return {
      model: null,
      modal: false,
      date: null,
    };
  },
  beforeDestroy() {},
  created() {},
  mounted() {
    this.initialize();
  },
  computed: {
    getCustomRules() {
      let rules = this.getRules;
      // rules.push(
      //   (v) => (v && this.$utilidade.isDate(v)) || "Digite uma data válida"
      // );

      return rules;
    },
  },
  methods: {
    initialize(_date) {
      // let value = this.value;

      // if(_date){
      //   value = _date
      // }

      // this.modal = false;
      // this.model = value
      // this.date = value

      // this.$emit("input", value);
    },

    validarHora(){
      // if(this.value){
      //   let arrayHora = this.value.split(':')

      //   if(Number(arrayHora[0])>){

      //   }
      // }
      this.$emit('blur', this.value)
    }
  },
};
</script>
