<template>
  <div>
    <div class="ed-form-label text-left mb-2" v-if="!hideDetails && !inline">
      <span style="color: #c63535" v-if="boolRequired">*</span>
      {{ label }}
    </div>
    <v-radio-group v-model="model" @change="onChange" :rules="getRules" :error="getError" :error-messages="getErrorContents" :readonly="readonly">
      <template v-for="(item, index) in items">
        <v-radio :key="index" :disabled="disabled" :label="item[itemText]" :value="item[itemValue]"/>

        <div :key="'item-'+index" class="ma-1" v-if="!!$slots['item-' + item[itemValue]] && model == item[itemValue]">
          <slot :name="'item-' + item[itemValue]"></slot>
        </div>
          
      </template>
    </v-radio-group>
  </div>
</template>

<script>
import EdFormMixin from "./mixins/formControl";

export default {
  name: "EdInputSwitch",
  mixins: [EdFormMixin],
  components: {},
  props: {
    inline: {
      type: [Boolean],
      required: false,
      default: false,
    },
    items: {
      type: [Array],
      required: true,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      model: null,
    };
  },
  beforeDestroy() {},
  created() {},
  mounted() {
    this.initialize();
  },
  computed: {},
  methods: {
    initialize() {
      this.model = this.value;

      if(this.value){
          this.onChange(this.value)
      }
    },

    onChange(ev) {
      this.$emit("input", ev);
      this.$emit("change", ev);

      let self = this;
      let objTipo = this.items.find((c) => c[self.itemValue] == ev);

      this.$emit("selected", objTipo);
    },
  },
  watch: {
    value() {
      this.initialize();
    },
  },
};
</script>