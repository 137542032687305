<template>
  <div>
    <div class="ed-form-label text-left mb-2" v-if="!hideDetails">
      <div class="row ma-0 pa-0">
        <div class="col pa-0">
          <span style="color: #c63535" v-if="boolRequired">*</span> {{ label }}
        </div>
        <div class="col pa-0" v-if="this.$slots.label">
          <slot name="label"></slot>
        </div>
      </div>
    </div>
    <v-text-field
      :id="name"
      class="ma-0 pa-0"
      autocomplete="off"
      :outlined="outlined"
      v-mask="'money'"
      :dense="dense"
      :solo="solo"
      :required="required"
      :name="name"
      :prepend-inner-icon="showIcon ? $utilidade.getIcone('dinheiro') : null"
      :append-icon="iconRight"
      :type="type"
      :label="hideDetails && label ? label : null"
      :loading="loading"
      :rules="getRules"
      :error="getError"
      :error-messages="getErrorContents"
      :value="value"
      :disabled="disabled"
      :readonly="readonly"
      :placeholder="placeholder"
      :persistent-hint="info ? true : false"
      :hint="hint"
      :hide-details="hideDetails"
      validate-on-blur
      @blur="initialize($event)"
      @keyup="$emit('keyup', $event)"
      @change="$emit('change', $event)"
      :color="color"
    >
      <template v-slot:prepend v-if="!!$slots.prepend">
        <slot name="prepend" />
      </template>

      <template v-slot:append v-if="!!$slots.append || info">
        <i :class="$utilidade.getIcone('info')" :title="info" v-if="info" />
        <slot name="append" />
      </template>
    </v-text-field>
  </div>
</template>

<script>
import EdFormMixin from "./mixins/formControl";

export default {
  name: "EdInputText",
  mixins: [EdFormMixin],
  props: {
    type: {
      type: String,
      required: false,
      default: "text",
    },

    showIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  watch: {
    value() {
      this.initialize();
    },
  },
  data() {
    return {};
  },
  beforeDestroy() {},
  created() {},
  mounted() {
    this.initialize();
  },
  computed: {},
  methods: {
    initialize(ev) {
      if (ev && ev.target._value) {
        this.$emit("input", ev.target._value);
      }
      if (this.value && this.value.toString().indexOf(",") == -1) {
        let strValor = this.$utilidade.floatToMoney(this.value);
        this.$emit("input", strValor);
      }

      // let input = $("input[name='"+this.name+"']")
      // input.attr('disabled',false)
    },
  },
};
</script>
