<template>
  <div class="download" v-if="arquivo">
    <ed-button
      :iconLeft="$utilidade.getIcone('download')"
      :label="
        'Arquivo ' +
        arquivo.strNome +
        '(' +
        $utilidade.toMb(arquivo.intTamanho) +
        ')'
      "
      text
      @click="download"
    />
  </div>
</template>

<script>
import EdButton from "./EdButton.vue";

export default {
  name: "EdDownload",
  components: { EdButton },
  mixins: [],
  props: {
    arquivo: {
      type: [Object, File],
      default: null,
    },
  },

  data() {
    return {
      id: 0,
      loadingDelete: false,
    };
  },

  mounted() {
    this.inicialize();
  },

  watch: {
    arquivo() {
      this.inicialize();
    },
  },

  methods: {
    inicialize() {
      this.id = new Date().getTime().toString();
    },



    download(arquivo) {
      console.log('arquivo',this.arquivo)
      if (!this.arquivo.intId) {
        this.$utilidade.download(this.arquivo.strConteudo,this.arquivo.strNome,this.arquivo.strTipo)
      } else {
        var id =
          arquivo && arquivo.id
            ? arquivo.id
            : this.arquivo && this.arquivo.id
            ? this.arquivo.id
            : null;

        if (!id) alert("Arquivo inválido");

        this.$root.$request.open(
          "auth/arquivo/" + this.$utilidade.toMd5(id) + "/download"
        );
      }
    },

    displayFile(arquivo) {
      return this.getUrl(
        "arquivo/" + this.$utilidade.toMd5(arquivo.id) + "/display"
      );
    },

    deleteFile(arquivo) {
      return this.post("arquivo/" + this.toMd5(arquivo.id) + "/deletar");
    },
  },
};
</script>

<style lang="scss">
#fileselector .btn-default {
  color: #666 !important;
  padding: 8px;
  width: 100%;
}

#fileselector .btn-default:hover {
  color: #666 !important;
}
.arquivo-info {
  width: 100%;
  font-style: italic;
  font-size: 10pt;
}

.download,
.el-popover {
  width: auto !important;
}
</style>
