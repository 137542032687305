import Vue from 'vue'
import Vuex from 'vuex'

import response from './response'
import cliente from './cliente'
import usuario from './usuario'
import empresa from './empresa'
import menu from './menu'
import common from './common'
import funcionario from './funcionario'

Vue.use(Vuex)

export default function (/* { ssrContext } */) {
  const Store = new Vuex.Store({
    modules: {
      response,
      cliente,
      usuario,
      empresa,
      menu,
      common,
      funcionario
    }
  })

  return Store
}