<template>
  <font-awesome-icon :icon="strIcone" :key="strIcone+key"/>
</template>

<script>
import EdFormMixin from "./mixins/formControl";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
export default {
  name: "EdTabs",
  mixins: [EdFormMixin],
  components: {FontAwesomeIcon},
  props: {
    strIcone: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {};
  },
  beforeDestroy() {},
  created() {},
  mounted() {
    this.initialize()
  },
  computed: {},
  methods: {
    initialize() {
      this.generateKey();
      this.$forceUpdate()
    },
  },
  watch: {
    strIcone() {
      this.initialize()
    },
  },
};
</script>
