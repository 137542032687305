<template>
  <div class="row">
    <v-navigation-drawer
      class="text-center"
      v-model="drawer"
      right
      fixed
      floating
      style="z-index: 99"
    >
      <v-list-item>
        <v-list-item-avatar>
          <ed-button
            :iconLeft="$utilidade.getIcone('fechar')"
            color="default"
            title="Cancelar"
            icon
            onlyIcon
            @click="selected = []"
          />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="title"> Ações em Lote </v-list-item-title>
        </v-list-item-content>
        <template></template>
      </v-list-item>

      <v-divider></v-divider>

      <ed-button
        v-if="config && !config.boolOcultarDeletarLote"
        :iconLeft="$utilidade.getIcone('deletar')"
        color="error"
        title="Deletar"
        label="Deletar"
        @click="onDelete()"
      />

      <ed-button
        class="mt-2"
        v-if="!config.boolOcultarInativarLote"
        :iconLeft="$utilidade.getIcone('inativar')"
        color="warning"
        title="Inativar"
        label="Inativar"
        @click="onDelete(null, true)"
      />

      <ed-button
        class="mt-2"
        v-if="!config.boolOcultarRestaurarLote"
        :iconLeft="$utilidade.getIcone('restaurar')"
        color="success"
        title="Restaurar"
        label="Restaurar"
        @click="onRestaurar()"
      />

      <div class="text-center col-12" v-if="!!this.$slots['table-acoes']">
        <slot name="table-acoes"></slot>

        <ed-button
          class="mt-2"
          :iconLeft="$utilidade.getIcone('salvar')"
          color="success"
          title="Aplicar Acao"
          label="Salvar"
          @click="onAcaoLote()"
        />
      </div>
    </v-navigation-drawer>

    <div
      class="text-left col-xs-12 col-md-12 col-lg-8 col-xl-6 pt-0 mt-0 mb-2"
    >
      <template v-if="!disabledRegisterRows">
        <ed-button
          :label="getConfig.registerName"
          color="primary"
          class="mr-1"
          :iconLeft="getConfig.registerIcon"
          @click="$emit('register')"
        />

        <ed-button
          v-if="getConfig.boolExibirExportar && rows.length"
          label="Exportar para Excel"
          color="warning"
          class="mr-1"
          :iconLeft="$utilidade.getIcone('excel')"
          :key="'excel'"
          @click="onExport"
        />

        <slot name="table-buttons"></slot>
      </template>

      
    </div>
    <ed-input-select
      v-if="!disabledSearchRows"
      label="Registros por página"
      class="col-xs-12 col-sm-12 col-md-3 col-lg-2 col-xl-2 pt-0 mt-0"
      v-model="filter.intRegistroPagina"
      
      
      :items="arrayRegistrosPaginas"
      @input="onBusca"
    />
    <div
      :class="
        disabledRegisterRows
          ? 'col-xs-12 col-md-5 col-lg-5 col-xl-5 text-right pt-0 mt-0'
          : 'col-xs-12 col-md-12 col-lg-4 col-xl-4 text-right pt-0 mt-0'
      "
      v-if="!disabledSearchRows"
    >
      <ed-input-text
        :solo="false"
        v-model="filter.strBusca"
        
        @keyup.enter="onBusca"
        style="margin-top: 0 !important; padding-top: 0 !important"
        :label="getConfig.searchName"
      >
        <div slot="append">
          <ed-button
            :iconLeft="$utilidade.getIcone('buscar')"
            color="secondary"
            title="Clique ou pressione ENTER para realizar a busca"
            onlyIcon
            x-small
            fab
            text
            @click="onBusca"
          />
          <!-- <ed-button
            v-if="!disabledFilters"
            :iconLeft="$utilidade.getIcone('filtrar')"
            color="secondary"
            title="Exibir filtro de busca"
            onlyIcon
            x-small
            fab
            text
            @click="boolExibirFiltro = !boolExibirFiltro"
          /> -->
        </div>
      </ed-input-text>
    </div>

    <div class="text-left col-12" v-if="!!this.$slots['table-filter']">
      <ed-filter :filter="filter" :key="'table-filter-' + $root.$session.versao" @filter="$emit('filter', filter)">
        <template slot="table-filter">
          <slot name="table-filter" />
        </template>
        <template slot="table-filter-button">
          <slot name="table-filter-button" />
        </template>
      </ed-filter>
    </div>
    
    <div class="text-left col-12">
      <h5 v-if="title" style="color: #999999; font-weight: bold">
        {{ title }}
      </h5>

      <v-data-table
        v-if="!boolDisableTableData"
        :key="key"
        class="elevation-1 ed-table mb-4"
        item-key="intId"
        :headers="tableHeaders"
        :options.sync="options"
        :items="rows"
        :single-select="singleSelect"
        :loading="loading"
        disable-pagination
        hide-default-footer
        dense
        loading-text="Carregando..."
        :no-data-text="config.noDataFound"
        @click:row="onRow"
      >
        <!--ACOES-->
        <template v-slot:item.acoes="{ item }">
          <div class="text-center ed-table-acoes">
            <span v-if="item._item.strDataAtualizacao && !disabledInfoUser">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <span :title="item._item.strDataAtualizacao ? 'Última alteração em '+$utilidade.toDate(item._item.strDataAtualizacao)+(item._item.usuario ? ' por '+item._item.usuario.strLogin  :''): ''">
                    <i
                      :class="$utilidade.getIcone('usuario')"
                      v-bind="attrs"
                      v-on="on"
                    ></i>
                  </span>
                </template>
                <span>
                  <b>Última alteração</b><br />
                  
                  <label v-if="item._item.usuario" style="display: block">
                    <b>Atualizado por</b>
                    {{ item._item.usuario.strNome }} em
                    {{ $utilidade.toDate(item._item.strDataAtualizacao) }}
                  </label>
                </span>
              </v-tooltip>
            </span>

            <span
              v-if="
                item._item.filiais && $root.$session.usuario.filiais.length > 1
              "
            >
              <!-- <ed-button
                :iconLeft="$utilidade.getIcone('vinculo')"
                :title="formatarNomeFilial(item._item.filiais)"
                color="default"
                icon
                onlyIcon
                text
              /> -->
              <ed-badge
                class=""
                color="primary"
                small
                onlyIcon
                text
                :icon-left="$utilidade.getIcone('vinculo')"
                :badge="item._item.filiais.length"
                :title="formatarNomeFilial(item._item.filiais)"
              />
            </span>

            <template v-for="(button, indexButton) in item.buttons">
              <ed-button
                v-if="item.buttons && item.buttons"
                :key="indexButton"
                :iconLeft="button.icone"
                :color="button.color"
                :title="button.title"
                icon
                onlyIcon
                text
                @click="button.click(item._item)"
              />
            </template>
            <ed-button
              v-if="!disabledEditRows"
              :iconLeft="$utilidade.getIcone('editar') + ' fa-3x'"
              :disabled="item.disableEdit"
              color="primary"
              title="Editar Registro"
              icon
              onlyIcon
              @click="$emit('edit', item._item)"
            />

            <ed-button
              v-if="!disabledDeleteRows"
              :iconLeft="$utilidade.getIcone('deletar')"
              color="error"
              :disabled="item.disableDelete"
              title="Deletar"
              icon
              onlyIcon
              @click="onDelete(item)"
            />
          </div>
        </template>

        <template v-slot:header.allselect>
          <ed-input-checkbox
            class="mr-2"
            style="float: left; width: 10px"
            v-model="boolSelecionarTodos"
            @input="onSelecteAll"
            inline
          />
        </template>

        <template v-slot:item.allselect="{ item }">
          <ed-input-checkbox
            class="mr-2"
            style="float: left; width: auto"
            v-model="item.boolSelecionado"
            @input="onSelecteItem(item)"
            inline
          />
        </template>

        <template
          v-for="(header, indexHeader) in headers"
          v-slot:[`item.${header.value}`]="{ item }"
        >
          <span
            v-if="
              header.collapse &&
              item[header.value] &&
              item[header.value].length > header.collapse
            "
            :key="'collpase-' + indexHeader"
          >
            <ed-collapse
              :textLimit="Number(header.collapse)"
              :textValue="item[header.value]"
              :textLabel="header.collapseLabel"
            />
          </span>
          <span
            v-else
            :class="
              (item._item.boolAtivo == 0 || item._item.strDataExclusao
                ? 'ed-inativo'
                : '') + (item.class ? ' ' + item.class : '')
            "
            :style="item.style ? item.style : ''"
            :title="
              (item._item.boolAtivo == 0 || item._item.strDataExclusao
                ? 'Registro inativo ou excluído'
                : '') + (item.title ? item.title : '')
            "
            :key="indexHeader + 1"
            v-html="item[header.value]"
          ></span>

          <span
            class="ed-table-info"
            :key="'empresa-' + indexHeader"
            v-if="header.showUnidade && item._item.unidade"
          >
            <label> <b>UNIDADE:</b> {{ item._item.unidade.strNome }} </label>
          </span>

          <span
            class="ed-table-info"
            :key="'funcao-' + indexHeader"
            v-if="header.showFuncao && item._item.funcao"
          >
            <label> <b>FUNÇÃO:</b> {{ item._item.funcao.strNome }} </label>
          </span>

          <span
            class="ed-table-info"
            :key="'setor-' + indexHeader"
            v-if="header.showSetor && item._item.setor"
          >
            <label> <b>SETOR:</b> {{ item._item.setor.strNome }} </label>
          </span>

          <span
            class="ed-table-info"
            :key="'setor-' + indexHeader"
            v-if="header.showArquivo && item._item[header.value]"
          >
            <a href="#" @click="downloadArquivo(item._item[header.value])"
              >{{ item._item[header.value].strNome }} ({{
                $utilidade.toMb(item._item[header.value].intTamanho)
              }})</a
            >
          </span>
        </template>
      </v-data-table>

      <ed-pagination
        v-if="pagination && !loading"
        :pagination="pagination"
        @page="onBusca"
      />
    </div>
  </div>
</template>

<script>
import TableControl from "./mixins/tableControl.vue";

import {
  EdButton,
  EdBadge,
  EdInputText,
  EdInputSelect,
  EdPagination,
  EdInputCheckbox,
  EdAlert,
  EdCollapse,
  EdForm,
  EdFilter
} from "@/components/common/form";

export default {
  name: "EdTable",
  components: {
    EdButton,
    EdBadge,
    EdInputText,
    EdPagination,
    EdAlert,
    EdInputSelect,
    EdInputCheckbox,
    EdCollapse,
    EdForm,
    EdFilter
  },
  mixins: [TableControl],
  beforeDestroy() {},
  data() {
    return {};
  },
  created() {},
  mounted() {
    this.inicialize();
  },
  computed: {},
  methods: {
    onSelecteItem(item) {
      let intIndex = this.rows.findIndex((c) => c.intId == item.intId);

      if (item.boolSelecionado) {
        this.selected.push(item);
      } else if (intIndex != -1) {
        this.selected.splice(intIndex, 1);
      }

      this.generateKey();
    },

    downloadArquivo(arquivo) {
      this.$root.$request.open(
        "publico/arquivo/download/" +
          this.$utilidade.toMd5(arquivo.intId) +
          "/get"
      );
    },
  },
  watch: {},
};
</script>

<style>
.ed-table tr th {
  font-size: 13px !important;
  text-transform: uppercase;
  font-weight: bold;
}

.ed-table tr td {
  font-size: 13px !important;
}

.ed-table-acoes {
  text-align: center;
  width: 180px;
}

@media only screen and (max-width: 800px) {
  .ed-table-acoes {
    text-align: center;
    width: 100% !important;
  }
}
</style>
