
const setDrawer = (context,objDados) => {
	context.commit('SET_DRAWER', objDados);
}

const setMini = (context,objDados) => {
	context.commit('SET_MINI', objDados);
}

const setMiniWidth = (context,objDados) => {
	context.commit('SET_MINI_WIDTH', objDados);
}

const setActive = (context,value) => {
	context.commit('SET_ACTIVE', value);
}

export{
	setDrawer,
	setActive,
	setMini,
	setMiniWidth
}


