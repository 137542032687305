import Vue from 'vue'

const SET_USUARIO_LOGADO = (state,objUsuario) => {
	state.logado = objUsuario
}

const SET_USUARIO_NOTIFICATION= (state,notification) => {
	state.notification = notification
}

const SET_USUARIO_PREFERENCIA= (state,objPreferencia) => {
	if(state.logado){
		state.logado.objPreferencia = objPreferencia
	}
}

export{
	SET_USUARIO_LOGADO,
	SET_USUARIO_NOTIFICATION,
	SET_USUARIO_PREFERENCIA
}
