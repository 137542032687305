<template>
  <ed-modal v-model="isCameraOpen">
    <div slot="title">CAPTURAR IMAGEM</div>

    <div class="" slot="content">
      <div class="row mt-3" slot="footer">
        <div class="col-12 d-flex justify-content-center">
          <div v-show="isCameraOpen && isLoading" class="camera-loading">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </div>

          <div
            v-if="isCameraOpen"
            v-show="!isLoading"
            class="camera-box"
            :class="{ flash: isShotPhoto }"
          >
            <div class="camera-shutter" :class="{ flash: isShotPhoto }"></div>

            <video
              v-show="!isPhotoTaken"
              ref="camera"
              :width="450"
              :height="337.5"
              autoplay
            ></video>

            <canvas
              v-show="isPhotoTaken"
              id="photoTaken"
              ref="canvas"
              :width="450"
              :height="337.5"
            ></canvas>
          </div>
        </div>
      </div>

      <div class="row" slot="footer">
        <div class="col-12 d-flex justify-content-center">
          <div v-if="isCameraOpen && !isLoading" class="camera-shoot">
            <ed-button
              :iconLeft="$utilidade.getIcone('camera')"
              @click="takePhoto"
              label="Capturar Imagem"
            />
          </div>

          <!-- <div v-if="isPhotoTaken && isCameraOpen" class="camera-download">
            <a
              id="downloadPhoto"
              download="my-photo.jpg"
              class="button"
              role="button"
              @click="downloadImage"
            >
              Download
            </a>
          </div> -->
        </div>
      </div>
    </div>
  </ed-modal>
</template>

<script type="text/javascript">
import EdModal from "./EdModal.vue";
import EdButton from "./EdButton.vue";

export default {
  name: "EdRequest",
  components: {
    EdModal,
    EdButton,
  },
  props: {
    openCamera: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  mixins: [],
  data() {
    return {
      isCameraOpen: false,
      isPhotoTaken: false,
      isShotPhoto: false,
      isLoading: false,
      isModal: false,
      link: "#",
    };
  },

  mounted() {},
  beforeDestroy() {
  },
  computed: {},
  watch: {
    openCamera() {
      this.toggleCamera();
    },
    isCameraOpen() {
      if (!this.isCameraOpen) {
        this.closeCamera();
      }
    },
  },
  methods: {
    closeCamera() {
      this.isCameraOpen = false;
      this.isPhotoTaken = false;
      this.isShotPhoto = false;
      this.isModal = false;
      this.stopCameraStream();
    },

    toggleCamera() {
      if (this.isCameraOpen) {
        this.closeCamera()
      } else {
        this.isCameraOpen = true;
        this.isModal = true;
        this.createCameraElement();
      }
    },

    createCameraElement() {
      this.isLoading = true;

      const constraints = (window.constraints = {
        audio: false,
        video: true,
      });

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          this.isLoading = false;
          this.$refs.camera.srcObject = stream;
        })
        .catch((error) => {
          this.isLoading = false;
           this.$root.$message.warning("Seu navegador não é compatível com captura de imagem. Efetue um refresh na tela e tente novamente.");
        });
    },

    stopCameraStream() {
      let tracks = this.$refs.camera.srcObject.getTracks();

      tracks.forEach((track) => {
        track.stop();
      });
    },

    takePhoto() {
      if (!this.isPhotoTaken) {
        this.isShotPhoto = true;

        const FLASH_TIMEOUT = 100;

        setTimeout(() => {
          this.isShotPhoto = false;
        }, FLASH_TIMEOUT);
      }

      this.isPhotoTaken = !this.isPhotoTaken;

      const context = this.$refs.canvas.getContext("2d");
      context.drawImage(this.$refs.camera, 0, 0, 450, 337.5);
      this.downloadImage();
    },

    downloadImage() {
      let strTipo = 'image/png'
      let strNome = 'web_'+this.$utilidade.toMd5((new Date()).getTime()+this.$utilidade.randomString())+'.png';
      let canvas = document.getElementById("photoTaken").toDataURL(strTipo)
      let base64 = canvas.replace(
        /^data:image\/(png|jpg|jpeg);base64,/,
        ""
      );
      let intTamanho = parseInt((base64).replace(/=/g,"").length * 0.75);



      let self = this;
      this.$emit("image", { 
        strConteudo: base64,
        strTipo:strTipo, 
        strNome:strNome,
        intTamanho:intTamanho,
        image: canvas 
      });

      setTimeout(() => {
        self.isShotPhoto = true;
        self.isPhotoTaken = !self.isPhotoTaken;
      }, 100);
    },
  },
};
</script>
