import Vue from 'vue'
import VueRouter from 'vue-router'
import admin from './admin';
import cliente from './cliente';

Vue.use(VueRouter)

let arrayHostName = window.location.hostname.split('.');
let routes = null;


if(arrayHostName[0] == 'admin'){
  routes = admin;
}
else{
  routes = cliente;
}

const router = new VueRouter({
  history: true,
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
