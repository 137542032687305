var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:_vm.ref,staticClass:"ed-form",attrs:{"disabled":_vm.block || _vm.loading,"autocomplete":"off","lazy-validation":""}},[_c('div',{staticClass:"col-md-12 pa-2 justify-center text-center"},[_c('div',{staticClass:"row ed-form-row"},[_vm._t("default"),_c('div',{staticClass:"col-md-12 pa-0 ma-0 text-center d-flex align-center flex-column"},[(_vm.recaptcha)?_c('vue-recaptcha',{key:_vm.recaptchaId,staticStyle:{"width":"304px !imaportant"},attrs:{"sitekey":_vm.recaptchaKey,"loadRecaptchaScript":true},on:{"verify":_vm.onRecaptchaVerify,"error":_vm.onRecaptchaError,"expired":_vm.onRecaptchaError,"render":_vm.onRecaptchaRender}}):_vm._e()],1)],2)]),_c('div',{class:'row ma-0 mt-0 ed-form-footer ' +
      _vm.classFooter +
      (_vm.disableFixedSave || _vm.$root.$session.isMobile ? '' : ' ed-form-fixed '),style:('position:' +
      (_vm.disableFixedSave || _vm.$root.$session.isMobile
        ? ''
        : _vm.savePosition + ' !important'))},[_c('div',{staticClass:"col-12 pa-0 ma-0"},[_c('br'),_c('v-divider')],1),_c('div',{class:'col-12 ' +
        (_vm.$root.$session.isMobile || _vm.disableAlignRight
          ? 'text-center'
          : 'text-right')},[_vm._t("form-footer"),(_vm.handlerSave)?_c('ed-button',{attrs:{"color":_vm.colorButton,"label":_vm.labelButton,"disabled":_vm.block || !_vm.isValidRecaptcha,"iconLeft":_vm.iconButton,"title":"Salvar alterações","size":"100%"},on:{"click":_vm.validar}}):_vm._e()],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }