import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import pt from 'vuetify/lib/locale/pt'
import colors from 'vuetify/lib/util/colors'

require('font-awesome/css/font-awesome.css')
require('font-awesome-animation/css/font-awesome-animation.css')


import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    locales: { pt },
    current: 'pt',
  },
  icons: {
    iconfont: 'fa', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
  breakpoint: {
    thresholds: {
      xs: 340,
      sm: 540,
      md: 800,
      lg: 1280,
    },
    scrollBarWidth: 24,
  },
  theme: {
    // themes: {
    //   light: {
    //     primary: '#3777a2',
    //     secondary: '#b0bec5',
    //     accent: '#8c9eff',
    //     error: '#b71c1c',
    //   },
    // },
    
    themes: {
      light: {
        primary: '#3da3b4',
        secondary: '#424242',
        accent: colors.shades.black,

        // primary: '#1976D2',
        // secondary: '#424242',
        // accent: '#82B1FF',
         error: '#db7878',
        // info: '#2196F3',
        success: '#74cc97',
        //warning: '#e0be57',
        
      },
      dark: {
        primary: colors.blue,
      },
    },
  },
})