<template>
  <div>
    <label :class="'collapsible'+(bind.boolExibir && textValue && textValue.length>textLimit ? ' bold ' : '') " :id="'collapsible-' + ref" @click="bind.boolExibir = !bind.boolExibir">
      <ed-icone
        :strIcone=" (!bind.boolExibir? $utilidade.getIcone('mais') : $utilidade.getIcone('menos'))"
        :id="'collapsible-status-' + ref"
        v-if="textValue && textValue.length>textLimit"
      />
      {{ (textLabel ? textLabel : $utilidade.textLimit(textValue, textLimit)) }}
      </label
    >
    <div v-show="bind.boolExibir && textValue && textValue.length>textLimit">
      <p v-html="textValue"></p>
    </div>
  </div>
</template>

<script>
import EdFormMixin from "./mixins/formControl";
import EdIcone from "./EdIcone"

export default {
  name: "EdTabs",
  mixins: [EdFormMixin],
  components: {EdIcone},
  props: {
    textLimit: {
      type: Number,
      required: false,
      default: 10,
    },
    textLabel: {
      type: [Number, String],
      required: false,
      default: null,
    },
    textValue: {
      type: [Number, String],
      required: false,
      default: null,
    },
    isOpen: {
      type: [Boolean],
      required: false,
      default: false,
    },
  },
  watch:{
    isOpen(){
      this.initialize()
    }
  },
  data() {
    return {
      ref: "collapse-" + this.$utilidade.randomString(),
      bind: {
        boolExibir:false
      },
    };
  },
  beforeDestroy() {},
  created() {},
  mounted() {
    this.initialize()
  },
  computed: {},
  methods: {
    initialize() {
      this.bind.boolExibir = this.isOpen
    },
  },
};
</script>
