
const setEmpresaAtivo = (context,objEmpresa) => {
	context.commit('SET_EMPRESA_ATIVO', objEmpresa);
}

export{
	setEmpresaAtivo
}


