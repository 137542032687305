<template>
  <div class="row mb-3 mt-2">
    <div class="text-left col-12" v-if="!!this.$slots['table-filter']">
      <v-card class="mb-5" color="#eeeeee">
        <v-card-title
          class="ed-mouse-hand"
          @click="boolExibirFiltro = !boolExibirFiltro"
        >
          <div class="col-9 ma-0 pa-0 text-left">
            <span>
              <i :class="$utilidade.getIcone('filtrar')"></i> Filtros
            </span>
          </div>
          <div class="col-3 ma-0 pa-0 text-right">
            <i
              :class="
                boolExibirFiltro
                  ? $utilidade.getIcone('setaCima')
                  : $utilidade.getIcone('setaBaixo')
              "
            ></i>
          </div>
        </v-card-title>

        <v-card-text
          class="white text--primary text-left"
          v-if="boolExibirFiltro"
        >
          <div class="row">
            <ed-form
              :formData="filter"
              :handlerSave="onFilter"
              :labelButton="getConfig.filterName"
              :iconButton="$utilidade.getIcone(getConfig.filterIcon ? getConfig.filterIcon : 'filtrar')"
              classFooter="text-center"
              disableFixedSave
              disableAlignRight
            >
              <div class="row">
                <slot name="table-filter" />
              </div>

              <template slot="form-footer">
                <slot name="table-filter-button" />
              </template>
            </ed-form>
          </div>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import TableControl from "./mixins/tableControl.vue";

import { EdForm } from "@/components/common/form";

export default {
  name: "EdFilter",
  components: {
    EdForm,
  },
  mixins: [TableControl],
  beforeDestroy() {},
  data() {
    return {};
  },
  created() {},
  mounted() {
    if (!this.filter.intPage) {
      this.filter.intPage = 1;
      this.filter.intRegistroPagina = 20;
    }

    for (let strCampo in this.filter) {
      if (
        this.filter[strCampo] != undefined &&
        this.filter[strCampo] != null &&
        this.filter[strCampo] instanceof Array &&
        this.filter[strCampo].length
      ) {
        this.boolExibirFiltro = true;
      }
    }

    if (this.boolFiltroAberto) {
      this.boolExibirFiltro = true;
    }
  },
  computed: {},
  methods: {},
  watch: {},
};
</script>

<style>
.ed-table-filter-button button {
  margin: 5px;
}
</style>