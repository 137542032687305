
const setClienteLogado = (context,cliente) => {
	context.commit('SET_CLIENTE_LOGADO', cliente);
}

export{
	setClienteLogado
}


