<template>
  <div>
    <div class="ed-form-label text-left mb-2" v-if="!hideDetails">
      <div class="row ma-0 pa-0">
        <div class="col pa-0">
          <span style="color: #c63535" v-if="boolRequired">*</span> {{ label }}
        </div>
        <div class="col pa-0" v-if="this.$slots.label">
          <slot name="label"></slot>
        </div>
      </div>
    </div>

    <v-dialog
      ref="dialog"
      v-model="modal"
      :return-value.sync="modal"
      persistent
      width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="model"
          class="ma-0"
          :outlined="outlined"
          :dense="dense"
          autocomplete="off"
          :required="required"
          :name="name"
          :prepend-inner-icon="
            iconLeft ? iconLeft : $utilidade.getIcone('tags')
          "
          type="text"
          :append-icon="iconRight"
          :loading="loading"
          :rules="getCustomRules"
          :error="getError"
          :error-messages="getErrorContents"
          :value="value"
          :disabled="disabled"
          :persistent-hint="info || hint ? true : false"
          :hint="hint"
          validate-on-blur
         
          :hide-details="hideDetails"
          readonly
          v-bind="attrs"
          v-on="on"
        >
          <template v-slot:prepend-inner>
           <i  :style="'color:'+color" :class="$utilidade.getIcone('tags')"></i>
          </template>
        </v-text-field>
      </template>

      <v-card>
        <v-card-text class="pa-5 pb-0">
          <v-color-picker v-model="date" dot-size="25" mode="hexa">
          </v-color-picker>
        </v-card-text>
        <v-card-actions>
          <div class="col-12 text-center">
            <v-btn text color="primary" @click="modal = false" > Fechar </v-btn>
            <v-btn text color="primary" @click="initialize(date)" v-if="!readonly && !disabled">
              Salvar
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import EdFormMixin from "./mixins/formControl";

export default {
  name: "EdInputText",
  mixins: [EdFormMixin],
  props: {
    type: {
      type: String,
      required: false,
      default: "text",
    },
    getEvent: {
      type: Function,
      required: false,
      default: function () {
        return true;
      },
    },
    getEventColor: {
      type: Function,
      required: false,
      default: function () {
        return "";
      },
    },
    getAllowedDate: {
      type: Function,
      required: false,
      default: function () {
        return true;
      },
    },
  },
  watch: {
    value() {
      this.initialize();
    },
  },
  data() {
    return {
      model: null,
      modal: false,
      date: null,
    };
  },
  beforeDestroy() {},
  created() {},
  mounted() {
    this.initialize();
  },
  computed: {
    getCustomRules() {
      let rules = this.getRules;
      // rules.push(
      //   (v) => (v && this.$utilidade.isDate(v)) || "Digite uma data válida"
      // );

      return rules;
    },
  },
  methods: {
    initialize(_value) {

      let value = this.value;

      if (_value) {
        value = (_value.hex ? _value.hex : _value)
      }

      if (value == this.value) {
        this.modal = false;
      }

      this.model = value;
      this.date = value;

      if(this.value && this.value == value)return;
      this.$emit("input", value);
    },
  },
};
</script>
