<template>
  <div>
    <div class="ed-form-label text-left mb-2" v-if="!hideDetails">
      <div class="row ma-0 pa-0">
        <div class="col pa-0">
          <span style="color: #c63535" v-if="boolRequired">*</span> {{ label }}
        </div>
        <div
          class="col pa-0 text-right"
          style="float: right !important; text-align: right !important"
          v-if="this.$slots.label"
        >
          <slot name="label"></slot>
        </div>
      </div>
    </div>


    <v-autocomplete
      :ref="ref"
      :outlined="outlined"
      :dense="dense"
      :solo="solo"
      :value="value"
      :items="items"
      :loading="boolLoading"
      :search-input.sync="search"
      :item-value="itemValue"
      :item-text="getItemText"
      :error="getError"
      :error-messages="getErrorContents"
      :label="placeholder ? placeholder : 'Digite algo para pesquisar...'"
      :rules="getRules"
      :prepend-inner-icon="iconLeft ? iconLeft : $utilidade.getIcone('buscar')"
      :append-icon="iconRight"
      :disabled="disabled"
      :readonly="readonly"
      :return-object="!multiple"
      :multiple="multiple"
      :filter="customFilter"
      :hide-details="hideDetails"
      :hint="hint"
      @click:clear="clear"
      @change="onChange($event)"
      @input="onSelected($event)"
      @keydown="boolSearched = false"
      @focus="onFocus"
      loader-height="10"
      no-data-text="Nenhum item encontrado"
    >
      <template v-slot:selection="{ item, index }" v-if="multiple">
        <v-chip v-if="index === 0">
          <span>{{ item[itemText] }}</span>
        </v-chip>
        <span v-if="index === 1" class="grey--text text-caption">
          (+{{ value ? value.length - 1 : 0 }})
        </span>
      </template>


      <template v-slot:item="data" v-else>
        <span :class="'ed-table-info ' + data.item[itemText]">
          {{ getLabelText(route, data.item) }}
          <span
            style="display: block; color: #999999"
            v-if="getLabelDetail(route, data.item)"
            v-html="getLabelDetail(route, data.item)"
          ></span>
        </span>
      </template>


      <template v-slot:append v-if="!disabled && (!!$slots.append || info || clearable)">
        <i :class="$utilidade.getIcone('info')" :title="info" v-if="info" />
        <ed-button 
          :iconLeft="$utilidade.getIcone('clear')" 
          class="mt-0 pa-0"
          onlyIcon 
          color="#666666"
          small
          text
          v-if="showClearable" 
          @click="clear" 
        />
        <slot name="append"/>
      </template>

      <template v-slot:append-outer v-if="!!$slots.addonRight && !disabled">
        <slot name="addonRight"></slot>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import EdFormMixin from "./mixins/formControl";
import EdButton from './EdButton'

export default {
  name: "EdInputAutoComplete",
  mixins: [EdFormMixin],
  components: {EdButton},
  props: {
    layout: {
      type: [String],
      required: false,
      default: "cliente",
    },
    filters: {
      type: [Object],
      required: false,
      default: function () {
        return {};
      },
    },
    itemList: {
      type: Array,
      required: false,
      default: function () {
        return [];
      },
    },
    itemLabel: {
      type: String,
      default: "",
    },
    isObject: {
      type: [Boolean],
      required: false,
      default: false,
    },
    reload: {
      type: [Boolean, String, Number],
      required: false,
      default: false,
    },
    disabledSync: {
      type: [Boolean],
      required: false,
      default: false,
    },
    initializeItems: {
      type: [Boolean],
      required: false,
      default: false,
    },
  },
  data() {
    return {
      ref: "autocomplete-" + this.$utilidade.randomString(),
      search: null,
      timerId: null,
      boolLoading: false,
      boolSearched: false,
      boolSearching: false,
      arrayItems: [],
    };
  },
  beforeDestroy() {},
  created() {},
  mounted() {
    this.initialize();
  },
  computed: {
    items() {

      if(!this.arrayItems){
        this.arrayItems = []
      }

      return this.arrayItems.concat(this.itemList ? this.itemList : []);
    },
  },
  methods: {
    initialize() {
      if (this.checkItems()) return;
      this.getItems();
    },

    onFocus() {
      this.boolSearching = true;
      if (this.checkItems()) return;

      this.getItems();
    },

    onChange(ev) {
      if (this.multiple) {
        this.$emit("input", ev);
      } else {
        if (!ev) {
          this.$emit("input", null);
        } else {
          this.$emit("input", ev[this.itemValue]);
          this.$emit('selected',ev)
        }
      }

      this.$nextTick(() => {
        this.$refs[this.ref].blur();
      });
    },

    checkItems() {
      if (!this.initializeItems && !this.boolSearching) return true;

      let self = this;
      let item = this.items.find((c) => c[self.itemValue] == self.value);

      if (!item) return false;

      let boolEncontrado = this.customFilter(item, this.search);

      return boolEncontrado;
    },

    getItems(objBusca) {

      if (!this.route || this.disabled || this.checkItems() || this.disabledSync) return;

      let objFilter = Object.assign(this.filters, {
        intRegistroPagina: 200,
      });

      if (objBusca && objBusca.strBusca) {
        objFilter["strBusca"] = objBusca.strBusca;
      } else {
        //objFilter["strBusca"] = this.value;
      }

      this.arrayItems = [];
      this.boolLoading = true;

      this.$root.$request.get(this.route, objFilter).then((objResponse) => {
        if (objResponse.status == 200) {
          if (objResponse.result?.data) {
            this.arrayItems = objResponse.result.data;
          } else {
            this.arrayItems = objResponse.result;
          }
        }

        this.boolLoading = false;
        this.boolSearched = true;
      });
    },
    customFilter(item, queryText, itemText) {
      let boolRetorno = false;

      if (!this.itemList.length && !this.initializeItems && ['EquipamentoFabricante'].indexOf() != -1) {
        return true;
      }
      const searchText = this.$utilidade.removerAcentos(queryText).toLowerCase();

      const searchTextNumber = searchText
        ? this.$utilidade.onlyNumber(searchText).toString()
        : "";

      for (let strNomeCampo in item) {
        let strTexto = item[strNomeCampo];

        if (strTexto) {
          strTexto = strTexto.toString();

          const textOne = this.$utilidade.removerAcentos(strTexto).toLowerCase();

          const textOneNumber = textOne
            ? this.$utilidade.onlyNumber(textOne).toString()
            : "";
          if (
            !searchText ||
            textOne.indexOf(searchText) > -1 ||
            (textOneNumber &&
              searchTextNumber.length > 2 &&
              textOneNumber.indexOf(searchTextNumber))
          ) {
            boolRetorno = true;
          }
        }
      }

      return boolRetorno;
    },
  },
  watch: {
    route() {},
    disabled() {},
    rules() {},
    boolRequired() {},
    boolSearching() {},
    disabled() {
      this.getItems();
    },
    reload() {},
    filters(newVal, oldVal) {
      let boolFiltrar = false;

      for (let strCampo in newVal) {
        if (["intRegistroPagina", "strBusca"].indexOf(strCampo) == -1) {

          if (oldVal[strCampo] && (oldVal[strCampo] == undefined || oldVal[strCampo] != newVal[strCampo])) {
            boolFiltrar = true;
          }
        }
      }

      if (boolFiltrar) {
        this.boolSearching = true
        this.getItems();
      }
    },
    layout() {},
    value() {
      this.initialize();
    },
    arrayItems() {},
    itemList() {
      this.initialize();
    },
    search(currentVal, oldVal) {
      this.boolSearching = false;

      if (currentVal) {
        this.boolSearching = true;
      }

      if (this.boolSearched) return;

      clearTimeout(this.timerId);

      this.timerId = setTimeout(() => {
        this.getItems({ strBusca: currentVal });
      }, 500);
    },
  },
};
</script>
