import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import 'setimmediate';

Vue.config.productionTip = false

// Optionally install the BootstrapVue icon components plugin

//SERVICE
import './services'

//PLUGINS
import './plugins'

//DIRECTIVE
import './directive'

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
