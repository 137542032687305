<template>
  <div></div>
</template>

<script>
import Echo from "laravel-echo";

export default {
  name: "broadcast",
  components: {},
  mixins: [],
  data() {
    return {
      pusher: null,
    };
  },

  created() {},
  beforeDestroy() {
    //window.Echo = null;
  },

  mounted() {
    //this.inicialize();

    // console.log('Pusher',{
    //   key: process.env.VUE_APP_PUSHER_KEY,
    //   host: process.env.VUE_APP_URL_SOCKET,
    // })
  },

  watch: {},

  methods: {
    inicialize() {

      if (!window.Echo) {
        let jwt =  this.$root.$session.getJwt();

        window.Echo = new Echo({
          broadcaster: "socket.io",
          cluster: "mt1",
          key: process.env.VUE_APP_PUSHER_KEY,
          host: process.env.VUE_APP_URL_SOCKET,
          auth: {
            headers: {
              'Authorization': 'Bearer ' + jwt
            }
          }
        });
      }
    },


    subscribe(ch, ev,callback) {

      this.inicialize();
      var self = this;

      if(this.$root.$session.cliente){
        ch = this.$root.$session.cliente.strUrlDatabase+'.'+ch
      }

      this.pusher = window.Echo.channel(ch);

      // this.pusher.listen(
      //   '.'+ev,
      //   function (data) {

      //     if(callback){
      //       callback(data)
      //     }
      //   },
      //   this
      // );

      this.pusher = window.Echo.private(ch)
      .listen("."+ev, (e) => {
        if(callback){
          callback(e)
        }
      });

    },


    unsubscribe(ch) {

      this.inicialize();

      if(this.pusher){
        this.pusher.unsubscribe(ch);
      }
    },
  },
};
</script>