<template>
  <div>
    <v-overlay :value="boolLoading" style="z-index: 999999999 !important">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-dialog
      v-model="dialog"
      persistent
      scrollable
      :max-width="this.$vuetify.breakpoint.lgAndDown ? '100%' : '50%'"
      style="z-index: 9999999 !important"
    >
      <v-card style="z-index: 9999991 !important">
        <v-toolbar class="headline mb-3" :color="type" dark>
          <v-icon left color="default" v-if="true"
            >fa fa-question-circle</v-icon
          >
          {{ title }}
        </v-toolbar>
        <v-card-text style="max-height: 300px" v-html="message"></v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <ed-button
            color="primary darken-1"
            text
            @click="resolve"
            label="Fechar"
            v-if="isAlert"
          />
          <ed-button
            color="green darken-1"
            text
            @click="resolve"
            label="Sim"
            v-if="!isAlert"
          />
          <ed-button
            color="error darken-1"
            text
            @click="reject"
            label="Não"
            v-if="!isAlert"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { EdButton } from "@/components/common/form";

export default {
  name: "EdDialog",
  components: { EdButton },
  mixins: [],

  watch: {},
  data() {
    return {
      dialog: false,
      message: null,
      title: null,
      promise: null,
      isAlert: false,
      boolLoading: false,
      type: "success",
      promiseResolve: () => {},
      promiseReject: () => {},
    };
  },

  mounted() {},

  methods: {
    loading(boolLoading) {
      this.boolLoading = boolLoading;
    },

    newTab(params) {
      let routeData = this.$router.resolve(params);
      window.open(routeData.href, "_blank");
    },

    confirm(message = "", title = "Atenção", type = "primary") {
      this.message = message;
      this.title = title;
      this.type = type;
      this.isAlert = false;
      this.dialog = true;
      this.promise = new Promise((resolve, reject) => {
        this.promiseResolve = resolve;
        this.promiseReject = reject;
      });
      return this.promise;
    },

    success(message = "", title = "Atenção") {
      this.message = message;
      this.title = title;
      this.isAlert = true;
      this.dialog = true;
      this.type = "success";
      this.promise = new Promise((resolve, reject) => {
        this.promiseResolve = resolve;
        this.promiseReject = reject;
      });
      return this.promise;
    },

    error(message = "", title = "Atenção") {
      this.message = message;
      this.title = title;
      this.isAlert = true;
      this.dialog = true;
      this.type = "error";
      this.promise = new Promise((resolve, reject) => {
        this.promiseResolve = resolve;
        this.promiseReject = reject;
      });
      return this.promise;
    },

    warning(message = "", title = "Atenção") {
      this.message = message;
      this.title = title;
      this.isAlert = true;
      this.dialog = true;
      this.type = "warning";
      this.promise = new Promise((resolve, reject) => {
        this.promiseResolve = resolve;
        this.promiseReject = reject;
      });
      return this.promise;
    },

    resolve() {
      if (this.promiseResolve) this.promiseResolve();
      this.reset();
    },

    reject() {
      if (this.promiseReject) this.promiseReject();
      this.reset();
    },

    reset() {
      this.promise = null;
      this.promiseResolve = null;
      this.promiseReject = null;
      this.message = null;
      this.title = null;
      this.dialog = false;
      //this.$destroy();
    },
  },
};
</script>
