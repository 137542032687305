<template>
  <div>
    <div class="ed-form-label text-left mb-2" v-if="!hideDetails">
      <div class="row ma-0 pa-0">
        <div class="col pa-0">
          <span style="color: #c63535" v-if="boolRequired">*</span> {{ label }}
          <i :class="$utilidade.getIcone('info')" :title="info" v-if="info" />
        </div>
        <div
          class="col pa-0 text-right"
          style="float: right !important; text-align: right !important"
          v-if="this.$slots.label"
        >
          <slot name="label"></slot>
        </div>
      </div>
    </div>

    <ckeditor
      :id="id"
      :value="value"
      :config="editorConfig"
      :disabled="disabled"
      :read-only="readonly"
      tag-name="textarea"
      :editor-url="editorUrl"
      @input="$emit('input', $event)"
      @blur="$emit('blur', $event)"
    />
    <!-- <froala id="edit" :tag="'textarea'" :config="config" v-model="value" /> -->
  </div>
</template>

<script>
import EdFormMixin from "./mixins/formControl";
//import FroalaEditor from "froala-editor";

export default {
  name: "EdInputEditor",
  mixins: [EdFormMixin],
  components: {},
  props: {
    type: {
      type: String,
      required: false,
      default: "text",
    },
    extraPlugins: {
      type: [String, Array],
      required: false,
      default: "",
    },
  },
  data() {
    return {
      id: "ckeditor-" + this.$utilidade.randomString(),
      editorUrl:
        this.$root.$request.getUrl("", true) +
        "js/ckeditor/ckeditor.js?v=" +
        this.$root.$session.versao,
      editorConfig: {
        language: "pt-br",
        extraPlugins: this.extraPlugins,
        removePlugins: 'iframe,flash,find,forms,save,about'
      },
      /*
      config: {
        language: "pt_br",
        placeholderText: "Escreva seu texto aqui",
        disableRightClick: true,
        key: process.env.VUE_APP_FROALA_KEY,

        //IMAGE
        imageUploadParam: "froala_image",
        imageUploadURL: "",
        imageUploadParams: { id: "my_editor" },
        imageUploadMethod: "POST",
        imageMaxSize: 5 * 1024 * 1024,
        imageAllowedTypes: ["jpeg", "jpg", "png"],
        requestHeaders: {},
        imageStyles: {
          imageAddBorda: "Adicionar Borda",
        },
        //IMAGE MANAGER
        //imageManagerPreloader: "/images/loader.gif",
        imageManagerPageSize: 20,
        imageManagerScrollOffset: 10,
        imageManagerLoadURL: "",
        imageManagerLoadMethod: "GET",
        imageManagerLoadParams: { user_id: 4219762 },
        imageManagerDeleteURL: "",
        imageManagerDeleteMethod: "POST",
        imageManagerDeleteParams: { param: "value" },
        toolbarButtons: [
          "fullscreen",
          "bold",
          "italic",
          "underline",
          "strikeThrough",
          "subscript",
          "superscript",
          "|",
          "fontFamily",
          "fontSize",
  
          "color",
          "inlineStyle",
          "paragraphStyle",
          "|",
          "paragraphFormat",
          "align",
          "formatOL",
          "formatUL",
          "outdent",
          "indent",
          "quote",
          "-",
          "insertLink",
          //'insertImage',
          "embedly",
          //'insertVideo',
          //'insertFile',
          "insertTable",
          "|",
          "emoticons",
          "specialCharacters",
          "insertHR",
          "selectAll",
          "clearFormatting",
          "|",
          "print",
          "spellChecker",
          "help",
          "html",
          "|",
          "undo",
          "redo",
          "wordPaste",
          "edolmed",
        ],
        imageEditButtons: [
          "imageReplace",
          "imageAlign",
          "imageRemove",
          "|",
          "imageLink",
          "linkOpen",
          "linkEdit",
          "linkRemove",
          "-",
          "imageDisplay",
          "imageStyle",
          "imageAlt",
          "imageSize",
        ],

        //EVENTS
        events: {
          //IMAGE
          // 'froalaEditor.initialized': this.initialized,
          // 'froalaEditor.image.beforeUpload': this.imageBeforeUpload,
          // 'froalaEditor.image.uploaded': this.imageUploaded,
          // 'froalaEditor.image.inserted': this.imageInserted,
          // 'froalaEditor.image.replaced': this.imageReplaced,
          // 'froalaEditor.image.error': this.imageError,
          // 'froalaEditor.image.beforeRemove': this.imageDeleted,
          //IMAGE MANAGER
          // 'froalaEditor.imageManager.error': this.imageError,
          // 'froalaEditor.imageManager.imagesLoaded': this.imagesLoaded,
          // 'froalaEditor.imageManager.imageLoaded': this.imageLoaded,
          // 'froalaEditor.imageManager.beforeDeleteImage': this.beforeDeleteImage,
          // 'froalaEditor.imageManager.imageDeleted': this.imageDeleted,
        },
      },
      */
    };
  },
  beforeDestroy() {},
  created() {},
  mounted() {
    //this.inicialize();
  },
  computed: {},
  methods: {
    inicialize() {
      FroalaEditor.DefineIcon("edolmed", { NAME: "cog", SVG_KEY: "cogs" });
      FroalaEditor.RegisterCommand("edolmed", {
        title: "Parâmetros do Sistema",
        type: "dropdown",
        focus: false,
        undo: false,
        refreshAfterCallback: true,
        options: {
          v1: "Option 1",
          v2: "Option 2",
        },
        callback: function (cmd, val) {
          console.log(val);
        },
        // Callback on refresh.
        refresh: function ($btn) {
          console.log("do refresh");
          
        },
        // Callback on dropdown show.
        refreshOnShow: function ($btn, $dropdown) {
          console.log("do refresh when show");

          var editorInstance = this,list = $dropdown.find("ul.fr-dropdown-list")

            console.log('list',list)
        },
      });
    },
  },
};
</script>