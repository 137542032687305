<template>
  <div>
    <div class="ed-form-label text-left mb-2" v-if="!hideDetails && !inline">
      <span style="color: #c63535" v-if="boolRequired">*</span>
      {{ label }}
    </div>

    <v-checkbox
      v-if="!items.length"
      v-model="model"
      :key="key"
      :label="labelItem"
      :value="value"
      :disabled="disabled"
      @change="onChange"
    ></v-checkbox>

    <template v-if="items.length">
      <div class="row">
        <div
          style="width: auto; float: left"
          v-for="(objItem, index) in items"
          :key="index"
        >
          <v-checkbox
            v-model="model"
            :label="objItem.strNome"
            :value="objItem.intId"
            :disabled="disabled"
            @change="onChange"
          ></v-checkbox>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import EdFormMixin from "./mixins/formControl";

export default {
  name: "EdInputSwitch",
  mixins: [EdFormMixin],
  components: {},
  props: {
    inline: {
      type: [Boolean],
      required: false,
      default: false,
    },
    labelItem: {
      type: [String],
      required: false,
      default: null,
    },
    items: {
      type: [Array],
      required: false,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      model: false,
    };
  },
  beforeDestroy() {},
  created() {
    if (this.items.length) {
      this.model = [];
    }
  },
  mounted() {
    this.initialize();
  },
  computed: {},
  methods: {
    initialize() {
        if(this.value === null){
          this.model = false
        }
        else{
          this.model = this.value;
        }
    },

    onChange(ev) {
      
      this.$emit("input", ev);
      this.$emit("change", ev);
    },
  },
  watch: {
    value() {
      this.initialize();
      this.generateKey();
    },
  },
};
</script>