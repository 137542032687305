<template>
  <div></div>
</template>

<script type="text/javascript">
import Axios from "axios";

export default {
  name: "EdRequest",
  components: {},
  mixins: [],
  data() {
    return {
      form: {
        url: null,
        method: null,
        service: null,
        params: null,
        filters: null,
        headers: null,
      },
    };
  },

  mounted() {},

  computed: {
    objResponse: {
      cache: false,
      get() {
        return this.$store.state.response.objDados;
      },
      set(objResponse) {
        this.$store.dispatch("response/setResponse", objResponse);
      },
    },
  },
  methods: {
    get(_service, _params = {}) {
      this.form.service = _service + "/get";
      this.form.params = _params;
      this.form.filters = _params;
      this.form.method = "GET";

      return this.request();
    },

    post(_service, _params = {}, _filters = {}) {
      _params = Object.assign(_params, _filters);

      this.form.service = _service + "/post";
      this.form.params = _params;
      this.form.filters = _filters;
      this.form.method = "POST";

      return this.request();
    },

    put(_service, _params = {}, _filters = {}) {
      _params = Object.assign(_params, _filters);

      this.form.service = _service + "/put";
      this.form.params = _params;
      this.form.filters = _filters;
      this.form.method = "PUT";

      return this.request();
    },

    delete(_service, _params = {}, _filters = {}) {
      _params = Object.assign(_params, _filters);

      this.form.service = _service + "/delete";
      this.form.params = _params;
      this.form.filters = _filters;
      this.form.method = "DELETE";

      return this.request();
    },

    open(_service, _params = {}) {
      let strUrl = this.getUrl() + "/";

      _params["token"] = this.$root.$session.getJwt();

      if (_params) {
        // _params = new URLSearchParams(_params).toString();
        // _params = Object.entries(_params).map(([key, val]) => `${key}=${val}`).join('&');
        _params = !_params ? '' : $.param(_params);
      }

      strUrl += _service + "?" + _params;

      window.open(strUrl, "_blank");
    },

    url(_service, _params = {}) {
      let strUrl = this.getUrl() + "/";

      _params["token"] = this.$root.$session.getJwt();

      if (_params) {
        _params = new URLSearchParams(_params).toString();
      }

      strUrl += _service + "?" + _params;

      return strUrl;
    },

    getHeaders(onlyeJWT) {
      let jwt = this.$root.$session.getJwt();
      let version = this.$cookie.get("versao");

      var headers = {
        Authorization: jwt ? "Bearer " + jwt : null,
        Version: version ? version : "1.0.0",
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, OPTIONS, PUT, DELETE",
        "Access-Control-Allow-Headers":
          "Origin, X-Requested-With, Content-Type, Accept, X-File-Name, X-File-Size, X-File-Type",
      };

      return headers;
    },

    setHeaders: function () {
      this.form.headers = this.getHeaders();

      Axios.defaults.headers.common = this.form.headers;
    },

    setUrl(layout = "") {
      this.form.url = this.getUrl(layout, false);
    },

    getUrl(layout = "", boolBaseUrl = false) {
      if (!layout) {
        layout = this.$root.$session.layout;
      }

      let url =
        this.$utilidade.getLocation("protocol") +
        "//" +
        this.$utilidade.getLocation();

      if (!Number(process.env.VUE_APP_PRODUCTION) || true) {
        url = process.env.VUE_APP_CLIENTE_URL + "/";
      } else {
        url += "/core/";
      }

      if (!boolBaseUrl) {
        url += "api/";
        if (this.$root.$session.cliente) {
          url += this.$root.$session.cliente.strUrlDatabase;
        }
      }

      return url;
    },

    request() {
      if (
        this.$cookie.get(process.env.VUE_APP_CLIENTE_COOKIE_NAME) &&
        this.$root.$session.cliente &&
        this.$cookie.get(process.env.VUE_APP_CLIENTE_COOKIE_NAME) !=
          this.$root.$session.cliente.strUrlDatabase
      ) {
        this.$root.$session.logout();
        return;
      }

      this.setUrl();
      this.setHeaders();

      var response = {};

      this.$utilidade.console(
        "[REQUEST] " + this.form.url + this.form.service,
        this.form.params
      );

      if (
        this.$root.$session.cliente &&
        this.$root.$session.cliente.strUrlDatabase !=
          this.$cookie.get(process.env.VUE_APP_CLIENTE_COOKIE_NAME)
      ) {
        window.location.reload(true);
        return;
      }

      return new Promise((resolve, reject) => {
        response = { msg: "OK", status: 200, result: null, options: {} };

        let promisses = [];

        Axios({
          method: this.form.method == "GET" ? "POST" : this.form.method,
          url: this.form.service,
          data: this.form.params,
          baseURL: this.form.url,
          timeout: 260000,
          onUploadProgress: function (progressEvent) {
            // var uploadProgress = Math.floor(
            //   (progressEvent.loaded / progressEvent.total) * 100
            // );
            // if (uploadProgress == 100) {
            //   uploadProgress = 99;
            // }
            // self.requestParam.uploadProgress = uploadProgress;
          },
          onDownloadProgress: function (progressEvent) {},
        })
          .then((result) => {
            this.objResponse = null;

            response = result.data;
            promisses.push(response);

            return Promise.all(promisses).then(
              (success) => {
                if (
                  this.form.method &&
                  ["POST", "PUT"].indexOf(
                    this.form.method.toString().toUpperCase()
                  ) != -1 &&
                  this.$route.meta.boolRequiredAuthentication
                ) {
                  this.$root.$message.success("Dados cadastrados com sucesso.");
                }
                resolve(success[0]);
              },
              (err) => {}
            );
          })
          .catch((err) => {
            var error = Object.assign({}, err);
  
            this.$utilidade.console(
              "[REQUEST ERROR]",err 
            );

            if (
              error.response &&
              error.response.data &&
              error.response.data.status
            ) {
              response.result = error.response.data.result;
              response.options = error.response.data.options;
              response.status = error.response.data.status;
              response.msg = error.response.data.msg;
            } else if (error.response) {
              response.result = error.response.data;
              response.options = {};
              response.status = error.response.status;
              response.msg = error.response.statusText;
            } else {
              response.options = {};
              response.status = 500;
              response.msg = error.stack;
            }

            switch (response.status) {
              case 401:
                this.$root.$session.logout();
                return;
                break;

              default:
                if (this.form.method == "GET") {
                  this.$root.$message.error(response.msg);
                } else {
                  let strMsg = response.msg;

                  if (response.status == 400 && response.options) {
                    strMsg += "<br>";
                    for (let strCampo in response.options) {
                      strMsg +=
                        "<br>Campo <b>" +
                        this.$utilidade.limparCampoDatabase(strCampo) +
                        "</b>: " +
                        response.options[strCampo].join(", ");
                    }
                  }
                  this.$root.$dialog.error(strMsg);
                }
                break;
            }
            this.objResponse = response;

            promisses.push(response);

            return Promise.all(promisses).then(
              (success) => {
                resolve(success[0]);
              },
              (err) => {}
            );
          })
          .finally((err) => {
            this.form.url = null;

            if (
              this.$root.$session.versao &&
              response?.version &&
              response?.version != this.$root.$session.versao
            ) {
              window.location.reload(true);
            }

            if(response?.version){
              this.$root.$session.versao = response?.version;
            }

            // if (response?.authorization) {
            //   this.$cookie.delete(this.$root.$session.jwtName);
            //   this.$cookie.set(
            //     this.$root.$session.jwtName,
            //     response.authorization,
            //     {
            //       expires: "12h",
            //       secure: this.$root.$session.isProduction ? false : false,
            //     }
            //   );
            // }
          });
      });
    },
  },
};
</script>
