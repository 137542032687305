<template>
  <div></div>
</template>

<script>
export default {
  name: "EdTable",
  components: {},
  props: {
    headers: {
      type: Array,
      required: false,
      default: function () {
        return [];
      },
    },
    rows: {
      type: Array,
      required: false,
      default: function () {
        return [];
      },
    },
    pagination: {
      type: Object,
      required: false,
      default: function () {
        return null;
      },
    },
    filter: {
      type: Object,
      required: false,
      default: function () {
        return {
          strBusca: null,
          intPage: 1,
          intRegistroPagina: 20,
        };
      },
    },
    config: {
      type: Object,
      required: false,
      default: function () {
        return {};
      },
    },
    loading: {
      type: Boolean,
      required: false,
      default: function () {
        return false;
      },
    },
    strModel: {
      type: String,
      required: false,
      default: function () {
        return null;
      },
    },
    boolFiltroAberto: {
      type: [Boolean],
      required: false,
      default: false,
    },
    disabledSelectedRows: {
      type: Boolean,
      required: false,
      default: function () {
        return false;
      },
    },
    disabledAcoesRows: {
      type: Boolean,
      required: false,
      default: function () {
        return false;
      },
    },
    disabledEditRows: {
      type: Boolean,
      required: false,
      default: function () {
        return false;
      },
    },
    disabledDeleteRows: {
      type: Boolean,
      required: false,
      default: function () {
        return false;
      },
    },
    disabledRegisterRows: {
      type: Boolean,
      required: false,
      default: function () {
        return false;
      },
    },
    disabledSearchRows: {
      type: Boolean,
      required: false,
      default: function () {
        return false;
      },
    },
    disabledUpdatedRows: {
      type: Boolean,
      required: false,
      default: function () {
        return false;
      },
    },
    disabledInfoUser: {
      type: Boolean,
      required: false,
      default: function () {
        return false;
      },
    },
    boolDisableTableData: {
      type: Boolean,
      required: false,
      default: function () {
        return false;
      },
    },
    disabledFilters: {
      type: Boolean,
      required: false,
      default: function () {
        return true;
      },
    },
    title: {
      type: String,
      required: false,
      default: function () {
        return null;
      },
    },
  },
  beforeDestroy() {
    this.selected = [];
    this.tableHeaders = [];
  },
  data() {
    return {
      selected: [],
      singleSelect: false,
      key: new Date().getTime(),
      tableHeaders: [],
      options: null,
      drawer: false,
      boolExibirFiltro: true,
      boolSelecionarTodos: false,
      objCollapse: {},
      arrayRegistrosPaginas: [
        { intId: 20, strNome: "20" },
        { intId: 50, strNome: "50" },
        { intId: 100, strNome: "100" },
        { intId: 200, strNome: "200" },
        { intId: 300, strNome: "300" },
      ],
    };
  },
  created() {},
  mounted() {},
  computed: {
    getConfig() {
      if (this.config.intRegistroPagina) {
        this.filter.intRegistroPagina = this.config.intRegistroPagina;
      }

      return Object.assign(
        {
          registerName: "Novo Registro",
          registerIcon: this.$utilidade.getIcone("cadastrar"),
          searchName: "Pesquisa",
          filterName: "Filtrar Resultado",
          searchPlaceholder: "Digite aqui sua pesquisa",
          noDataFound: "Nenhum registro encontrado",
          boolOcultarDeletarLote: false,
          boolOcultarInativarLote: false,
          boolExibirExportar: false,
        },
        this.config
      );
    },

    getItemsSelecionados() {
      let arrayIds = [];

      this.selected.forEach((item) => {
        if (item.intId) {
          arrayIds.push(item.intId);
        }
      });

      return arrayIds;
    },
  },
  methods: {
    inicialize() {
      this.tableHeaders = [];

      this.tableHeaders.push({
        value: "allselect",
        sortable: false,
        text: "",
      });

      this.headers.forEach((header) => {
        if (!header.hidden) {
          this.tableHeaders.push(header);
        }
      });

      if (!this.disabledAcoesRows) {
        this.tableHeaders.push({
          value: "acoes",
          class: "ed-table-acoes",
          width: "180px",
          sortable: false,
          text: "",
        });
      }

      this.generateKey();
    },

    onBusca(intPage = 1) {
      this.filter.intPage = intPage;
      this.$emit("search", this.filter);
    },

    onFilter(intPage = 1) {
      this.filter.intPage = intPage;
      this.$emit("filter", this.filter);
    },

    generateKey() {
      this.key = new Date().getTime();
    },

    formatarNomeFilial(filiais) {
      let array = [];

      filiais.forEach((element) => {
        array.push(" - " + element.filial.strNome);
      });

      return (
        "ESSE CADASTRO PERTENCE AS SEGUINTES FILIAIS:\n\n" + array.join("\n")
      );
    },

    onSelecteAll() {
      let boolSelecionado = false;

      this.selected = [];
      if (this.boolSelecionarTodos) {
        boolSelecionado = this.boolSelecionarTodos;
      }

      this.rows.forEach((element) => {
        element.boolSelecionado = boolSelecionado;

        if (boolSelecionado) {
          this.selected.push(element);
        }
      });

      this.generateKey();
    },

    onDelete(item, boolInativar = false) {
      let arrayId = [];
      let strAcao = null;
      let strRepositorio = null;
      let strMensagem = boolInativar ? "inativados" : "excluídos";

      if (item && item._item && item._item.intId != undefined) {
        arrayId.push(item._item.intId);
      } else {
        arrayId = this.getItemsSelecionados;
      }

      if (this.strModel) {
        strRepositorio = this.strModel.split("/")[1];
        strAcao = boolInativar ? "inativar" : "deletar";
      }

      this.$root.$dialog
        .confirm(
          arrayId.length +
            " registro(s) serão " +
            strMensagem +
            ". Deseja prosseguir?"
        )
        .then((boolExcluir) => {
          if (!this.strModel) {
            return this.$emit("delete", arrayId);
          }

          this.$root.$dialog.loading(true);

          this.$root.$request
            .delete(this.strModel, {
              arrayId: arrayId,
              strAcao: strAcao,
              strRepositorio: strRepositorio,
              boolInativar: boolInativar,
            })
            .then((objResponse) => {
              this.$root.$dialog.loading(false);

              if (objResponse.status == 200) {
                this.$root.$message.success(
                  "Registros " + strMensagem + " com sucesso"
                );
                this.onBusca();
                this.drawer = false;
                this.selected = [];
                return this.$emit("delete", []);
              }
            });
        });
    },

    onExport() {
      let formFiltros = _.cloneDeep(this.filter);
      formFiltros.boolExportar = true;

      this.$root.$request.open(this.strModel + "/get", formFiltros);
    },

    onRestaurar(item) {
      let arrayId = [];
      let strMensagem = "restaurados";

      if (item && item._item && item._item.intId != undefined) {
        arrayId.push(item._item.intId);
      } else {
        arrayId = this.getItemsSelecionados;
      }

      this.$root.$dialog
        .confirm(
          arrayId.length +
            " registro(s) serão " +
            strMensagem +
            ". Deseja prosseguir?"
        )
        .then((boolExcluir) => {
          this.$root.$request
            .delete(this.strModel, {
              arrayId: arrayId,
              boolRestaurar: true,
            })
            .then((objResponse) => {
              if (objResponse.status == 200) {
                this.$root.$message.success(
                  "Registros " + strMensagem + " com sucesso"
                );
                this.onBusca();
                this.drawer = false;
                this.selected = [];
              }
            });
        });
    },

    onAcaoLote() {
      let arrayId = this.getItemsSelecionados;

      this.$root.$dialog
        .confirm(
          arrayId.length +
            " registro(s) serão atualizados" +
            ". Deseja prosseguir?"
        )
        .then((boolExcluir) => {
          this.$emit("acaoLote", arrayId);
          this.drawer = !this.drawer;
        });
    },

    onRow(item) {
      let collapseItem = document.getElementById(
        "collapsible-content-" + item.intId
      );
      let collapseParent = document.getElementById("collapsible-" + item.intId);
      let collapseStatus = document.getElementById(
        "collapsible-status-" + item.intId
      );
      if (collapseItem) {
        if (collapseItem.style.display === "block") {
          collapseItem.style.display = "none";
          collapseStatus.classList.remove("fa-minus-square-o");
          collapseStatus.classList.add("fa-plus-square-o");
          collapseParent.classList.remove("bold");
        } else {
          collapseItem.style.display = "block";
          collapseStatus.classList.remove("fa-plus-square-o");
          collapseStatus.classList.add("fa-minus-square-o");
          collapseParent.classList.add("bold");
        }
      }
    },
  },
  watch: {
    headers: {
      handler: function (newVal) {
        this.inicialize();
      },
      deep: true,
    },
    collapse() {},
    rows: {
      handler: function (newVal) {
        this.inicialize();
      },
      deep: true,
    },
    pagination: {
      handler: function (newVal) {},
      deep: true,
    },
    selected() {
      this.drawer = this.selected.length > 0;
    },
    options() {},
    loading() {},
    config() {},
    disabledSelectedRows() {},
    disabledAcoesRows() {},
    disabledEditRows() {},
    disabledDeleteRows() {},
    disabledSearchRows() {},
    disabledRegisterRows() {},
    boolFiltroAberto() {
      this.inicialize();
    },
  },
};
</script>
