import Vue from 'vue'
import moment from "moment";
import 'moment/locale/pt-br'
import 'moment-precise-range-plugin';
import Base64 from "base-64";
import md5 from 'js-md5';
var FileSaver = require("file-saver");

let VueUtilidade = {

  install(Vue) {
    Vue.prototype.$utilidade = this;
    Vue.utilidade = this;
  },

  getLocation(type) {
    var hostname = window.location;

    type = (type ? type : 'hostname');

    return hostname[type]
  },

  removerHtml(string) {

    if (!string) return string;

    string = string.replace(/(<([^>]+)>)/gi, "");

    string = string.replace(/[\u00A0-\u9999<>\&]/gim, function (i) {
      return '&#' + i.charCodeAt(0) + ';';
    });

    return string;
  },

  getIcone(icone) {
    let icones = {
      'relatorioAnalitico': 'fas fa-chart-bar',
      'pesquisar': 'fas fa-search',
      'anexo': 'fas fa-paperclip',
      'permissao': 'fas fa-user-check',
      'banco': 'fas fa-database',
      'pdf': 'fas fa-file-pdf',
      'pagamento': 'fas fa-dollar-sign',
      'integracao': 'fas fa-globe-americas',
      'clear': 'fas fa-times-circle',
      'microfone-on': 'fa fa-microphone',
      'ltcat': 'fa-solid fa-person-circle-exclamation',
      'pgr': 'fa-solid fa-triangle-exclamation',
      'chat': 'fa-solid fa-comments',
      'play': 'fa-solid fa-play',
      'stop': 'fa fa-stop-circle',
      'pause': 'fa fa-pause-circle-o',
      'compartilhar': 'fa fa-share-alt-square',
      'microfone-of': 'fa fa-microphone-slash',
      'fone': 'fa fa-phone',
      'video': 'fa-solid fa-video',
      'video-on': 'fa-solid fa-video',
      'video-of': 'fa-solid fa-video-slash',
      'gerenciar': 'fa-solid fa-folder-plus',
      'fabricante': 'fa fa-building-o',
      'lista_opcao': 'fa fa-list-ul',
      'hora': 'fa-solid fa-clock',
      'campoPersonalizado': 'fa fa-list-ol',
      'consulta': 'fa fa-user-md',
      'arquivo': 'fa fa-file',
      'cadastrar': 'fa-solid fa-circle-plus',
      'novoItem': 'fa fa-info-circle',
      'enquadramento': 'fa fa-address-card',
      'funcionario': 'fa-solid fa-users-line',
      'convenio': 'fa fa-ticket',
      'imprimir': 'fa fa-print',
      'dinheiro': 'fa fa-usd',
      'buscar': 'fa-solid fa-magnifying-glass',
      'filtrar': 'fa fa-filter',
      'editar': 'fa-solid fa-pen-to-square',
      'deletar': 'fa-regular fa-trash-can',
      'link': 'fa fa-external-link-square',
      'vinculo': 'fas fa-project-diagram',
      'xml': 'fa-solid fa-file-code',
      'link2': 'fa fa-external-link',
      'salvar': 'fa-regular fa-floppy-disk',
      'servico': 'fas fa-tasks',
      'fechar': 'fa fa-times',
      'informacao': 'fa fa-list',
      'texto': 'fa fa-file-text-o',
      'empresa': 'fa fa-building',
      'empresa': 'fa fa-building',
      'usuarios': 'fa fa fa-users',
      'checkbox': 'fa fa-check',
      'usuario': 'fa fa-user',
      'consulta': 'fa fa-user-md',
      'pcmso': 'fa fa-medkit',
      'esocial': 'fa-solid fa-file-code',
      'download': 'fa fa-cloud-download',
      'upload': 'fa fa-cloud-upload',
      'senha': 'fa fa-lock',
      'logs': 'fas fa-history',
      'enviar': 'fa fa-paper-plane',
      'login': 'fa fa-sign-in',
      'logout': 'fa fa-sign-out',
      'setaDireita': 'fa fa-angle-right',
      'setaBaixo': 'fa fa-angle-down',
      'bloqueio': 'fa fa-ban',
      'tags': 'fa fa-tags',
      'setaCima': 'fa fa-angle-up',
      'setaDuplaDireita': 'fa fa-angle-double-right',
      'setaDuplaEsquerda': 'fa fa-angle-double-left',
      'receita': 'fas fa-file-download',
      'despesa': 'fas fa-file-upload',
      'recuperarSenha': 'fa fa-share',
      'facebook': 'fa-brands fa-facebook',
      'twitter': 'fa-brands fa-square-twitter',
      'instagram': 'fa-brands fa-instagram',
      'youtube': 'fa-brands fa-youtube',
      'smile': 'fas fa-smile-beam',
      'email': 'fa fa-envelope',
      'emailAberto': 'fa fa-envelope-open-o',
      'cadastroGeral': 'fa-solid fa-id-card',
      'cadastroFiscal': 'fas fa-money-check-alt',
      'cobranca': 'fa-solid fa-circle-dollar-to-slot',
      'setores': 'fa-solid fa-building-user',
      'frenteTrabalho': 'fa-solid fa-building-shield',
      'menos': 'fas fa-minus',
      'mais': 'fas fa-plus',
      'funcao': 'fa fa-address-card',
      'risco': 'fa-solid fa-triangle-exclamation',
      'exame': 'fa-solid fa-stethoscope',
      'copiar': 'fa fa-files-o',
      'historico': 'fa fa-history',
      'notificacao': 'fa fa-bell',
      'calendario': 'fa-solid fa-calendar-days',
      'subItem': 'fa fa-list-ol',
      'classificacao': 'fa fa-list-ol',
      'info': 'fas fa-question-circle',
      'detalhes': 'far fa-file-alt',
      'frequencia': 'fa-solid fa-clock',
      'meioPropagacao': 'fa fa-exchange',
      'word': 'fa fa-file-word-o',
      'absenteismo': 'fas fa-user-times',
      'categoria': 'fa fa-window-restore',
      'matriz': 'fa fa-table',
      'excel': 'fas fa-file-csv',
      'probabilidade': 'fa fa-random',
      'template': 'fas fa-file-word',
      'conteudo': 'fa fa-columns',
      'configuracao': 'fa fa-cog',
      'inativar': 'fa fa-eye-slash',
      'restaurar': 'fa-solid fa-arrows-rotate',
      'enviarEmail': 'fa fa-paper-plane',
      'recorrent': 'fas fa-redo-alt',
      'link': 'fa fa-link',
      'cancelar': 'fa fa-times',
      'checkin': 'fa fa-sign-in',
      'camera': 'fa fa-camera',
      'interacao': 'fa fa-list-alt',
      'prontuario': 'fa-solid fa-address-book',
      'pendente': 'fa-solid fa-clock',
      'finalizado': 'fa fa-check',
      'excluido': 'fa fa-ban',
      'epi': 'fa fa-headphones',
      'unidade': 'fa fa-percent',
      'imagem': 'fa fa-file-image-o',
      'audiometria': 'fas fa-headset',
      'estatistica': 'fa fa-line-chart'
    }

    if (icones[icone]) {
      return icones[icone]
    }

    return null;
  },

  invokeSaveAsDialog(file, fileName) {
    if (!file) {
      throw 'Blob object is required.';
    }

    if (!file.type) {
      try {
        file.type = 'video/webm';
      } catch (e) {}
    }

    var fileExtension = (file.type || 'video/webm').split('/')[1];

    if (fileName && fileName.indexOf('.') !== -1) {
      var splitted = fileName.split('.');
      fileName = splitted[0];
      fileExtension = splitted[1];
    }

    var fileFullName = (fileName || (Math.round(Math.random() * 9999999999) + 888888888)) + '.' + fileExtension;

    if (typeof navigator.msSaveOrOpenBlob !== 'undefined') {
      return navigator.msSaveOrOpenBlob(file, fileFullName);
    } else if (typeof navigator.msSaveBlob !== 'undefined') {
      return navigator.msSaveBlob(file, fileFullName);
    }

    var hyperlink = document.createElement('a');
    hyperlink.href = URL.createObjectURL(file);
    hyperlink.download = fileFullName;

    hyperlink.style = 'display:none;opacity:0;color:transparent;';
    (document.body || document.documentElement).appendChild(hyperlink);

    if (typeof hyperlink.click === 'function') {
      hyperlink.click();
    } else {
      hyperlink.target = '_blank';
      hyperlink.dispatchEvent(new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true
      }));
    }

    URL.revokeObjectURL(hyperlink.href);
  },

  download(blobData, filename, type = 'data:text/plain;charset=utf-8') {
    //FileSaver.saveAs(blobData, filename);

    //var blobUrl = URL.createObjectURL(blobData);
    //window.location = blobUrl;


    console.log('blobData', {
      blobData: blobData,
      filename: filename,
      type: type
    })
    var element = document.createElement('a');
    element.setAttribute('href', type + ', ' + encodeURIComponent(blobData));
    element.setAttribute('download', filename);
    document.body.appendChild(element);
    element.click();
  },


  b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (
      var offset = 0; offset < byteCharacters.length; offset += sliceSize
    ) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, {
      type: contentType
    });

    return blob;
  },

  limparCampoDatabase(strCampo) {

    let arrayCampo = ['int', 'str', 'float']

    arrayCampo.forEach(strIdentificado => {

      if (strCampo && strCampo.toString().substring(0, strIdentificado.length) == strIdentificado) {
        strCampo = strCampo.toString().substring(strIdentificado.length, strCampo.length)
      }
    });

    return strCampo

  },

  parseBoolean(bool) {
    if (Number(bool)) {
      return 'Sim';
    }

    return 'Não'
  },

  randomMumber(min, max) {
    min = (!min ? 1 : min)
    max = (!max ? 10 : max)
    return Math.random() * (max - min) + min;
  },

  randomString() {
    let math = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    return this.toMd5(math + (new Date()).getTime())
  },

  arrFindByField(arr, field, value, index) {
    if (!field || !arr || !arr.length > 0) return null;

    var arr = arr.filter(x => x[field] == value);
    var arrIndex = arr.findIndex(x => x[field] == value);

    if (index) return (arrIndex);
    if (arr.length == 1) return arr[0];

    return null;
  },

  onlyNumber(value) {
    if (!value) return "";

    value = value.toString();

    return value.replace(/[\. ,:-]+/g, "-").replace(/[^0-9\.]+/g, "");
  },

  onlyText(value) {
    if (!value) return "";

    value = this.toString(value);
    return value.replace(/(<([^>]+)>)/gi, "");
  },

  checkPassword(value) {
    if (!value) return false;

    var regExpLetter = /[a-zA-Z]/g;
    var regExpNumber = /[0-9]/g;

    return regExpLetter.test(value) && regExpNumber.test(value) && value.toString().length >= 6
  },

  validateFileLength(rule, file) {
    let rules = rule.split(':')


    if (file && file.intTamanho && rules.length>1) {
      let floatTamanhoMb = file.intTamanho / 1000
      let floatTamanhoLimite = Number(rules[1])

      if (floatTamanhoMb > floatTamanhoLimite) {
        return false;
      }
    }
    return true
  },

  validateStringLength(rule, string) {
    let rules = rule.split(':')
    if (string && rules.length>1) {
      if (string.toString().length > floatTamanhoLimite) {
        return false;
      }
    }
    return true
  },

  isEmail(value) {

    if (!value) return false;

    let arrayEmail = value.split(';')
    let arrayEmailInvalido = []
    let boolRetorno = false;

    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    if (arrayEmail.length > 1) {

      boolRetorno = true

      arrayEmail.forEach(email => {
        if (!pattern.test(email)) {
          arrayEmailInvalido.push(email)
          boolRetorno = false
        }
      });
    } else {
      boolRetorno = pattern.test(value)
    }

    return boolRetorno
  },

  isDate(value) {
    if (!value) return false;

    let arrayValue = value.toString().split('/');

    if (arrayValue.length != 3) return false;

    let dia = arrayValue[0];
    let mes = arrayValue[1];
    let ano = arrayValue[2];
    let data = ano + '-' + mes + '-' + dia;

    if (parseInt(mes) > 12 || parseInt(mes) < 1 || parseInt(dia) < 1 || parseInt(dia) > 31 || parseInt(ano) < 1900) return false;

    return moment(data).isValid();
  },

  isCpf(cpf) {

    cpf = cpf.toString().replace(/[^\d]+/g, '');
    if (cpf == '') return false;
    // Elimina CPFs invalidos conhecidos	
    if (cpf.length != 11 ||
      cpf == "00000000000" ||
      cpf == "11111111111" ||
      cpf == "22222222222" ||
      cpf == "33333333333" ||
      cpf == "44444444444" ||
      cpf == "55555555555" ||
      cpf == "66666666666" ||
      cpf == "77777777777" ||
      cpf == "88888888888" ||
      cpf == "99999999999")
      return false;
    // Valida 1o digito	
    let add = 0;
    for (var i = 0; i < 9; i++)
      add += parseInt(cpf.charAt(i)) * (10 - i);
    let rev = 11 - (add % 11);
    if (rev == 10 || rev == 11)
      rev = 0;
    if (rev != parseInt(cpf.charAt(9)))
      return false;
    // Valida 2o digito	
    add = 0;
    for (i = 0; i < 10; i++)
      add += parseInt(cpf.charAt(i)) * (11 - i);
    rev = 11 - (add % 11);
    if (rev == 10 || rev == 11)
      rev = 0;
    if (rev != parseInt(cpf.charAt(10)))
      return false;
    return true;
  },

  isPis(pis) {
    pis = pis.replace(/[^\d]+/g, '');

    var multiplicadorBase = "3298765432";
    var total = 0;
    var resto = 0;
    var multiplicando = 0;
    var multiplicador = 0;
    var digito = 99;

    // Retira a mascara
    var numeroPIS = pis;

    if (numeroPIS.length !== 11 ||
      numeroPIS === "00000000000" ||
      numeroPIS === "11111111111" ||
      numeroPIS === "22222222222" ||
      numeroPIS === "33333333333" ||
      numeroPIS === "44444444444" ||
      numeroPIS === "55555555555" ||
      numeroPIS === "66666666666" ||
      numeroPIS === "77777777777" ||
      numeroPIS === "88888888888" ||
      numeroPIS === "99999999999") {
      return false;
    } else {
      for (var i = 0; i < 10; i++) {
        multiplicando = parseInt(numeroPIS.substring(i, i + 1));
        multiplicador = parseInt(multiplicadorBase.substring(i, i + 1));
        total += multiplicando * multiplicador;
      }

      resto = 11 - total % 11;
      resto = resto === 10 || resto === 11 ? 0 : resto;

      digito = parseInt("" + numeroPIS.charAt(10));
      return resto === digito;
    }
  },

  isCei(cei) {
    cei = cei.replace(/[^\d]+/g, '');

    if (cei == "") {
      return false;
    }

    if (cei.length != 12) {
      return false;
    }

    var peso = "74185216374";
    var soma = 0;

    //Faz um for para multiplicar os números do CEI digitado pelos números do peso.
    //E somar o total de cada número multiplicado.
    for (i = 1; i < 12; i++) {
      var fator = peso.substring(i - 1, i);
      var valor = cei.substring(i - 1, i);
      soma += (fator * valor);
    }
    //Pega o length do resultado da soma e desconta 2 para pegar somente a dezena.
    var len = soma.toString().length - 2;

    //pega a dezena
    var dezena = soma.toString().substring(len);

    //pega o algarismo da dezena
    var algdezena = dezena.toString().substring(0, 1);

    //pega o algarismo da unidade
    var unidade = parseInt(soma) - (parseInt((soma / 10)) * 10);

    //soma o algarismo da dezena com o algarismo da unidade.
    soma = parseInt(algdezena) + unidade;

    //pega o dígito (último número) do cei digitado.
    var digitoCEI = cei.substring(11);
    var digitoEncontrado = 10 - soma;

    if (digitoCEI != digitoEncontrado) {
      return false;
    } else {
      return true;
    }

  },

  mascara(val, mask) {

    if (!val) return val;

    val = this.onlyNumber(val);

    let num = this.onlyNumber(val);

    if (this.isCnpj(num)) {
      mask = 'cnpj';
    } else if (this.isCpf(num)) {
      mask = 'cpf';
    } else if (num.toString().length == 10) {
      mask = 'telefone';
    } else if (num.toString().length == 1) {
      mask = 'celular';
    }

    switch (mask) {

      case 'cnpj':
        mask = '##.###.###/####-##'
        break;

      case 'cpf':
        mask = '###.###.###-##'
        break;

      case 'telefone':
        mask = '(##) ####-####'
        break;

      case 'celular':
        mask = '(##) #####-#####'
        break;

      default:
        mask = ''
        break;
    }

    let maskared = '';
    let k = 0;

    for (let i = 0; i <= mask.length - 1; i++) {

      if (mask[i] == '#') {
        if ((val[k]))
          maskared += val[k++];
      } else {
        if ((mask[i]))
          maskared += mask[i];
      }
    }

    return maskared;

  },

  isCnpj(value) {

    if (!value) return false;

    let cnpj = this.onlyNumber(value);

    // Valida a quantidade de caracteres
    if (cnpj.length !== 14)
      return false

    // Elimina inválidos com todos os caracteres iguais
    if (/^(\d)\1+$/.test(cnpj))
      return false

    // Cáculo de validação
    let t = cnpj.length - 2,
      d = cnpj.substring(t),
      d1 = parseInt(d.charAt(0)),
      d2 = parseInt(d.charAt(1)),
      calc = x => {
        let n = cnpj.substring(0, x),
          y = x - 7,
          s = 0,
          r = 0

        for (let i = x; i >= 1; i--) {
          s += n.charAt(x - i) * y--;
          if (y < 2)
            y = 9
        }

        r = 11 - s % 11
        return r > 9 ? 0 : r
      }

    return calc(t) === d1 && calc(t + 1) === d2
  },

  isNumeric(value) {
    const pattern = /[0-9]/

    return value && pattern.test(value)
  },

  buildFormData(formData, data, parentKey) {

    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
      Object.keys(data).forEach(key => {
        this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
      });
    } else {
      const value = data == null ? '' : data;

      formData.append(parentKey, value);
    }
  },

  toFormUpload(data) {
    const formData = new FormData();

    this.buildFormData(formData, data);

    return formData;
  },

  booleanToInteger(form) {
    for (var key in form) {
      if (form[key] === true) form[key] = 1;

      if (form[key] === false) form[key] = 0;
    }

    return form;
  },

  base64Decode(value) {
    try {
      return Base64.decode(value);
    } catch (err) {
      return null;
    }
  },

  base64Encode(value) {
    try {
      return Base64.encode(value);
    } catch (err) {
      return null;
    }
  },

  fileToBase64(file) {

    var reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onerror = () => {
        reader.abort();
        reject(new Error("Error parsing file"));
      }
      reader.onload = function () {

        //This will result in an array that will be recognized by C#.NET WebApi as a byte[]
        let bytes = Array.from(new Uint8Array(this.result));

        //if you want the base64encoded file you would use the below line:
        let base64StringFile = btoa(bytes.map((item) => String.fromCharCode(item)).join(""));

        //Resolve the promise with your custom file structure
        resolve({
          intTamanho: file.size,
          strConteudo: base64StringFile,
          strNome: file.name,
          strTipo: file.type
        });
      }
      reader.readAsArrayBuffer(file);
    });

  },

  utf8Decode(t) {
    if (!t) return t;

    try {
      return decodeURIComponent(escape(t));
    } catch (err) {
      return t;
    }
  },

  diffDate(date1, date2, type = 'minute') {


    //date1 = new Date(date1)
    //date2 = new Date(date2)

    //console.log('date1 => '+date1)

    var a = moment(date1);
    var b = moment(date2);

    switch (type) {
      case 'minutes':
        return a.diff(b, 'minutes');
        break;

      case 'hours':
        return a.diff(b, 'hours');
        break;

      case 'days':
        return a.diff(b, 'days');
        break;


      case 'weeks':
        return a.diff(b, 'weeks');
        break;

      default:
        return a.diff(b, 'minutes');
        break;
    }
  },

  getInicioMes(format = 'DD/MM/YYYY', data = null) {

    if (!data) {
      data = moment()
    }
    return moment(data).startOf('month').format(format, 'pt_BR').toUpperCase();
  },

  
  getInicioMesAnterior(format = 'DD/MM/YYYY', data = null) {
    if (!data) {
      data = moment()
    }
    return  moment(data)
    .startOf('month')
    .subtract(1, 'month')
    .startOf('month') 
    .format(format, 'pt_BR') 
    .toUpperCase();
  },

  getFimMes(format = 'DD/MM/YYYY', data = null) {
    if (!data) {
      data = moment()
    }
    return moment(data).endOf('month').format(format, 'pt_BR').toUpperCase();
  },

  toDate(date, disableHours, format) {
    if (!date) {
      date = new Date();
    }

    if (!format) {
      format = "DD/MM/YYYY HH:mm:ss";

      if (disableHours) {
        format = "DD/MM/YYYY";
      }
    }

    let objDate = moment(date)
    return objDate.format(format, 'pt_BR').toUpperCase();
  },

  convertDate(date, format) {
    if (!date) {
      return new Date();
    }

    let arrayData1 = date.toString().split(' ')
    let arrayData2 = arrayData1[0].split('/')

    if (arrayData2.length == 3) {
      date = arrayData2[2] + '-' + arrayData2[1] + '-' + arrayData2[0]

      if (arrayData1.length == 2) {
        date += ' ' + arrayData1[0]
        date = date.trim()
      }
    }

    format = (format ? format : 'DD/MM/YYYY');

    return moment(date).format(format);
  },

  addDate(value, date, type = 'D', format) {

    if (!date) {
      date = new Date();
    } else {
      date = new Date(date);
    }

    switch (type) {
      case "y":
        date = moment(date).add(value, 'years').toDate();
        break;
      case "M":
        date = moment(date).add(value, 'months').toDate();
        break;
      case "D":
        date = moment(date).add(value, 'days').toDate();
        break;
      case "h":
        date = moment(date).add(value, 'hours').toDate();
        break;
      case "m":
        date = moment(date).add(value, 'minutes').toDate();
        break;
      case "s":
        date = moment(date).add(value, 'secounds').toDate();
        break;
      default:
        date = moment(date).add(value, 'days').toDate();
    }

    if (format) {
      return moment(date).format(format);
    }
    return date;
  },

  formataCpf(cpf) {
    //retira os caracteres indesejados...
    cpf = cpf.replace(/[^\d]/g, "");

    //realizar a formatação...
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  },

  isTomorrow(strDate) {
    let arrayDate = strDate.split('/')

    if (arrayDate.length == 3) {
      strDate = arrayDate[2] + '-' + arrayDate[1] + '-' + arrayDate[0]
    }

    let strDataAtual = this.toDate(null, true, 'YYYY-MM-DD');

    let intDifDay = this.diffDays(strDate, strDataAtual, 'd')

    if (intDifDay > 0) return true;

    return false

  },

  getAno() {
    let strDataAtual = new Date();

    return Number(strDataAtual.getFullYear())
  },

  getHora() {
    let strDataAtual = new Date();

    return Number(strDataAtual.getHours())
  },

  getSaudacao() {
    let intHora = this.getHora()

    if (intHora > 6 && intHora <= 12) {
      return 'Bom dia'
    } else if (intHora > 12 && intHora <= 18) {
      return 'Boa tarde'
    } else {
      return 'Boa noite'
    }
  },


  calcularIdade(strDataNascimento) {

    if (!strDataNascimento || this.onlyNumber(strDataNascimento).toString().length != 8) return null;

    let arrayDate = strDataNascimento.split('/')

    if (arrayDate.length == 3) {
      strDataNascimento = arrayDate[2] + '-' + arrayDate[1] + '-' + arrayDate[0]
    }

    let strDataAtual = this.toDate(null, true, 'YYYY-MM-DD');

    var diff = moment.preciseDiff(strDataAtual, strDataNascimento, true);

    if (isNaN(diff.years)) return null;

    let retorno = diff.years + ' anos'

    if (diff.months) {
      retorno += ' e ' + diff.months.toFixed(0) + (diff.months.toFixed(0) == 1 ? ' mês' : ' meses')
    }

    return retorno;

  },

  diffDays(date1, date2, type) {

    if (date1) {
      let arrayDate = date1.split('/')

      if (arrayDate.length == 3) {
        date1 = arrayDate[2] + '-' + arrayDate[1] + '-' + arrayDate[0]
      }
    }

    if (date2) {
      let arrayDate = date2.split('/')

      if (arrayDate.length == 3) {
        date2 = arrayDate[2] + '-' + arrayDate[1] + '-' + arrayDate[0]
      }
    }

    type = !type ? "d" : type;
    date1 = !date1 ? moment() : moment(date1);
    date2 = !date2 ? moment() : moment(date2);

    switch (type) {
      case "y":
        return date1.diff(date2, "years", true);
        break;
      case "d":
        return date1.diff(date2, "days", true);
        break;
      case "h":
        return date1.diff(date2, "hours", true);
        break;
      case "m":
        return date1.diff(date2, "minutes", true);
        break;
      case "s":
        return date1.diff(date2, "seconds", true);
        break;
      default:
        return date1.diff(date2, "days", true);
        break;
    }

  },

  moneyToFloat(value) {
    if (!value || value == "") {
      return 0;
    }

    if (value.toString().indexOf(',') == -1) {
      return parseFloat(value);
    }

    value = this.toString(value);

    return parseFloat(value.replace(".", "").replace(",", "."));
  },

  floatToMoney(value) {
    if (!value) {
      return "0,00";
    }

    if (value.toString().indexOf(',') != -1) {
      return value;
    }

    value = parseFloat(value)

    var formatter = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL"
    });

    value = formatter
      .format(value)
      .replace("R$", "")
      .trim();

    if (isNaN(value)) {
      //return '0,00';
    }

    return value;
  },

  formataCampo(text, left, format) {

    format = (!format ? '0' : format);
    left = (!left ? 0 : left);

    if (!text) return text;

    return text.toString().padStart(left, format);
  },

  toMb: function (bytes) {

    if (!bytes) return '0 MB';

    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
    if (bytes === 0) return 'n/a'
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)
    if (i === 0) return `${bytes} ${sizes[i]}`
    return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`

  },

  toString(value) {
    if (value == null || value == undefined) return '';

    return value.toString();
  },

  toMd5(text) {

    try {
      text = this.toString(text);
      text = md5(text);
      return text;
    } catch (e) {
      return text;
    }
  },

  validateEmail(email) {

    email = (email ? email.trim() : null);

    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return true
    }
    return false
  },

  textLimit(value, limit) {

    limit = (limit ? limit : 100);

    if (value && value.length > limit) {

      return value.substring(0, limit) + "...";
    }

    return value;
  },

  console(data, info = '') {
    if (process.env.VUE_APP_LOG == 1) {
      console.log('DEBUG :: ' + data, info)
    }
  },

  removerAcentos(source = '') {
    if (!source) {
      return ''
    }

    var accent = [
        /[\300-\306]/g, /[\340-\346]/g, // A, a
        /[\310-\313]/g, /[\350-\353]/g, // E, e
        /[\314-\317]/g, /[\354-\357]/g, // I, i
        /[\322-\330]/g, /[\362-\370]/g, // O, o
        /[\331-\334]/g, /[\371-\374]/g, // U, u
        /[\321]/g, /[\361]/g, // N, n
        /[\307]/g, /[\347]/g, // C, c
      ],
      noaccent = ['A', 'a', 'E', 'e', 'I', 'i', 'O', 'o', 'U', 'u', 'N', 'n', 'C', 'c']

    for (var i = 0; i < accent.length; i++) {
      source = source.replace(accent[i], noaccent[i])
    }

    return source
  },


  getEstado(search = null) {
    let arrayEstados = [{
        "uf": "AC",
        "estado": "Acre"
      },
      {
        "uf": "AL",
        "estado": "Alagoas"
      },
      {
        "uf": "AP",
        "estado": "Amapá"
      },
      {
        "uf": "AM",
        "estado": "Amazonas"
      },
      {
        "uf": "BA",
        "estado": "Bahia"
      },
      {
        "uf": "CE",
        "estado": "Ceará"
      },
      {
        "uf": "DF",
        "estado": "Distrito Federal"
      },
      {
        "uf": "ES",
        "estado": "Espírito Santo"
      },
      {
        "uf": "GO",
        "estado": "Goiás"
      },
      {
        "uf": "MA",
        "estado": "Maranhão"
      },
      {
        "uf": "MT",
        "estado": "Mato Grosso"
      },
      {
        "uf": "MS",
        "estado": "Mato Grosso do Sul"
      },
      {
        "uf": "MG",
        "estado": "Minas Gerais"
      },
      {
        "uf": "PA",
        "estado": "Pará"
      },
      {
        "uf": "PB",
        "estado": "Paraíba"
      },
      {
        "uf": "PR",
        "estado": "Paraná"
      },
      {
        "uf": "PE",
        "estado": "Pernambuco"
      },
      {
        "uf": "PI",
        "estado": "Piauí"
      },
      {
        "uf": "RJ",
        "estado": "Rio de Janeiro"
      },
      {
        "uf": "RN",
        "estado": "Rio Grande do Norte"
      },
      {
        "uf": "RS",
        "estado": "Rio Grande do Sul"
      },
      {
        "uf": "RO",
        "estado": "Rondônia"
      },
      {
        "uf": "RR",
        "estado": "Roraima"
      },
      {
        "uf": "SP",
        "estado": "São Paulo"
      },
      {
        "uf": "SC",
        "estado": "Santa Catarina"
      },
      {
        "uf": "SE",
        "estado": "Sergipe"
      },
      {
        "uf": "TO",
        "estado": "Tocantins"
      }
    ];


    return arrayEstados.filter(function (objEstado) {
      objEstado.estado = objEstado.estado.toUpperCase();

      return (
        !search || objEstado.uf.indexOf(search) !== -1
      )
    })

  },

  pluck(array, key) {
    return array.map(o => o[key]);
  }
};

Vue.use(VueUtilidade)

export default VueUtilidade