<template>
  <div>
    <div class="ed-form-label text-left mb-2" v-if="!hideDetails">
      <div class="row ma-0 pa-0">
        <div class="col pa-0">
          <span style="color: #c63535" v-if="boolRequired" >*</span> {{ label }}
        </div>
        <div class="col pa-0 text-right" v-if="this.$slots.label">
          <slot name="label"></slot>
        </div>
      </div>
    </div>

    <v-select
      :outlined="outlined"
      :dense="dense"
      :solo="solo"
      :items="arrayItems"
      :name="name"
      :required="required"
      :value="value"
      :error="getError"
      :error-messages="getErrorContents"
      :loading="loading"
      :rules="getRules"
      :item-value="itemValue"
      :item-text="getLabelText"
      :item-disabled="itemDisabled"
      :placeholder="placeholder"
      :return-object="isObject"
      :disabled="disabled"
      :readonly="readonly"
      :prepend-inner-icon="iconLeft"
      :append-icon="iconRight"
      :item-color="itemColor"
      :multiple="multiple"
      :persistent-hint="info ? true : false"
      :hide-details="hideDetails"
      :chips="multiple || chips"
      :color="color"
      :small-chips="chips || multiple"
      no-data-text="Nenhum item encontrado"
      :background-color="backgroundColor"
      @change="$emit('input', $event)"
      @input="onSelected($event)"
      attach
    >
      <template v-slot:selection="{ item, index }" v-if="multiple">
        <v-chip v-if="index === 0">
          <span>{{ item[itemText] }}</span>
        </v-chip>
        <span v-if="index === 1" class="grey--text text-caption">
          (+{{ value.length - 1 }})
        </span>
      </template>

      <template v-slot:item="data">
        <span :class="'ed-table-info ' + data.item[itemText]">
          {{ getLabelText(route, data.item) }}
          <span
            style="display: block; color: #999999"
            v-if="getLabelDetail(route, data.item)"
            >{{ getLabelDetail(route, data.item) }}</span
          >

          <span
            style="display: block; color: #999999"
            v-if="data.item.strInfo"
            v-html="data.item.strInfo"
          ></span>
        </span>
      </template>

      <template v-slot:append v-if="!disabled && (!!$slots.append || info || clearable)">
        <i :class="$utilidade.getIcone('info')" :title="info" v-if="info" />
        <ed-button 
            :iconLeft="$utilidade.getIcone('clear')" 
            class="mt-0 pa-0"
            onlyIcon 
            color="#666666"
            small
            text
            v-if="showClearable" 
            @click="clear" 
        />
        
        <slot name="append" />
      </template>
      <template v-slot:append-icon="{ item, index }" >
        <div slot="append-icon">asdfasfsa</div>
      </template>
    </v-select>
  </div>
</template>

<script>
import EdFormMixin from "./mixins/formControl";
import EdInputSwitch from "./EdInputSwitch.vue";
import EdButton from './EdButton'

export default {
  name: "EdInputSelect",
  mixins: [EdFormMixin],
  components: { EdInputSwitch,EdButton },
  props: {
    items: {
      type: [Array],
      required: false,
      default: function () {
        return [];
      },
    },
    itemDisabled: {
      type: [String, Array, Function],
      required: false,
      default: function () {
        return "boolBloqueado";
      },
    },
    chips: {
      type: [Boolean],
      required: false,
      default: false,
    },
  },
  data() {
    return {
      boolTodos: false,
    };
  },
  beforeDestroy() {},
  created() {},
  mounted() {
    this.onSelected();
  },
  computed: {
    arrayItems() {
      if (this.name == "strTipoPessoa") {
        return [
          { intId: "PJ", strNome: "Jurídica" },
          { intId: "PF", strNome: "Física" },
        ];
      }
      return this.items;
    },
  },
  methods: {
    onSelected(ev) {

      if (typeof ev !== "object") {
        let objItem = null 
        this.items.forEach(element => {
          if(element[this.itemValue] == ev){
            objItem = element
          }
        });
        this.$emit("selected", objItem);
      } else {
        this.$emit("selected", ev);
      }
    },

    checkAll() {
      let arrayItems = [];

      if (this.boolTodos) {
        this.items.forEach((element) => {
          arrayItems.push(element[this.itemValue]);
        });
      }

      this.$emit("input", arrayItems);
    },
  },
  watch: {
    items() {},
  },
};
</script>
