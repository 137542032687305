<template>
  <div>
    <div class="ed-form-label text-left mb-2" v-if="!hideDetails">
      <div class="row ma-0 pa-0">
        <div class="col pa-0">
          <span style="color: #c63535" v-if="boolRequired">*</span> {{ label }}
        </div>
        <div class="col pa-0" v-if="this.$slots.label">
          <slot name="label"></slot>
        </div>
      </div>
    </div>

    <v-dialog
      ref="dialog"
      v-model="modal"
      :return-value.sync="modal"
      persistent
      width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="model"
          class="ma-0"
          :outlined="outlined"
          :dense="dense"
          autocomplete="off"
          :required="required"
          :name="name"
          :prepend-inner-icon="
            iconLeft ? iconLeft : $utilidade.getIcone('calendario')
          "
          type="text"
          :append-icon="iconRight"
          :loading="loading"
          :rules="getCustomRules"
          :error="getError"
          :error-messages="getErrorContents"
          :value="value"
          :disabled="disabled"
          :persistent-hint="info || hint ? true : false"
          :hint="hint"
          validate-on-blur
          :color="color"
          :hide-details="hideDetails"
          @focus:clear="clear"
          readonly
          v-bind="attrs"
          v-on="on"
        >
          <template v-slot:append v-if="!disabled && (!!$slots.append || info || clearable)">
            <i :class="$utilidade.getIcone('info')" :title="info" v-if="info" />
            <ed-button 
              :iconLeft="$utilidade.getIcone('clear')" 
              class="mt-0 pa-0"
              onlyIcon 
              color="#666666"
              small
              text
              v-if="showClearable" 
              @click="clear" 
            />
            <slot name="append" />
          </template>
        </v-text-field>
      </template>
      <v-date-picker
        v-model="date"
        :range="multiple"
        scrollable
        :event-color="getEventColor"
        :events="getEvent"
        :allowed-dates="getAllowedDate"
        @change="initialize(date)"
      >
        <v-spacer></v-spacer>
        <div class="col-12 pa-0 ma-0 text-center">
          <v-btn text color="primary" @click="modal = false"> Fechar </v-btn>
          <!-- <v-btn text color="primary" @click="initialize(date)"> Salvar </v-btn> -->
        </div>
      </v-date-picker>
    </v-dialog>
  </div>
</template>

<script>
import EdFormMixin from "./mixins/formControl";
import EdButton from './EdButton'

export default {
  name: "EdInputText",
  mixins: [EdFormMixin],
  components:{EdButton},
  props: {
    type: {
      type: String,
      required: false,
      default: "text",
    },
    getEvent: {
      type: Function,
      required: false,
      default: function () {
        return true;
      },
    },
    getEventColor: {
      type: Function,
      required: false,
      default: function () {
        return "";
      },
    },
    getAllowedDate: {
      type: Function,
      required: false,
      default: function () {
        return true;
      },
    },
  },
  watch: {
    value() {
      this.initialize();
    },
  },
  data() {
    return {
      model: null,
      modal: false,
      date: null,
    };
  },
  beforeDestroy() {},
  created() {},
  mounted() {
    this.initialize();
  },
  computed: {
    getCustomRules() {
      let rules = this.getRules;
      // rules.push(
      //   (v) => (v && this.$utilidade.isDate(v)) || "Digite uma data válida"
      // );

      return rules;
    },
  },
  methods: {

    initialize(_date) {
      let value = this.value;

      if (_date) {
        value = [];
        let arrayDate = [];
        if (this.multiple) {
          arrayDate = _date;
        } else {
          arrayDate = [_date];
        }

        arrayDate.forEach((date) => {
          value.push(this.$utilidade.convertDate(date, "DD/MM/YYYY"));
        });
      } else if (typeof value == "string") {
        value = this.$utilidade.convertDate(value, "DD/MM/YYYY");
      }

      this.modal = false;

      if (value) {
        if (typeof value == "string") {
          this.model = value;

          if (!this.date) {
            this.date = this.$utilidade.convertDate(value, "YYYY-MM-DD");
          }
        } else {
          this.model = value.join(" - ");

          if (!this.date) {
            let arrayData = [];
            value.forEach((date) => {
              arrayData.push(this.$utilidade.convertDate(date, "YYYY-MM-DD"));
            });

            this.date = arrayData;
          }
          if (!this.multiple) {
            value = value[0];
          }
        }
      } else {
        this.model = null;
      }

      this.$emit("input", value);
    },
  },
};
</script>
