<template>
  <div>
<!-- 
    <el-dialog class="pa-0 ma-0" :close-on-press-escape="false" :close-on-click-modal="false" :visible.sync="value" :width="width" center >
      <v-card>
        <v-card-title
          dark
          color="primary"
          class="pr-0"
          :style="
            'background:' +
            this.$vuetify.theme.themes.light.primary +
            ' !important;;color: #ffffff;text-transform: uppercase !important;'
          "
        >
          <div class="col-10 pa-0 ma-0" slot="title">
            <v-icon v-if="iconTitle" class="mr-1" color="white">{{ iconTitle }}</v-icon>

            <slot name="title" />
          </div>
          <div class="col-2 pa-0 ma-0 text-right">
            <ed-button
              text
              onlyIcon
              color="white"
              @click="updateValue"
              :iconLeft="$utilidade.getIcone('fechar')"
            />
          </div>
        </v-card-title>
        <v-card-subtitle v-if="!!$slots.subtitle">
          <slot name="subtitle" />
        </v-card-subtitle>

        <v-card-text
          :style="
            $vuetify.breakpoint.smAndDown
              ? 'height: ' + this.$vuetify.breakpoint.height * 0.9 + 'px;'
              : ''
          "
        >
          <slot name="content" />
        </v-card-text>

        <div v-if="!!$slots.footer" slot="footer">
          <v-divider></v-divider>
          <v-card-actions class="justify-end">
            <slot name="footer" />
          </v-card-actions>
        </div>
      </v-card>
    </el-dialog> -->

    <v-dialog
      :persistent="persistent"
      :fullscreen="fullscreen"
      :scrollable="false"
      :value="value"
      :width="width"
      @input="updateValue"
      :max-width="maxWidth"
    >
      <v-card>
        <v-card-title
          dark
          color="primary"
          class="pr-0"
          :style="
            'background:' +
            this.$vuetify.theme.themes.light.primary +
            ' !important;;color: #ffffff;text-transform: uppercase !important;'
          "
        >
          <div class="col-10 pa-0 ma-0">
            <v-icon v-if="iconTitle" class="mr-1" color="white">{{ iconTitle }}</v-icon>

            <slot name="title" />
          </div>
          <div class="col-2 pa-0 ma-0 text-right">
            <ed-button
              text
              onlyIcon
              color="white"
              @click="updateValue"
              :iconLeft="$utilidade.getIcone('fechar')"
            />
          </div>
        </v-card-title>
        <v-card-subtitle v-if="!!$slots.subtitle">
          <slot name="subtitle" />
        </v-card-subtitle>

        <v-card-text
          :style="
            $vuetify.breakpoint.smAndDown
              ? 'height: ' + this.$vuetify.breakpoint.height * 0.9 + 'px;'
              : ''
          "
        >
          <slot name="content" />
        </v-card-text>

        <div v-if="!!$slots.footer">
          <v-divider></v-divider>
          <v-card-actions class="justify-end">
            <slot name="footer" />
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog> 
  </div>
</template>

<script>
import { EdButton } from "@/components/common/form";
import EdFormMixin from "./mixins/formControl";

export default {
  name: "EdModal",
  mixins: [EdFormMixin],
  components: { EdButton },
  props: {
    persistent: {
      type: Boolean,
      required: false,
      default: true,
    },
    fullscreen: {
      type: Boolean,
      required: false,
      default: function () {
        return this.$vuetify.breakpoint.mobile;
      },
    },
    scrollable: {
      type: Boolean,
      required: false,
      default: true,
    },
    iconTitle: {
      type: String,
      required: false,
      default: null,
    },
    modelValue: {
      required: false,
      default: null,
    },
    value: {
      required: false,
      default: null,
    },
  },
  data() {
    return {};
  },
  beforeDestroy() { },
  created() { },
  mounted() { 
  },
  computed: {},
  methods: {},
};
</script>

.modal-mask {
position: fixed;
z-index: 9998;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, .5);
display: table;
transition: opacity .3s ease;
}

.modal-wrapper {
display: table-cell;
vertical-align: middle;
}

.modal-container {
width: auto;
margin: 0px auto;
padding: 0px;
background-color: #fff;
border-radius: 2px;
box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
transition: all .3s ease;
font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
margin-top: 0;
color: #42b983;
}

.modal-body {
margin: 20px 0;
}

.modal-default-button {
float: right;
}

/*
* the following styles are auto-applied to elements with
* v-transition="modal" when their visiblity is toggled
* by Vue.js.
*
* You can easily play with the modal transition by editing
* these styles.
*/

.modal-enter, .modal-leave {
opacity: 0;
}

.modal-enter .modal-container,
.modal-leave .modal-container {
-webkit-transform: scale(1.1);
transform: scale(1.1);
}
</style>