<template>
  <div>
    <div class="ed-form-label text-left mb-2" v-if="!hideDetails">
      <div class="row ma-0 pa-0">
        <div class="col pa-0">
          <span style="color: #c63535" v-if="boolRequired">*</span> {{ label }}
        </div>
        <div class="col pa-0" v-if="this.$slots.label">
          <slot name="label"></slot>
        </div>
      </div>
    </div>

    <v-text-field
      class="ma-0 pa-0"
      autocomplete="off"
      :outlined="outlined"
      :dense="dense"
      :solo="solo"
      :required="required"
      :name="name"
      :prepend-inner-icon="iconLeft"
      :append-icon="iconRight"
      :type="type"
      :label="hideDetails && label ? label : null"
      :loading="loading"
      :rules="getRules"
      :error="getError"
      :error-messages="getErrorContents"
      :value="value"
      :disabled="disabled"
      :readonly="readonly"
      :placeholder="placeholder"
      :persistent-hint="info ? true : false"
      :hint="hint"
      :hide-details="hideDetails"
      :max="maxlength"
      validate-on-blur
      @input="$emit('input', $event)"
      @keydown="$emit('keydown', $event)"
      @blur="$emit('blur', $event)"
      @keyup="$emit('keyup', $event)"
      @change="$emit('change', $event)"
      @keydown.enter="$emit('enter', $event)"
      :color="color"
    >
      <template v-slot:prepend v-if="!!$slots.prepend">
        <slot name="prepend" />
      </template>

      <template v-slot:append v-if="!!$slots.append || info">
        <i :class="$utilidade.getIcone('info')" :title="info" v-if="info" />
        <slot name="append" />
      </template>
    </v-text-field>
  </div>
</template>

<script>
import EdFormMixin from "./mixins/formControl";

export default {
  name: "EdInputText",
  mixins: [EdFormMixin],
  props: {},
  data() {
    return {};
  },
  beforeDestroy() {},
  created() {},
  mounted() {},
  computed: {},
  methods: {},
};
</script>
