<template>
  <v-footer
    height="auto"
    fixedfir
    class="hidden-sm-and-down ma-0 pa-0"
    :class="this.$vuetify.theme.dark == true ? 'grey darken-4' : 'white'"
    light
    :style="
      $root.$session.isMobile || !boolRequiredAuthentication
        ? 'box-shadow:0px 0px 0px 0px rgba(0,0,0,.12), inset 0 -1px 0 0 #272727'
        : !$root.$session.mini
        ? 'margin-left:300px !important;box-shadow:0px 0px 0px 0px rgba(0,0,0,.12), inset 0 -1px 0 0 #272727'
        : 'left:' +
          $root.$session.miniWidth +
          'px !important;  box-shadow:0px 0px 0px 0px rgba(0,0,0,.12), inset 0 -1px 0 0 #272727'
    "
  >
    <!-- style="border-color:#e0e0e0;border-width: 1px;border-style: solid;border-bottom: 0;border-left:0;border-right:0" -->
    <v-container fluid class="pt-0 mt-0">
      <v-row
        align="center"
        justify="center"
        class="my-0 py-0 text-center"
        style="color: #3da3b4 !important"
        v-if="boolExibeLinksRedeSocial"
      >
        <v-col class="ed-social-links" cols="12" md="10" lg="10" sm="11" >
          <span v-if="$root.$session.cliente.strUrlFacebook || $root.$session.cliente.strUrlYoutube || $root.$session.cliente.strUrlTwitter || $root.$session.cliente.strUrlInstagram|| $root.$session.cliente.strUrlFacebook">
            Redes Sociais
            <v-divider></v-divider>
          </span>

          <v-btn
            v-if="$root.$session.cliente.strUrlFacebook"
            text
            icon
            color="primary"
            rel="noreferrer"
            :href="$root.$session.cliente.strUrlFacebook"
            target="_blank"
            slot="activator"
          >
            <i :class="$utilidade.getIcone('facebook') + ' fa-2x'"></i>
          </v-btn>

          <v-btn
            v-if="$root.$session.cliente.strUrlInstagram"
            text
            icon
            color="primary"
            rel="noreferrer"
            :href="$root.$session.cliente.strUrlInstagram"
            target="_blank"
            slot="activator"
          >
            <i :class="$utilidade.getIcone('instagram') + ' fa-2x'"></i>
          </v-btn>

          <v-btn
            v-if="$root.$session.cliente.strUrlTwitter"
            text
            icon
            color="primary"
            rel="noreferrer"
            :href="$root.$session.cliente.strUrlTwitter"
            target="_blank"
            slot="activator"
          >
            <i :class="$utilidade.getIcone('twitter') + ' fa-2x'"></i>
          </v-btn>

          <v-btn
            v-if="$root.$session.cliente.strUrlYoutube"
            text
            icon
            color="primary"
            rel="noreferrer"
            :href="$root.$session.cliente.strUrlYoutube"
            target="_blank"
            slot="activator"
          >
            <i :class="$utilidade.getIcone('youtube') + ' fa-2x'"></i>
          </v-btn>

          <v-spacer></v-spacer>
          <div
            class="col-12 pa-0 ma-0 text-center"
            v-if="$root.$session.cliente"
            style="position: absolute; bottom: 15px; left: 0; right: 0"
          >
            <span
              class="subtitle-2 google-font ed-logo-footer"
              style="font-size: 10pt !important; color: #3da3b4 !important"
            >
              <a
                :href="strUrlSite"
                target="_blank"
                style="text-decoration: none; color: #3da3b4"
                class="pa-0 ma-0"
              >
                <img src="/logo-name.png" style="max-width: 150px"
              /></a>
            </span>
            <span
              class="subtitle-2 google-font"
              style="font-size: 9pt !important; color: #3da3b4 !important; display: block"
            >
              ©{{ $utilidade.getAno() }} Copyright
            </span>
          </div>
          <div class="col-12 pt-5 pr-0 pr-2 text-right">
            <span
              class="subtitle-2 google-font text-white"
              style="font-size: 10pt !important; color: #3da3b4 !important"
            >
              v{{ $root.$session.versao }}
            </span>
          </div>
        </v-col>
      </v-row>

      <v-row
        v-if="false"
        align="center"
        justify="center"
        class="my-0 py-0 text-center text-white"
      >
        <v-col class="px-0 mx-0">
          <!-- <v-divider></v-divider> -->
          <v-btn
            v-for="(item, i) in config.footerConfig['Footer End Session Link']"
            :key="i"
            rel="noreferrer"
            :href="item.link"
            target="_blank"
            class="ml-0 google-font hidden-sm-and-down"
            style="text-transform: capitalize; font-size: 110%"
            text
            aria-label="Footer Bottom Button"
          >
            {{ item.linkname }}
          </v-btn>
          <v-spacer></v-spacer>
          <span class="subtitle-2 google-font text-white">
            Desenvolvido por
            <a
              :href="$root.$session.strUrlAdmin"
              target="_blank"
              rel="noreferrer"
              style="text-decoration: none; color: white"
            >
              <b>{{ $root.$session.strNomeAdmin }}</b></a
            ></span
          >
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  components: {},
  data: () => ({
    config: {
      generalConfig: {
        socialLinks: {},
      },
      keysandsecurity: {},
      footerConfig: {},
    },
    strUrlSite: process.env.VUE_URL_SITE,
  }),
  computed: {
    boolExibeLinksRedeSocial() {
      return (
        this.$root.$session.cliente &&
        (this.$root.$session.cliente.strUrlFacebook != "" ||
          this.$root.$session.cliente.strUrlInstagram != "" ||
          this.$root.$session.cliente.strUrlTwitter != "" ||
          this.$root.$session.cliente.strUrlYoutube != "")
      );
    },
    boolRequiredAuthentication() {
      return this.$route.meta?.boolRequiredAuthentication ? true : false;
    },
  },
};
</script>

<style scoped>
.darkThemeTextLink {
  color: #fafafa;
}
.lightThemeTextLink {
  color: #3e4551;
}
</style>
