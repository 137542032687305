<template>
  <div :class="$root.$session.isMobile ? 'row mt-4' : 'row ma-0 mt-4'">
    <div
      class="text-left col-xs-12 col-md-12 col-lg-7 col-xl-4 pt-0 mt-0 mb-2 pa-0"
      v-if="!disabledRegisterRows"
    >
      <ed-button
        :label="getConfig.registerName"
        color="primary"
        class="mr-1"
        :iconLeft="getConfig.registerIcon"
        :disabled="getConfig.boolDisableRegister"
        @click="$emit('register')"
      />
    </div>

    <ed-input-select
      v-if="!disabledSearchRows"
      label="Registros por página"
      class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 pt-0 mt-0 pa-0"
      v-model="filter.intRegistroPagina"
      hideDetails
      :solo="false"
      :items="arrayRegistrosPaginas"
      @input="onBusca"
    />

    <div
      :class="
        disabledRegisterRows
          ? 'col-xs-12 col-md-12 col-lg-12 col-xl-12 text-right pt-0 mt-0'
          : 'col-xs-12 col-md-12 col-lg-5 col-xl-5 text-right pt-0 mt-0'
      "
      v-if="!disabledSearchRows"
    >
      <ed-input-text
        :solo="false"
        v-model="filter.strBusca"
        hideDetails
        @keyup.enter="onBusca"
        style="margin-top: 0 !important; padding-top: 0 !important"
        :label="getConfig.searchName"
      >
        <div slot="append">
          <ed-button
            :iconLeft="$utilidade.getIcone('buscar')"
            color="secondary"
            title="Clique ou pressione ENTER para realizar a busca"
            onlyIcon
            x-small
            fab
            text
            @click="onBusca"
          />
          <ed-button
            :iconLeft="$utilidade.getIcone('filtrar')"
            color="secondary"
            title="Exibir filtro de busca"
            onlyIcon
            x-small
            fab
            text
            @click="onBusca"
          />
        </div>
      </ed-input-text>
    </div>

    <div class="col-12 pa-0" v-if="loading">
      <v-progress-linear indeterminate></v-progress-linear>
    </div>
    <div class="text-left col-12" v-if="!!this.$slots['table-filter']">
      <v-card class="mb-5" color="#eeeeee">
        <v-card-title
          class="ed-mouse-hand"
          @click="boolExibirFiltro = !boolExibirFiltro"
        >
          <div class="col-9 ma-0 pa-0 text-left">
            <span>
              <i :class="$utilidade.getIcone('filtrar')"></i> Filtros
            </span>
          </div>
          <div class="col-3 ma-0 pa-0 text-right">
            <i
              :class="
                boolExibirFiltro
                  ? $utilidade.getIcone('setaCima')
                  : $utilidade.getIcone('setaBaixo')
              "
            ></i>
          </div>
        </v-card-title>

        <v-card-text
          class="white text--primary text-left"
          v-if="boolExibirFiltro"
        >
          <div class="row">
            <ed-form
              :formData="filter"
              :handlerSave="onFilter"
              :labelButton="getConfig.filterName"
              :iconButton="$utilidade.getIcone('filtrar')"
              classFooter="text-center"
              disableFixedSave
              disableAlignRight
            >
              <div class="row">
                <slot name="table-filter" />
              </div>

              <template slot="form-footer">
                <slot name="table-filter-button" />
              </template>
            </ed-form>
          </div>
        </v-card-text>
      </v-card>
    </div>
    <v-simple-table dense class="ed-simple-table pa-0" loading>
      <template v-slot:default>
        <slot />
      </template>
    </v-simple-table>

    <ed-pagination
      v-if="pagination && !loading"
      :pagination="pagination"
      @page="onBusca"
    />
  </div>
</template>

<script>
import TableControl from "./mixins/tableControl.vue";
import {
  EdForm,
  EdButton,
  EdPagination,
  EdInputSelect,
  EdInputText,
} from "@/components/common/form";

export default {
  name: "EdSimpleTable",
  components: { EdForm,EdButton, EdInputSelect, EdInputText, EdPagination },
  mixins: [TableControl],
  beforeDestroy() {},
  data() {
    return {};
  },
  created() {},
  mounted() {
    this.inicialize()
  },
  computed: {},
  methods: {},
  watch: {},
};
</script>

<style></style>
