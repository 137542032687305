<template>
  <v-badge color="red" :content="badge.toString()" bordered overlap>
    <v-btn
      class="ed-button"
      @click="$emit('click', $event)"
      :key="key"
      :text="text"
      :title="title"
      :color="color"
      :to="to"
      :outlined="outlined"
      :loading="loading"
      :bottom="bottom"
      :right="right"
      :left="left"
      :top="top"
      :center="center"
      :dark="dark"
      :fab="fab"
      :fixed="fixed"
      :link="link"
      :small="small"
      :x-small="xSmall"
      :light="light"
      :large="large"
      :icon="icon"
      :disabled="disabled"
      :class="customClass"
      :style="customStyle"
      :x-large="xLarge"
      exact
    >
      <v-icon :left="!onlyIcon" v-if="iconLeft" :color="iconColor">{{
        iconLeft
      }}</v-icon>
      <i v-if="iconClass" :class="iconClass"></i>

      {{ label ? labelFormatado : null }}
      <v-icon class="right" :right="!onlyIcon" v-if="iconRight">{{
        iconRight
      }}</v-icon>
    </v-btn>
  </v-badge>
</template>

<script>
import EdFormMixin from "./mixins/formControl";
export default {
  name: "EdButton",
  mixins: [EdFormMixin],
  components: {},
  props: {
    text: {
      type: Boolean,
      required: false,
      default: false,
    },
    icon: {
      type: Boolean,
      required: false,
      default: false,
    },
    to: {
      type: [String, Object],
      required: false,
      default: null,
    },
    outlined: {
      type: Boolean,
      required: false,
      default: false,
    },
    link: {
      type: Boolean,
      required: false,
      default: false,
    },
    bottom: {
      type: Boolean,
      required: false,
      default: false,
    },
    top: {
      type: Boolean,
      required: false,
      default: false,
    },
    right: {
      type: Boolean,
      required: false,
      default: false,
    },
    left: {
      type: Boolean,
      required: false,
      default: false,
    },
    center: {
      type: Boolean,
      required: false,
      default: false,
    },
    dark: {
      type: Boolean,
      required: false,
      default: false,
    },
    onlyIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    fab: {
      type: Boolean,
      required: false,
      default: false,
    },
    fixed: {
      type: Boolean,
      required: false,
      default: false,
    },
    xSmall: {
      type: Boolean,
      required: false,
      default: false,
    },
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
    large: {
      type: Boolean,
      required: false,
      default: false,
    },
    xLarge: {
      type: Boolean,
      required: false,
      default: false,
    },
    light: {
      type: Boolean,
      required: false,
      default: false,
    },
    badge: {
      type: [String, Number, Boolean],
      required: false,
      default: null,
    },
  },
  data() {
    return {};
  },
  beforeDestroy() {},
  created() {},
  mounted() {},
  watch: {},
  computed: {
    labelFormatado() {
      if (!this.label) return "";

      //if(this.$vuetify.breakpoint.mdAndUp){
      return this.label;
      //}

      let arrayLabel = this.label.split(" ");

      return arrayLabel[0];
    },
  },
  methods: {},
};
</script>
