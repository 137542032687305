<template>
  <div></div>
</template>

<script>
import Cookie from "vue-cookie";
import JwtDecode from "jwt-decode";

export default {
  name: "EdSession",
  components: {},
  mixins: [],
  computed: {
    cliente: {
      cache: false,
      get() {
        return this.$store.state.cliente.logado;
      },
      set(value) {
        this.$cookie.set(
          process.env.VUE_APP_CLIENTE_COOKIE_NAME,
          value.strUrlDatabase,
          { expires: "1M" }
        );
        this.$store.dispatch("cliente/setClienteLogado", value);
      },
    },
    versao: {
      cache: false,
      get() {
        return this.$cookie.get("versao");
      },
      set(value) {
        this.$cookie.set("versao", value, {
          expires: 24 * 30 + "h",
        });
      },
    },
    menu: {
      cache: false,
      get(strFiltro) {
        return this.$store.state.usuario.logado.menu;
      },
    },
    urlSistema: {
      cache: false,
      get(strFiltro) {
        return process.env.VUE_APP_URL+''+this.$root.$session.cliente.strUrlDatabase
      },
    },
    usuario: {
      cache: false,
      get() {
        return this.$store.state.usuario.logado;
      },
      set(value) {
        this.$store.dispatch("usuario/setUsuarioLogado", value);
      },
    },
    filial: {
      cache: false,
      get() {
        let objFilial = this.getJwt(true)
        
        if(objFilial && objFilial.user){
          return objFilial.user.filial
        }
        return null
      },
      set(value) {
        //this.$store.dispatch("usuario/setUsuarioLogado", value);
      },
    },
    filiais: {
      cache: false,
      get() {
        let filiais = [];

        if (this.usuario) {
          this.usuario.filiais.forEach((objFilialVinculo) => {
            if (objFilialVinculo.filial) {
              filiais.push(objFilialVinculo.filial);
            }
          });
        }

        return filiais;
      },
    },
    filial: {
      cache: false,
      get() {
        let filial = null;

        if (this.usuario) {
          this.usuario.filiais.forEach((objFilialVinculo) => {
            if (objFilialVinculo.filial && this.$utilidade.onlyNumber(objFilialVinculo.filial.strCnpj) == this.$utilidade.onlyNumber(this.cliente.strCnpj)) {
              filial = objFilialVinculo.filial
            }
          });
        }

        return filial;
      },
    },
    notification: {
      cache: false,
      get() {
        return this.$store.state.usuario.notification;
      },
      set(value) {
        this.$store.dispatch("usuario/setUsuarioNotification", value);
      },
    },
    layout: {
      cache: false,
      get() {
        return this.$store.state.common.layout;
      },
      set(value) {
        this.$store.dispatch("common/setLayout", value);
      },
    },
    loadingContent: {
      cache: false,
      get() {
        return this.$store.state.common.loadingContent;
      },
      set(value) {
        this.$store.dispatch("common/setLoadingContent", value);
      },
    },
    jwtName: {
      cache: false,
      get() {
        if (this.layout == "cliente") {
          return process.env.VUE_APP_CLIENTE_JWT_NAME;
        }
        return process.env.VUE_APP_SYSTEM_JWT_NAME;
      },
      set(jwt) {},
    },
    isMobile: {
      cache: false,
      get() {
        return (
          this.$vuetify.breakpoint.mobile ||
          this.$vuetify.breakpoint.xs ||
          this.$vuetify.breakpoint.sm ||
          this.$vuetify.breakpoint.md
        );
      },
    },
    isProduction: {
      cache: false,
      get() {
        return Number(process.env.VUE_APP_PRODUCTION);
      },
    },
    drawer: {
      get() {
        return this.$store.state.menu.drawer;
      },
      set(val) {
        if (this.isMobile) {
          this.mini = false;
        } else {
          if (val) {
            this.mini = false;
          } else {
            this.mini = true;
          }
        }

        this.$store.dispatch("menu/setDrawer", val);
      },
    },
    mini: {
      get() {
        return this.$store.state.menu.mini;
      },
      set(val) {
        this.$store.dispatch("menu/setMini", val);
      },
    },
    miniWidth: {
      get() {
        return this.$store.state.menu.miniWidth;
      },
      set(val) {
        this.$store.dispatch("menu/setMiniWidth", val);
      },
    },
    width: {
      get() {
        return this.$vuetify.breakpoint.width;
      },
    },
    height: {
      get() {
        return this.$vuetify.breakpoint.height;
      },
    },
  },
  watch: {
    drawer() {},
    mini() {},
    isMobile() {
      this.inicialize();
    },
    width() {
      this.inicialize();
    },
  },
  data() {
    return {
      strUrlAdmin: process.env.VUE_APP_URL_ADMIN,
      strNomeAdmin: process.env.VUE_APP_NOME_ADMIN,
    };
  },

  mounted() {},
  created() {
    this.inicialize();
  },

  methods: {
    inicialize() {
      if (this.isMobile) {
        this.mini = false;
        this.drawer = false;
      } else if (this.$cookie.get("drawer") != null) {
        this.drawer = this.$cookie.get("drawer");

        if (this.$cookie.get("drawer")) {
          this.mini = false;
        } else {
          this.mini = true;
        }
      } else if (this.width < 1800) {
        this.drawer = false;
        this.mini = true;
      } else {
        this.drawer = true;
        this.mini = false;
      }
    },

    clearSession() {
      this.$cookie.delete(process.env.VUE_APP_CLIENTE_JWT_NAME);
      this.$cookie.delete(process.env.VUE_APP_SYSTEM_JWT_NAME);
      this.usuario = null;
    },

    logout() {
      this.clearSession();
      this.$router.push({ name: "login" });
    },

    login(objUsuario, strToken) {
      this.usuario = objUsuario;
      this.$cookie.set(this.jwtName, strToken, {
        expires: "12h",
        secure: this.$root.$session.isProduction ? false : false,
      });

      let route = { name: "home" };

      if (this.cliente && !this.$route.params.strUrlDatabase) {
        route.params = { strUrlDatabase: this.cliente.strUrlDatabase };
      }

      this.$router.push(route);
      //window.location.reload(true);
    },

    logado() {
      let jwt = this.$cookie.get(this.jwtName);

      return jwt || this.usuario;
    },

    getJwt(boolDecode = false) {
      let jwt = this.$cookie.get(this.jwtName);

      if (boolDecode) {
        try {
          return JwtDecode(jwt);
        } catch (error) {
          return null;
        }
      }
      return jwt;
    },

    isRouteActive(strRota) {
      if (!strRota) return false;

      let boolActive = false;

      let arrayRotaAtual = this.$route.name.split(".");
      let arrayRota = strRota.split(".");

      arrayRota.forEach((strRota1) => {
        arrayRotaAtual.forEach((strRota2) => {
          if (strRota1 == strRota2) {
            boolActive = true;
          }
        });
      });

      return boolActive;
    },

    verificarPermissaoMenu(strRota) {
      if (!this.usuario) return false;
      if ([2, 3].indexOf(this.usuario.intTipoUsuarioId) != -1) return true;

      let intIndexMenu = this.usuario.menu.findIndex(
        (c) => c.strRota == strRota
      );

      return intIndexMenu != -1;
    },

    verificarPermissaoModulo(intModuloId) {
      let intIndexMenu = this.cliente.modulos.findIndex(
        (c) => c.modulo.intId == intModuloId && c.boolAtivo
      );

      return intIndexMenu != -1;
    },

    getUsuarioPreferencia(strChave, strValorDefault = "") {
      if (!this.usuario || this.usuario.objPreferencia[strChave] == undefined) {
        return strValorDefault;
      }

      return this.usuario.objPreferencia[strChave];
    },

    setUsuarioPreferencia(strChave, strValor) {
      let obj = this.usuario.objPreferencia;
      obj[strChave] = strValor;

      this.$store.dispatch("usuario/setUsuarioPreferencia", obj);
      this.$root.$request
        .post("Cadastro/Usuario", {
          boolSalvarPreferencia: 1,
          objPreferencia: this.usuario.objPreferencia,
        })
        .then((objResponse) => {});
    },
  },
};
</script>
