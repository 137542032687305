
const setUsuarioLogado = (context,objUsuario) => {
	context.commit('SET_USUARIO_LOGADO', objUsuario);
}

const setUsuarioNotification = (context,objUsuario) => {
	context.commit('SET_USUARIO_NOTIFICATION', objUsuario);
}

const setUsuarioPreferencia = (context,objUsuario) => {
	context.commit('SET_USUARIO_PREFERENCIA', objUsuario);
}

export{
	setUsuarioLogado,
	setUsuarioNotification,
	setUsuarioPreferencia
}


